import React, { useContext } from 'react';

function TeamsGrid1() {
    return (
        <div className="group grid-elements grid1-parent flex flex-col md:flex-row text-lg items-end rounded-xl border transition duration-300 ease-in-out mb-3 shadow-md hover:bg-gray-100 hover:shadow-lg">
            {/* Image container */}
            <div className="grid1-container md:w-3/5 w-full border-r border-myblue-300">

                
                

                <img src={require('./TeamsGridImages/teamsroute-grid1.1.png').default} alt="Optimized Route Visual" className="grid1-img rounded-br-lg rounded-tl-lg md:rounded-l-lg md:rounded-br-none"/>
            </div>
            
            {/* Text container */}
            <div className="text-container md:w-2/5 w-full p-5 md:p-10">
                <h1 className="text-3xl text-white group-hover:text-myblue-900 font-extrabold leading-tighter tracking-tighter mb-4">Seamless, interactive dashboard</h1>
                <p className="text-myblue-100 group-hover:text-gray-500">
                    Easily manage your fleet’s routes from one place—add stops, assign drivers, and visualize optimized routes with just a few clicks.
                </p>
                {/* Add more text or elements as needed */}
            </div>
        </div>
    );
}

export default TeamsGrid1;






