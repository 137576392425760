import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';
import Modal from '../utils/Modal.js';
import { useHistory } from 'react-router-dom';

function AppInstructions() {

  const history = useHistory();

  const [tab, setTab] = useState(2);

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  function goChrome() {
    window.open("https://chrome.google.com/webstore/category/extensions", "_blank")
  }

  function goAppInstall() {
    // google tracking
    try {
      // Send the trial start event to Google Analytics (and ultimately Google Ads) after the user is successfully created
      window.gtag && window.gtag('event', 'app_button_click', {
        'event_category': 'Button Click',
        'event_label': 'App Button Click',
        'value': 0.1
      });
      console.log('App Button Click')
    } catch (error) {
      console.log('Error in tracking app button click start in Google Analytics', error);
    }

    history.push('/app-install')
  }

  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">

          {/* Section header */}
          <div id="extension-instructions-section" className="max-w-3xl mx-auto text-center pb-2 md:pb-3">
            <h1 className="h2 mb-4 text-myblue-900">Explore our Mobile App</h1>
            <p className="text-xl text-gray-600 mb-7">The <a onClick={goAppInstall} className="underline-offset-2 text-myteal-500 cursor-pointer">Routora Mobile App</a> takes the stops you enter and rearranges them to give you the fastest route.</p>
            <div className="flex justify-center items-center md:mb-0 mb-5">
              <svg onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVideoModalOpen(true); }} aria-controls="modal" xmlns="http://www.w3.org/2000/svg" fill="#338CF5" className="ionicon cursor-pointer" width="30" height="30" viewBox="0 0 512 512"><title>Play Circle</title><path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm74.77 217.3l-114.45 69.14a10.78 10.78 0 01-16.32-9.31V186.87a10.78 10.78 0 0116.32-9.31l114.45 69.14a10.89 10.89 0 010 18.6z"/></svg>
              <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVideoModalOpen(true); }} aria-controls="modal" className="text-base font-bold ml-1 text-gray-600 cursor-pointer">How it works</p>
            </div>
          </div>

          {/* Section content */}
          <div className="md:grid md:grid-cols-12 md:gap-6">

            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6" data-aos="fade-right">
              {/* <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                <h3 className="h3 mb-3">Powerful suite of tools</h3>
                <p className="text-xl text-gray-600">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.</p>
              </div> */}
              
              <div className="mb-8 md:mb-0">
                <a
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(1); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1 text-myblue-900">Enter Stops</div>
                    <div className="text-gray-600">In the app, first enter or import all the locations you would like to visit</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" height="17" width="17" viewBox="0 0 512 512">
                      <circle cx="256" cy="192" r="32"/><path d="M256 32c-88.22 0-160 68.65-160 153 0 40.17 18.31 93.59 54.42 158.78 29 52.34 62.55 99.67 80 123.22a31.75 31.75 0 0051.22 0c17.42-23.55 51-70.88 80-123.22C397.69 278.61 416 225.19 416 185c0-84.35-71.78-153-160-153zm0 224a64 64 0 1164-64 64.07 64.07 0 01-64 64z"/>
                    </svg>
                  </div>
                </a>
                <a
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(2); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1 text-myblue-900">Tap Optimize</div>
                    <div className="text-gray-600">Tap the optimize button, and our AI technology will generate the fastest order of stops</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                    <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                    </svg>
                  </div>
                </a>
                <a
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(3); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1 text-myblue-900">Start Navigating</div>
                    <div className="text-gray-600">Track the time, distance, and gas money you've saved and begin your route. We will direct you to your navigation app of choice: Google, Apple and Waze</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                    <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" fillRule="nonzero" />
                    </svg>
                  </div>
                </a>
              </div>
            </div>

            {/* Tabs items */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="zoom-y-out" ref={tabs}>
              <div className="relative flex flex-col text-center lg:text-center">
                
                <Transition
                  show={tab === 1}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <img className="md:max-w-none mx-auto drop-shadow-2xl" src={require('../images/app-visual-1.png').default} width="300" height="502" alt="Features bg" />
                    <div className="absolute bottom-8 left-20 md:left-20 w-36 h-8 cursor-pointer" onClick={(e) => { e.preventDefault(); setTab(2); }}>
                      {/* empty div on optimize button */}
                    </div>
                  </div>
                </Transition>
                
                <Transition
                  show={tab === 2}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  {/* <div className="relative inline-flex flex-col">
                    <img className="md:max-w-none mx-auto rounded" src={require('../images/features-bg.png').default} width="500" height="462" alt="Features bg" />
                    <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} />
                  </div> */}
                  <div className="relative inline-flex flex-col">
                    <img className="md:max-w-none mx-auto" src={require('../images/app-visual-2.2.png').default} width="370" height="502" alt="Features bg" />
                    <div className="absolute bottom-40 left-2 md:left-3 cursor-pointer animate-float" onClick={(e) => { e.preventDefault(); setTab(3); }}>
                      <img className="md:max-w-none" src={require('../images/savings-visual-1.png').default} width="350" height="502" alt="savings" />
                    </div>
                    <div className="absolute bottom-14 left-16 md:left-16 w-60 h-8 cursor-pointer" onClick={(e) => { e.preventDefault(); setTab(3); }}>
                      {/* empty div on continue button */}
                    </div>
                  </div>
                </Transition>
                
                <Transition
                  show={tab === 3}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <img className="md:max-w-none mx-auto drop-shadow-2xl" src={require('../images/app-visual-3.png').default} width="300" height="502" alt="Features bg" />
                  </div>
                </Transition>
              </div>
            </div >

          </div >

        </div >
      </div >
      
      {/* Video Modal */}
      <Modal id="modal" className="z-50" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)}>
        <div className="relative pb-9/16">
          <iframe className="absolute w-full h-full" src="https://www.youtube.com/embed/wBu22Jyh9vw?si=Sz3Pb8duQLHEOru9" title="Video" allowFullScreen></iframe>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-5 right-5 cursor-pointer" onClick={() => setVideoModalOpen(false)} height="30" width="30" viewBox="0 0 24 24"><path fill="#5D5D5D" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29ZM19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/></svg>
      </Modal>
      
    </section >
  );
}

export default AppInstructions;
