import React, { useContext, useState } from 'react'
import Axios from 'axios';
import './Pricing.css'
import Header from '../../partials/Header.js'
import Footer from '../../partials/Footer.js'
import { useHistory } from 'react-router-dom';
import Modal from '../../utils/Modal.js'

import { AuthContext } from '../../AuthContext'


const PLUS_PRICE = 17.99
const PLUS_PRICE_YEARLY = 14.49
const FLEET_PRICE = 89.99
const FLEET_PRICE_YEARLY = 71.99

function PricingExtension() {

    const { setPricingPlan, currentUser, updateUserTier, currentUserTier, getSubId, updateFleetClicks } = useContext(AuthContext);

    const history = useHistory();

    // const [promoCodeContent, setPromoCodeContent] = useState('');
    // function onPromoCodeChange(e) {
    //     setPromoCodeContent(e.target.value)
    // }
    // function submitPromoCode() {
    //     if (currentUser) {
    //         if (promoCodeContent == process.env.REACT_APP_PROMO_CODE_PLUS) {
    //             updateUserTier(currentUser, 2)
    //             history.push('/welcome-plus')
    //         } else if (promoCodeContent == process.env.REACT_APP_PROMO_CODE_BASIC) {
    //             updateUserTier(currentUser, 1)
    //             history.push('/welcome-basic')
    //         } else {
    //             alert('Sorry, Invalid Promo Code')
    //         }
    //     } else {
    //         history.push('signin')
    //     }
    // }

    async function submitFreeSub() {
        if (currentUser) {
            // if already subscribed to plus
            if (currentUserTier > 1) {

                // const subId = await getSubId(currentUser)
                // const res = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/deletesub`, {'subId': subId});
                // const {status} = res.data;
                // if (status === 'canceled') {
                //   console.log('cancelation success')
                // } else {
                //   console.log('cancelation ERROR')
                //   alert('Cancelation Error')
                // }
                history.push('/welcome-basic')
                return;
            }

            updateUserTier(currentUser, 1)
            history.push('/welcome-basic')
        } else {
            history.push('/extension-instructions')
        }
    }

    function subscriptionSelected(e) {
        let subType = e.currentTarget.id
        let plan
        let price
        if (subType === 'Basic') {
            plan = 'BASIC' // not rlly needed anymore since its free
            price = 0.00
        } else if (subType === 'Plus') {
            plan = 'PLUS'
            if (isYearly) {
                price = PLUS_PRICE_YEARLY // monthly breakdown of annual price
            } else {
                price = PLUS_PRICE
            }
        }

        setPricingPlan({plan: plan, price: price, isYearly: isYearly})
        if (currentUser) {
            if (subType === 'Plus' && currentUserTier == 2) {
                alert('You already have a PLUS subscription. Please cancel your subscription in the profile page before continuing.')
            } else {

                // fb tracking
                if (window.fbq) {
                    window.fbq('track', 'InitiateCheckout', {
                        value: price, // This uses a standard fb parameter 'value' to indicate the price.
                        pricing_plan: plan, // Custom parameter to specify the type of pricing plan.
                        is_yearly: isYearly // Custom parameter to specify monthly or yearly trial sub.
                    });
                }

                // google tracking
                try {
                    // Send the trial initiated event to Google Analytics (and ultimately Google Ads) after the user is successfully created
                    window.gtag && window.gtag('event', 'initiate_checkout', {
                    'event_category': 'Trial',
                    'event_label': 'Checkout Initiated',
                    'value': 5
                    });
                    console.log('New Trial Initiated')
                } catch (error) {
                    console.log('Error in tracking trial initiated in Google Analytics', error);
                }

                history.push('/subscribe')
            }
        } else {
            history.push('/signup')
        }
    }

    function goWaitlist() {
        updateFleetClicks()

        // fb tracking
        if (window.fbq) {
            window.fbq('track', 'Lead', {
                product: 'Routora for Teams'
            });
        }

        // google tracking
        try {
            // Send the waitlist click event to Google Analytics after the user is successfully created
            window.gtag && window.gtag('event', 'waitlist_click', {
              'event_category': 'Teams Waitlist',
              'event_label': 'Waitlist',
              'value': 10
            });
            console.log('New Waitlist Click')
          } catch (error) {
            console.log('Error in tracking waitlist click in Google Analytics', error);
        }

        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdwD52EgyuTukBcDh4pliKp2kvMyDMjTg8yKNzhUmgz2puFJg/viewform?usp=sf_link', '_blank').focus();
    }

    const [isYearly, toggle] = useState(true)
    const toggleClicked = () => {
        toggle(!isYearly)
    }

    const [videoModalOpen, setVideoModalOpen] = useState(false);

    return (
        <div>
            <Header></Header>
            <div className="buffer-100"></div>

            <div className="relative max-w-4xl mx-auto px-4 sm:px-6">
                <div className="pb-4 md:pb-7"></div>
                {/* Section header */}
                <div className="max-w-3xl mx-auto text-center">
                    <h2 className="h2 mb-4 text-myblue-900">Optimize more than 10 stops?</h2>
                    {/* <h2 className="text-2xl h2 mb-4"><span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-myteal-500">30 Day </span> Free Trial</h2> */}
                    <p className="text-xl text-gray-600">Try Routora Maps free for 7 days, or continue with the extension</p>
                </div>
                {/* Toggle for Monthly/Annually */}
                <div className="flex items-center justify-center mt-5 md:mb-3 mb-3">
                    <div className="w-25 ml-16">
                        { isYearly ? 
                            <div className="md:text-1xl font-bold text-gray-300 mr-2">Monthly</div> 
                        : 
                            <div className="md:text-1xl font-bold text-gray-500 mr-2">Monthly</div> 
                        }
                    </div>
                    <label class="toggle" for="myToggle">
                        <input class="toggle__input" name="" type="checkbox" id="myToggle" defaultChecked onClick={toggleClicked}></input>
                        <div class="toggle__fill"></div>
                    </label>
                    <div className="flex items-center justify-center w-25">
                        <div className="md:text-1xl font-bold text-gray-500 ml-2">
                            { isYearly ? 
                                <div className="md:text-1xl font-bold text-gray-500 mr-2">Yearly</div> 
                            : 
                                <div className="md:text-1xl font-bold text-gray-300 mr-2">Yearly</div> 
                            }
                        </div>
                    </div>
                    <h5 className="annual-deal-message">SAVE 20%</h5>
                </div>
                {/* Items */}
                <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-2 items-start md:max-w-2xl lg:max-w-none">
                    {/* <div style={{ height: 500 }} className="p-6 bg-white rounded-b shadow-lg border-gray-200 border-2 hover:shadow-xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                    <div className="flex justify-center">
                        <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fillRule="evenodd">
                            <rect className="fill-current text-myteal-500" width="64" height="64" rx="32" />
                            <g strokeWidth="2">
                                <path className="stroke-current text-blue-300" d="M34.514 35.429l2.057 2.285h8M20.571 26.286h5.715l2.057 2.285" />
                                <path className="stroke-current text-white" d="M20.571 37.714h5.715L36.57 26.286h8" />
                                <path className="stroke-current text-blue-300" strokeLinecap="square" d="M41.143 34.286l3.428 3.428-3.428 3.429" />
                                <path className="stroke-current text-white" strokeLinecap="square" d="M41.143 29.714l3.428-3.428-3.428-3.429" />
                            </g>
                            </g>
                        </svg>
                    </div>
                    <h4 className="text-xl font-semibold leading-snug tracking-tight flex justify-center">Chrome Extension</h4>
                    <div className="flex items-center justify-center">
                        <div className="text-4xl :text-4xl text-gray-700 font-bold leading-snug tracking-tight mb-1 mr-1">Free</div>
                    </div>
                    <div className="flex ml-4 mt-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                        <p className="text-gray-600 text-left ml-3">Chrome Extension Access - 10 Stops</p>
                    </div>
                    <div className="flex ml-4 mt-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                        <p className="text-gray-600 text-left ml-3">Driving, Biking, Walking Modes</p>
                    </div>
                    <div className="flex justify-center">
                        <div onClick={submitFreeSub} id="Basic" className="font-semibold absolute rounded py-2 bottom-6 px-28 text-white cursor-pointer bg-gradient-to-r from-blue-500 to-myteal-500 hover:shadow-xl">Continue</div>
                    </div>
                    </div> */}

                    {/* 1st item */}
                    <div style={{ height: 550 }} className="p-7 bg-white rounded-2xl shadow-xl border-gray-200 border-2 hover:shadow-2xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                        <h4 className="text-xl text-myblue-900 font-semibold">Chrome Extension</h4>
                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600">For drivers looking to optimize shorter routes; errands, travel, etc</p>
                        <div className="dashed-line"></div>
                        <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mb-3 mr-1">Free</div>
                        <div className="flex h-5 mb-2"></div>
                        <div onClick={submitFreeSub} id="Basic" className="btn py-2 font-semibold rounded-lg border-2 text-white bg-myteal-500 border-myteal-500 hover:bg-myteal-600 hover:border-myteal-600 w-full cursor-pointer">
                            <p>Continue</p>
                        </div>
                        <div className="mt-7">
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Optimize up to 10 stops</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Driving, biking, walking modes</p>
                            </div>
                        </div>
                    </div>

                    {/* 2nd item */}
                    <div style={{ height: 550 }} className="p-7 bg-white rounded-2xl shadow-xl border-gray-200 border-2 hover:shadow-2xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                        <h4 className="text-xl text-myblue-900 font-semibold">Routora Maps</h4>
                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600">For drivers and small to medium sized businesses with longer routes</p>
                        <div className="dashed-line"></div>
                        <div className="flex">
                            <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                                { isYearly ? PLUS_PRICE_YEARLY : PLUS_PRICE }
                            </div>
                            <div className="md:text-1xl text-gray-600 font-medium mt-6">/ month</div>
                        </div>
                        { isYearly ? 
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-gray-500">${173.99} Billed Annually |</p>
                                <p className="md:text-sm text-sm text-myteal-500 ml-1 font-semibold">You Save ${42}</p>
                            </div>
                            :
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-white">-</p>
                            </div>
                        }
                        <div onClick={subscriptionSelected} id="Plus" className="btn mt-3 py-2 font-semibold rounded-lg text-myteal-500 border-gray-200 border-2 hover:text-white hover:bg-myteal-500 hover:border-myteal-500 w-full cursor-pointer">
                            <p>Start free 7 day trial</p>
                        </div>
                        <div className="mt-7">
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Optimize up to 100 stops</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Import stops from excel, CSV</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Download your itinerary</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Share routes</p>
                            </div>
                        </div>

                        <div className="flex justify-center items-center md:mb-0 mt-8 bg-bl">
                            <svg onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVideoModalOpen(true); }} aria-controls="modal" xmlns="http://www.w3.org/2000/svg" fill="#338CF5" class="ionicon cursor-pointer" width="30" height="30" viewBox="0 0 512 512"><title>Play Circle</title><path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm74.77 217.3l-114.45 69.14a10.78 10.78 0 01-16.32-9.31V186.87a10.78 10.78 0 0116.32-9.31l114.45 69.14a10.89 10.89 0 010 18.6z"/></svg>
                            <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVideoModalOpen(true); }} aria-controls="modal" className="font-medium text-gray-600 ml-1 cursor-pointer">How it works</p>
                        </div>
                    </div>

                    {/* 3rd item */}
                    {/* <div style={{ height: 550 }} className="p-7 bg-white rounded-2xl shadow-xl border-gray-200 border-2 hover:shadow-2xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                        <h4 className="text-xl text-myblue-900 font-semibold">Routora for Teams</h4>
                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600">For teams with a fleet of drivers looking to efficiently allocate several routes</p>
                        <div className="dashed-line"></div>
                        <div className="flex">
                            <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                                { isYearly ? FLEET_PRICE_YEARLY : FLEET_PRICE }
                            </div>
                            <div className="md:text-1xl text-gray-600 font-medium mt-6">/ month</div>
                        </div>
                        { isYearly ? 
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-gray-500">${863.99} Billed Annually |</p>
                                <p className="md:text-sm text-sm text-myteal-500 ml-1 font-semibold">You Save ${216}</p>
                            </div>
                            :
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-white">-</p>
                            </div>
                        }
                        <div onClick={goWaitlist} id="Fleet" className="btn mt-3 py-2 font-semibold rounded-lg text-myteal-500 border-gray-200 border-2 hover:text-white hover:bg-myteal-500 hover:border-myteal-500 w-full cursor-pointer">
                            <p>Start free 7 day trial</p>
                        </div>
                        <div className="mt-7">
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Scalable fleet management</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Add multiple drivers</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Balanced route distribution</p>
                            </div>

                            <div className="w-full border-t border-gray-300 my-3">
                                <div className="border-t-0 h-0.5" />
                            </div>

                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Import stops from excel, CSV</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Download your itinerary</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Share routes</p>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
            {/* <div className="w-96 mx-auto flex mt-20">
                <input className="form-input w-full text-gray-800 mx-3" placeholder="Access code" value={promoCodeContent} onChange={onPromoCodeChange} />
                <div className="btn text-white bg-blue-500 hover:bg-blue-600 shadow cursor-pointer" onClick={submitPromoCode}>Apply</div>
            </div> */}
            <div className="buffer-100"></div>
            <Footer></Footer>

            {/* Video Modal */}
            <Modal id="modal" className="z-50" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)}>
                <div className="relative pb-9/16">
                <iframe className="absolute w-full h-full" src="https://www.youtube.com/embed/ETKzjzBtZUI?si=_g1FhhM1gMbAy2EW" title="Video" allowFullScreen></iframe>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-5 right-5 cursor-pointer" onClick={() => setVideoModalOpen(false)} height="30" width="30" viewBox="0 0 24 24"><path fill="#5D5D5D" d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29ZM19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/></svg>
            </Modal>
        </div>
    );
}

export default PricingExtension;