import React from 'react';

function InspectorSelection({ availableInspectors, selectedInspectors, onInspectorSelect }) {
    return (
        <div className="h-full flex flex-col">
            {/* Fixed Header */}
            <div className="px-6 pt-4 border-gray-200">
                <h3 className="text-lg font-medium text-myblue-900">
                    Available Inspectors
                </h3>
                <div className="flex items-center mt-1">
                    <p className="text-sm text-gray-500">
                        Select inspectors to assign for today's routes
                    </p>
                    <span className="inline-flex ml-2 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800" style={{marginTop: "3px"}}>
                        {selectedInspectors.length} selected
                    </span>
                </div>
            </div>

            {/* Cards Container with scrollable content */}
            <div className="flex-1 overflow-hidden">
                <div className="h-full relative">
                    <div className="absolute inset-0 overflow-auto px-6 mt-4 pb-4">
                        <div className="space-y-4">
                            {availableInspectors.map((inspector) => (
                                <div
                                    key={inspector.INSPECTOR_ID}
                                    onClick={() => onInspectorSelect(inspector.INSPECTOR_ID)}
                                    className={`cursor-pointer transition-all duration-200 ease-in-out 
                                        ${selectedInspectors.includes(inspector.INSPECTOR_ID)
                                            ? 'bg-blue-50 border-blue-200 ring-2 ring-blue-500'
                                            : 'bg-white hover:bg-gray-50 border-gray-200'
                                        } border rounded-lg shadow-sm`}
                                >
                                    <div className="p-4">
                                        <div className="flex items-start justify-between">
                                            <div className="flex-1">
                                                <div className="flex items-center space-x-2">
                                                    <input
                                                        type="checkbox"
                                                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                        checked={selectedInspectors.includes(inspector.INSPECTOR_ID)}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            onInspectorSelect(inspector.INSPECTOR_ID);
                                                        }}
                                                    />
                                                    <h4 className="text-lg font-medium text-gray-900">
                                                        {inspector.INSPECTOR_ID}
                                                    </h4>
                                                </div>
                                                <div className="mt-2 grid grid-cols-2 gap-4">
                                                    <div>
                                                        <p className="text-sm font-medium text-gray-500">Department</p>
                                                        <p className="mt-1 text-sm text-gray-900">{inspector.INSPECTOR_DEPARTMENT}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-medium text-gray-500">Districts</p>
                                                        <p className="mt-1 text-sm text-gray-900">
                                                            {inspector.districtCodes?.length > 0 
                                                                ? inspector.districtCodes.join(', ')
                                                                : inspector.Districts || 'No Data'
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <p className="text-sm font-medium text-gray-500">Skills</p>
                                                    <div className="mt-1 flex flex-wrap gap-2">
                                                        {inspector.Skills.split(',').map((skill, index) => (
                                                            <span
                                                                key={index}
                                                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                                                            >
                                                                {skill.trim()}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <p className="text-sm font-medium text-gray-500">Location</p>
                                                    <p className="mt-1 text-sm text-gray-900">{inspector.Street}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InspectorSelection; 