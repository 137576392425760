// Required columns for district data
export const REQUIRED_COLUMNS = [
    'INSPECTOR_ID',
    'District'
];

// Column validation function
export const validateColumns = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        return {
            isValid: false,
            missingColumns: REQUIRED_COLUMNS,
            message: 'No data found in file'
        };
    }

    const fileColumns = Object.keys(data[0]);
    const missingColumns = REQUIRED_COLUMNS.filter(col => !fileColumns.includes(col));

    return {
        isValid: missingColumns.length === 0,
        missingColumns,
        message: missingColumns.length > 0
            ? `Missing required columns: ${missingColumns.join(', ')}`
            : 'All required columns present'
    };
};

// Main validation function
export const validateDistrictRow = (row) => {
    const errors = [];
    if (!row) {
        errors.push('Invalid row: empty or undefined');
        return errors;
    }

    // Check if all required columns exist in the data structure
    const missingFields = REQUIRED_COLUMNS.filter(field => !(field in row));

    if (missingFields.length > 0) {
        errors.push(`Missing required fields: ${missingFields.join(', ')}`);
        return errors;
    }

    // INSPECTOR_ID validation
    if (!row.INSPECTOR_ID || String(row.INSPECTOR_ID).trim() === '') {
        errors.push('INSPECTOR_ID cannot be empty');
    }

    // District validation
    if (!row.District || String(row.District).trim() === '') {
        errors.push('District cannot be empty');
    }

    return errors;
};

export const validateDistrictData = (data) => {
    if (!Array.isArray(data)) {
        throw new Error('Invalid data format: Expected an array of district records');
    }

    // First validate columns
    const columnValidation = validateColumns(data);
    if (!columnValidation.isValid) {
        throw new Error(columnValidation.message);
    }

    return data.map((row, index) => ({
        rowIndex: index + 2, // Add 2 to account for 0-based index and header row
        inspectorId: row?.INSPECTOR_ID || 'N/A',
        errors: validateDistrictRow(row),
        rawData: row
    })).filter(result => result.errors.length > 0);
}; 