/**
 * getInspectorAvailability.js
 *
 * Main function: `getAvailableInspectorsForDate()`
 *  - Accepts:
 *    - inspectorsData (array)
 *    - calendarData (array)
 *    - holidayListData (array)
 *    - targetDate (string or Date)
 *
 * Returns:
 *    - A filtered list of inspectors who are not completely blocked.
 *      For partial blocks, a new field is appended with the times blocked.
 *
 * Approach:
 * 1) Check if targetDate is a holiday => if yes, return empty.
 * 2) For each inspector, gather "block out" events that intersect targetDate.
 * 3) If the block events cover the entire day, inspector is removed.
 * 4) If partial blocks, keep inspector but add info on partial unavailability.
 */

// Helper function to parse our standard date format "M/D/YY HH:mm"
function parseDate(dateStr) {
    if (!dateStr) return null;

    try {
        // Parse M/D/YY HH:mm format
        const [datePart, timePart] = dateStr.split(' ');
        const [month, day, year] = datePart.split('/').map(Number);
        const [hours, minutes] = timePart.split(':').map(Number);
        
        // Validate components
        if (isNaN(month) || isNaN(day) || isNaN(year) || isNaN(hours) || isNaN(minutes)) {
            console.warn('Invalid date components in:', dateStr);
            return null;
        }
        
        // Convert 2-digit year to full year (20xx)
        const fullYear = 2000 + year;
        
        return new Date(fullYear, month - 1, day, hours, minutes);
    } catch (error) {
        console.warn('Error parsing date:', dateStr, error);
        return null;
    }
}

// Helper function to check if two dates are the same day (in local timezone)
function isSameDay(date1, date2) {
    if (!date1 || !date2) return false;
    
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
}

/***************************************************************
 * Helper to check if a given date is a holiday
 ***************************************************************/
function isHoliday(targetDate, holidayListData) {
    if (!holidayListData) return false;

    for (const holiday of holidayListData) {
        const holStart = parseDate(holiday.START_DATE);
        if (holStart && isSameDay(targetDate, holStart)) {
            console.log(`Holiday found: ${holiday.HOLIDAY_NAME} on ${holStart.toLocaleDateString()}`);
            return true;
        }
    }
    return false;
}

/***************************************************************
 * Helper: Gets the block-out events for a single inspector
 ***************************************************************/
function getInspectorBlocksForDate(inspectorId, calendarData, targetDate) {
    if (!calendarData) return [];

    return calendarData.filter((row) => {
        if (row.INSPECTOR_ID !== inspectorId) return false;
        if (row.EVENT_TYPE !== 'Block Out') return false;

        const eventStart = parseDate(row.START_TIME);
        return eventStart && isSameDay(eventStart, targetDate);
    });
}

/***************************************************************
 * Helper: Check if block events effectively cover the entire workday
 ***************************************************************/
function isFullyBlockedForDay(blockEvents, targetDate) {
    if (!blockEvents || blockEvents.length === 0) return false;

    // Sort events by start time
    const sortedEvents = blockEvents
        .map(evt => {
            const start = parseDate(evt.START_TIME);
            const end = parseDate(evt.END_TIME);
            if (!start || !end) return null;
            return { start, end };
        })
        .filter(evt => evt !== null)
        .sort((a, b) => a.start - b.start);

    if (sortedEvents.length === 0) return false;

    // Check for a single event covering the standard workday (e.g., 9AM to 5PM)
    const workdayStart = new Date(targetDate);
    workdayStart.setHours(9, 0, 0, 0);
    const workdayEnd = new Date(targetDate);
    workdayEnd.setHours(17, 0, 0, 0);

    // First check if any single event covers the workday
    for (const evt of sortedEvents) {
        if (evt.start <= workdayStart && evt.end >= workdayEnd) {
            return true;
        }
    }

    // Then check if multiple events together cover the workday
    let currentTime = workdayStart;
    for (const evt of sortedEvents) {
        // If there's a gap between current time and next event start
        if (evt.start > currentTime) {
            return false;
        }
        // Move current time to the end of this event
        currentTime = evt.end > currentTime ? evt.end : currentTime;
    }

    // Check if we made it to the end of the workday
    return currentTime >= workdayEnd;
}

/***************************************************************
 * Main function: getAvailableInspectorsForDate
 ***************************************************************/
function getAvailableInspectorsForDate(inspectorsData, calendarData, holidayListData, targetDate) {
    if (!inspectorsData || !Array.isArray(inspectorsData)) {
        console.warn('No inspector data provided');
        return [];
    }

    // Normalize targetDate to noon of the day to avoid timezone issues
    const normalizedTargetDate = new Date(targetDate);
    normalizedTargetDate.setHours(12, 0, 0, 0);

    // 1) Check if targetDate is a holiday
    if (isHoliday(normalizedTargetDate, holidayListData)) {
        console.log('Date is a holiday - no inspectors available');
        return [];
    }

    const results = [];
    const fullyBlocked = [];
    const partiallyBlocked = [];
    const fullyAvailable = [];

    for (const inspector of inspectorsData) {
        // gather block out events that intersect targetDate
        const blocks = getInspectorBlocksForDate(inspector.INSPECTOR_ID, calendarData, normalizedTargetDate);

        if (blocks.length === 0) {
            // inspector is fully free
            results.push({ ...inspector, partialBlocks: [] });
            fullyAvailable.push(inspector.INSPECTOR_ID);
            continue;
        }

        // check if blocks cover the workday
        if (isFullyBlockedForDay(blocks, normalizedTargetDate)) {
            fullyBlocked.push(inspector.INSPECTOR_ID);
            continue;
        }

        // else they have partial blocks
        partiallyBlocked.push(inspector.INSPECTOR_ID);
        results.push({
            ...inspector,
            partialBlocks: blocks.map((block) => ({
                eventId: block.EVENT_ID,
                eventName: block.EVENT_NAME,
                start: block.START_TIME,
                end: block.END_TIME,
            })),
        });
    }

    const dateStr = normalizedTargetDate.toLocaleDateString();
    console.log(`Inspector Availability Summary for ${dateStr}:`);
    console.log('Fully Available:', fullyAvailable.length, 'inspectors:', fullyAvailable);
    console.log('Partially Blocked:', partiallyBlocked.length, 'inspectors:', partiallyBlocked);
    console.log('Fully Blocked:', fullyBlocked.length, 'inspectors:', fullyBlocked);

    return results;
}

// Export the main function
export {
    getAvailableInspectorsForDate
};