// Required columns for holiday list data
export const REQUIRED_COLUMNS = [
    'HOLIDAY_NAME',
    'START_DATE',
    'END_DATE'
];

// Helper functions
const isValidDateTime = (dateTimeStr) => {
    if (!dateTimeStr) {
        return false;
    }
    
    // Handle Excel date format "YYYY-MM-DD HH:mm:ss"
    const formatExcel = /^(\d{4})-(\d{1,2})-(\d{1,2})\s*(\d{2}):(\d{2}):(\d{2})$/;
    
    // Check format "YYYY-MM-DD HH:mm:ss" or "YY-MM-DD HH:mm:ss" (with variations for single/double digit month/day)
    const regex = /^(\d{2}|\d{4})-(\d{1,2})-(\d{1,2})\s*(\d{2}):(\d{2}):(\d{2})$/;
    
    let match;
    if (formatExcel.test(dateTimeStr)) {
        match = dateTimeStr.match(formatExcel);
    } else if (regex.test(dateTimeStr)) {
        match = dateTimeStr.match(regex);
    } else {
        return false;
    }

    // Parse the date components
    const [, year, month, day, hours, minutes, seconds] = match;
    
    // Convert to numbers for validation
    const monthNum = parseInt(month);
    const dayNum = parseInt(day);
    const yearNum = parseInt(year);
    const hoursNum = parseInt(hours);
    const minutesNum = parseInt(minutes);
    const secondsNum = parseInt(seconds);

    // Validate ranges
    if (monthNum < 1 || monthNum > 12) {
        return false;
    }
    if (dayNum < 1 || dayNum > 31) {
        return false;
    }
    
    // Year validation
    if (yearNum < 2020 || yearNum > 2199) {
        return false;
    }
    
    if (hoursNum < 0 || hoursNum > 23) {
        return false;
    }
    if (minutesNum < 0 || minutesNum > 59) {
        return false;
    }
    if (secondsNum < 0 || secondsNum > 59) {
        return false;
    }
    
    // Create date object to validate the date
    const date = new Date(yearNum, monthNum - 1, dayNum, hoursNum, minutesNum, secondsNum);
    
    // Check if it's a valid date and matches the input components
    return date instanceof Date && 
           !isNaN(date) && 
           date.getMonth() === monthNum - 1 && 
           date.getDate() === dayNum && 
           date.getFullYear() === yearNum &&
           date.getHours() === hoursNum &&
           date.getMinutes() === minutesNum &&
           date.getSeconds() === secondsNum;
};

// Column validation function
export const validateColumns = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        return {
            isValid: false,
            missingColumns: REQUIRED_COLUMNS,
            message: 'No data found in file'
        };
    }

    const fileColumns = Object.keys(data[0]);
    const missingColumns = REQUIRED_COLUMNS.filter(col => !fileColumns.includes(col));

    return {
        isValid: missingColumns.length === 0,
        missingColumns,
        message: missingColumns.length > 0
            ? `Missing required columns: ${missingColumns.join(', ')}`
            : 'All required columns present'
    };
};

// Main validation function
export const validateHolidayRow = (row) => {
    const errors = [];
    if (!row) {
        errors.push('Invalid row: empty or undefined');
        return errors;
    }

    // Check if all required columns exist in the data structure
    const missingFields = REQUIRED_COLUMNS.filter(field => !(field in row));

    if (missingFields.length > 0) {
        errors.push(`Missing required fields: ${missingFields.join(', ')}`);
        return errors;
    }

    // START_DATE validation
    if (!isValidDateTime(row.START_DATE)) {
        errors.push('Invalid START_DATE: Must be in format "YYYY-MM-DD HH:mm:ss" or "YY-MM-DD HH:mm:ss"');
    }

    // END_DATE validation
    if (!isValidDateTime(row.END_DATE)) {
        errors.push('Invalid END_DATE: Must be in format "YYYY-MM-DD HH:mm:ss" or "YY-MM-DD HH:mm:ss"');
    }

    // Validate that END_DATE is after START_DATE
    if (isValidDateTime(row.START_DATE) && isValidDateTime(row.END_DATE)) {
        const startDate = new Date(row.START_DATE);
        const endDate = new Date(row.END_DATE);
        if (endDate <= startDate) {
            errors.push('END_DATE must be after START_DATE');
        }
    }

    return errors;
};

export const validateHolidayData = (data) => {
    console.log(data)
    if (!Array.isArray(data)) {
        throw new Error('Invalid data format: Expected an array of holiday records');
    }

    // First validate columns
    const columnValidation = validateColumns(data);
    if (!columnValidation.isValid) {
        throw new Error(columnValidation.message);
    }

    return data.map((row, index) => ({
        rowIndex: index + 2, // Add 2 to account for 0-based index and header row
        errors: validateHolidayRow(row),
        rawData: row
    })).filter(result => result.errors.length > 0);
}; 