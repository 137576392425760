import React, { useContext, useState } from 'react'
import Axios from 'axios';
import './Pricing.css'
import Header from '../../partials/Header.js'
import Footer from '../../partials/Footer.js'
import { useHistory, useLocation } from 'react-router-dom';
import Modal from '../../utils/Modal.js'

import { AuthContext } from '../../AuthContext'


function PricingIntro() {

    const history = useHistory();
    const location = useLocation(); // Get current URL

    // Check if "/pricing/redirect" exists in the URL
    const isRedirect = location.pathname.includes('/pricing/redirect');
    console.log(isRedirect)

    function goWebPricing() {
        if (isRedirect) {
            history.push('/web-intro');
        } else {
            history.push('/pricing-web');
        }
    }

    function goTeamsPricing() {
        if (isRedirect) {
            history.push('/teams-intro');
        } else {
            history.push('/pricing-teams');
        }
    }

    return (
        <div>
            <Header></Header>
            <div className="buffer-100"></div>

            <div className="relative max-w-4xl mx-auto px-4 sm:px-6">
                <div className="pb-4 md:pb-7"></div>
                {/* Section header */}
                <div className="max-w-xl mx-auto text-center">
                    <h2 className="text-2xl font-semibold mb-4 mt-16 text-gray-700">What best describes your routing needs?</h2>
                </div>
                {/* Items */}
                <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-2 items-start md:max-w-2xl lg:max-w-none">

                    {/* 1st item */}
                    <div onClick={goWebPricing} style={{ height: 300 }} className="group p-7 cursor-pointer bg-white rounded-2xl shadow-lg border-gray-200 border-2 hover:shadow-xl hover:bg-myblue-900 md:mt-5">
                        <div className="flex">
                            <h4 className="h3 text-myblue-900 font-semibold group-hover:text-white">Single-Driver</h4>
                        </div>
                        <h1 className="text-lg text-gray-700 group-hover:text-gray-200 italic">Web Route Planner</h1>
                        <svg data-slot="icon" className="text-myblue-900 group-hover:text-white mt-5 mx-auto" fill="none" stroke-width="2" stroke="currentColor" width="130" height="130" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"></path>
                        </svg>
                    </div>

                    {/* 2nd item */}
                    <div onClick={goTeamsPricing} style={{ height: 300 }} className="group p-7 cursor-pointer bg-white rounded-2xl shadow-lg border-gray-200 border-2 hover:shadow-xl hover:bg-myblue-900 md:mt-5">
                        <div className="flex">
                            <h4 className="h3 text-myblue-900 font-semibold group-hover:text-white">Multi-Driver</h4>
                        </div>
                        <h1 className="text-lg text-gray-700 group-hover:text-gray-200 italic">Routora for Teams</h1>
                        <svg data-slot="icon" className="text-myblue-900 group-hover:text-white mt-2 mx-auto" fill="none" stroke-width="1.5" stroke="currentColor" width="160" height="160" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"></path>
                        </svg>
                    </div>

                </div>
            </div>
            <div className="buffer-100"></div>
        </div>
    );
}

export default PricingIntro;