import React, { useContext } from 'react';

function TeamsGrid4() {
    return (
        <div className="group grid-elements grid4-parent flex flex-col justify-between text-lg items-end rounded-xl border transition duration-300 ease-in-out shadow-md hover:bg-gray-100 hover:shadow-lg overflow-hidden">
            
            <div className="text-container w-full p-5 md:p-10 md:order-1">
                <h1 className="text-3xl text-white group-hover:text-myblue-900 font-extrabold leading-tighter tracking-tighter mb-4">Full control over your routes</h1>
                <p className="text-myblue-100 group-hover:text-gray-500">
                    View individual driver ETAs, adjust stops, and fine-tune itineraries after optimization—giving managers complete oversight.
                </p>
            </div>

            {/* Image container */}
            <div className="flex md:order-2 px-5 md:px-16 order-2">
                <div className="grid4-img w-full justify-center bg-myblue-50 rounded-tr-xl rounded-tl-xl shadow-lg border-myblue-300 border-l border-r border-t">
                    <div className="flex ml-4 md:ml-7 mt-5">
                        <span className="dot-window red"></span>
                        <span className="dot-window yellow"></span>
                        <span className="dot-window green"></span>
                    </div>
                    <img src={require('./TeamsGridImages/dashboard-teamsgrid4.1.png').default} alt="Routora Maps Dashboard" className="rounded-tr-xl rounded-tl-xl"/>
                </div>
            </div>

        </div>
    );
}

export default TeamsGrid4;






