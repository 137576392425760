import { createContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getFirestore, doc, collection, where, getDoc, updateDoc, query, orderBy, getDocs, setDoc, increment, arrayUnion, deleteDoc } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, onAuthStateChanged, signInWithPopup, signOut, createUserWithEmailAndPassword, updateProfile, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { useHistory } from 'react-router-dom';

import React from 'react';
import Axios from 'axios';

import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

// initial creation of context, will be placed inside useContext() in other components
export const AuthContext = createContext(null);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Enable debug mode for localhost
if (process.env.NODE_ENV === 'development') {
  // Add this line to activate debug mode
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// Initialize App Check with reCAPTCHA Enterprise
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_FIREBASE_APP_CHECK_SITE_KEY),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

// function to get all the business emails
// businessEmails()
// function businessEmails() {
//   let emailArr = []
//   let fullEmailArr = []
//   console.log('***** DATA OF EACH DOCUMENT *****')
//   db.collection("users").get().then(function(querySnapshot) {
//     querySnapshot.forEach(function(doc) {
//         // doc.data() is never undefined for query doc snapshots
//         let email = doc.id
//         let email1 = email.split('@')[1]
        
//         let index = email1.length-1
//         for (let i = email1.length-2; i >= 0; i--) {
//           if (email1.substring(i,i+1) == '.') {
//             index = i
//           }
//         }

//         let email2 = email1.substring(0,index)
//         if (email2 != 'gmail') {
//           emailArr.push(email2)
//           fullEmailArr.push(email)
//           console.log(email2)
//         }
        
//     });
//   });

//   console.log(emailArr)
//   console.log(fullEmailArr)
// }

// function to get ALL the emails on firebase
// allEmails()
// async function allEmails() {
//   try {
//     // Fetch the data from Firebase
//     const usersCollection = collection(db, "users");
//     const querySnapshot = await getDocs(usersCollection);

//     let usersData = [];
//     querySnapshot.forEach((doc) => {
//       let data = doc.data();
//       let userRecord = {
//         name: data.name || "",
//         email: doc.id, // Assuming the email is stored as the document ID
//       };
//       usersData.push(userRecord);
//     });

//     // Format the data into CSV
//     let csvContent = "data:text/csv;charset=utf-8,";
//     csvContent += "Name,Email\n"; // Headers
//     usersData.forEach((user) => {
//       let row = `${user.name},${user.email}`;
//       csvContent += row + "\n";
//     });

//     // Trigger a download of the CSV file
//     let encodedUri = encodeURI(csvContent);
//     let link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "all_users_emails.csv");
//     document.body.appendChild(link); // Required for Firefox

//     link.click();
//     document.body.removeChild(link); // Clean up

//     console.log("CSV file downloaded successfully.");
//   } catch (error) {
//     console.error("Error getting user emails:", error);
//   }
// }

// function to get a breakdown of onboarding breakdown
// console.log('Breakdown of Onboarding Questions');
// onboardingBreakdown();
// async function onboardingBreakdown() {
//   let medium = {
//     google: 0,
//     article: 0,
//     friend: 0,
//     ad: 0,
//     email: 0,
//     social: 0,
//     other: 0
//   };
//   let segment = {
//     delivery: 0,
//     personal: 0,
//     travel: 0,
//     realestate: 0,
//     fieldservice: 0,
//     sales: 0,
//     healthcare: 0,
//     other: 0
//   };

//   try {
//     // Query Firestore to get users with "onboarding" field not null
//     const usersRef = collection(db, "users");
//     const q = query(usersRef, where("onboarding", "!=", null));
//     const querySnapshot = await getDocs(q);

//     querySnapshot.forEach((doc) => {
//       const data = doc.data();

//       // Update medium counts
//       if (data.onboarding?.medium) {
//         switch (data.onboarding.medium) {
//           case 'Google':
//             medium.google++;
//             break;
//           case 'Article/Blog':
//             medium.article++;
//             break;
//           case 'Friend/Colleague':
//             medium.friend++;
//             break;
//           case 'Ad':
//             medium.ad++;
//             break;
//           case 'Email':
//             medium.email++;
//             break;
//           case 'Social Media':
//             medium.social++;
//             break;
//           case 'Other':
//             medium.other++;
//             break;
//           default:
//             break;
//         }
//       }

//       // Update segment counts
//       if (data.onboarding?.segment) {
//         switch (data.onboarding.segment) {
//           case 'Delivery':
//             segment.delivery++;
//             break;
//           case 'Personal Use':
//             segment.personal++;
//             break;
//           case 'Travel':
//             segment.travel++;
//             break;
//           case 'Real Estate':
//             segment.realestate++;
//             break;
//           case 'Field Service':
//             segment.fieldservice++;
//             break;
//           case 'Sales':
//             segment.sales++;
//             break;
//           case 'Health Care':
//             segment.healthcare++;
//             break;
//           case 'Other':
//             segment.other++;
//             break;
//           default:
//             break;
//         }
//       }
//     });
//     console.log('Medium breakdown:', medium);
//     console.log('Segment breakdown:', segment);
//   } catch (error) {
//     console.error("Error getting documents: ", error);
//   }
// }


// Export the onboarding data at an individual level
// exportOnboardingBreakdownToCSV();
// async function exportOnboardingBreakdownToCSV() {
//   try {
//     // Fetch the data from Firebase
//     const usersCollection = collection(db, "users");
//     const onboardingQuery = query(usersCollection, where("onboarding", "!=", null));
//     const querySnapshot = await getDocs(onboardingQuery);

//     let usersData = [];
//     querySnapshot.forEach((doc) => {
//       let data = doc.data();
//       let userRecord = {
//         email: doc.id,
//         subscription_id: data.subscription_id || 'N/A',
//         createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : 'N/A',
//         medium: data.onboarding?.medium || 'N/A',
//         segment: data.onboarding?.segment || 'N/A',
//         organization: data.onboarding?.organization || 'N/A',
//       };
//       usersData.push(userRecord);
//     });

//     // Sort the data by createdAt in descending order
//     usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

//     // Format the data into CSV
//     let csvContent = "data:text/csv;charset=utf-8,";
//     csvContent += "Email,subscription_id,Created At,Medium,Segment,Organization\n";
//     usersData.forEach((user) => {
//       let row = `${user.email},${user.subscription_id},${user.createdAt},${user.medium},${user.segment},${user.organization}`;
//       csvContent += row + "\n";
//     });

//     // Trigger a download of the CSV file
//     let encodedUri = encodeURI(csvContent);
//     let link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "onboarding_breakdown.csv");
//     document.body.appendChild(link); // Required for Firefox

//     link.click();
//     document.body.removeChild(link); // Clean up
//   } catch (error) {
//     console.error("Error getting documents: ", error);
//   }
// }

// Export signup count by month (MoM growth)
// exportMonthlySignupsToCSV();
// function exportMonthlySignupsToCSV() {
//   // Fetch all user data from Firebase
//   db.collection("users").get()
//     .then(function(querySnapshot) {
//       let monthlySignups = {};

//       querySnapshot.forEach(function(doc) {
//         let data = doc.data();
//         if (data.createdAt) {
//           let date = data.createdAt.toDate();
//           let yearMonth = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}`;

//           if (!monthlySignups[yearMonth]) {
//             monthlySignups[yearMonth] = 0;
//           }
//           monthlySignups[yearMonth]++;
//         }
//       });

//       // Format the data into CSV
//       let csvContent = "data:text/csv;charset=utf-8,";
//       csvContent += "Year-Month,Signups\n";

//       for (let yearMonth in monthlySignups) {
//         let row = `${yearMonth},${monthlySignups[yearMonth]}`;
//         csvContent += row + "\n";
//       }

//       // Trigger a download of the CSV file
//       let encodedUri = encodeURI(csvContent);
//       let link = document.createElement("a");
//       link.setAttribute("href", encodedUri);
//       link.setAttribute("download", "monthly_signups.csv");
//       document.body.appendChild(link); // Required for FF

//       link.click();
//       document.body.removeChild(link); // Clean up
//     })
//     .catch(function(error) {
//       console.error("Error getting documents: ", error);
//     });
// }

// Function to export cancelation feedback data to CSV
// exportCancelationDataToCSV();
// function exportCancelationDataToCSV() {
//   // Fetch all user data with cancellation field from Firebase
//   db.collection("users").where("cancelation", "!=", null).get()
//     .then(function(querySnapshot) {
//       let usersData = [];
//       querySnapshot.forEach(function(doc) {
//         let data = doc.data();
//         let userRecord = {
//           email: doc.id,
//           name: data.name || 'N/A',
//           createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : 'N/A',
//           feedback: data.cancelation?.feedback || 'N/A',
//           reason: data.cancelation?.reason || data.cancelation?.segment || 'N/A'
//         };
//         usersData.push(userRecord);
//       });

//       // Sort the data by createdAt in descending order
//       usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

//       // Format the data into CSV
//       let csvContent = "data:text/csv;charset=utf-8,";
//       csvContent += "Email,Name,Created At,Feedback,Reason\n";
//       usersData.forEach(function(user) {
//         let row = `${user.email},${user.name},${user.createdAt},${user.feedback},${user.reason}`;
//         csvContent += row + "\n";
//       });

//       // Trigger a download of the CSV file
//       let encodedUri = encodeURI(csvContent);
//       let link = document.createElement("a");
//       link.setAttribute("href", encodedUri);
//       link.setAttribute("download", "cancelation_data.csv");
//       document.body.appendChild(link); // Required for FF

//       link.click();
//       document.body.removeChild(link); // Clean up
//     })
//     .catch(function(error) {
//       console.error("Error getting documents: ", error);
//     });
// }


// function to retrieve all current subscribers from the database
// exportSubscribersToCSV();
// async function exportSubscribersToCSV() {
//   try {
//     const querySnapshot = await db.collection("users")
//       .where("tier", "==", 2)
//       .get();

//     let usersData = [];
//     for (const doc of querySnapshot.docs) {
//       const data = doc.data();
//       let status = 'N/A'; // Default status when no subscription_id is present
//       if (data.subscription_id) {
//         // Check if the subscription is active
//         status = await checkSubscriptionStatus(data.subscription_id);
//       }

//       const userRecord = {
//         email: doc.id,
//         name: data.name || 'N/A',
//         createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : 'N/A',
//         tier: data.tier || 'N/A',
//         subscription_id: data.subscription_id || 'N/A',
//         status
//       };
//       usersData.push(userRecord);
//     }

//     // Sort the data by createdAt in descending order
//     usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

//     // Convert to CSV
//     let csvContent = "data:text/csv;charset=utf-8,Email,Name,Created At,Tier,Subscription ID,Status\n";
//     usersData.forEach(user => {
//       const row = `${user.email},${user.name},${user.createdAt},${user.tier},${user.subscription_id},${user.status}`;
//       csvContent += row + "\n";
//     });

//     // Trigger a download of the CSV file
//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "subscribers_data.csv");
//     document.body.appendChild(link); // Required for FF
//     link.click();
//     document.body.removeChild(link); // Clean up

//   } catch (error) {
//     console.error("Error exporting subscriber data: ", error);
//   }
// }


// axios call to check status of a subscription
// async function checkSubscriptionStatus(subscriptionId) {
//   try {
//     const response = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/checksub`, { subscriptionId });
//     if (response.data.active) {
//         console.log('Subscription is active (' + response.data.subscription.status + ')', response.data.subscription);
//     } else {
//         console.log('Subscription is not active (' + response.data.subscription.status + ')', response.data.subscription);
//     }
//     return response.data.subscription.status
//   } catch (error) {
//       console.error('Error checking subscription status:', error);
//       return 'Error'
//   }
// }


// retrieve NPS score
// async function exportNPSFeedbackToCSV() {
//   try {
//     // Fetch all documents from the "feedback" collection
//     const feedbackCollectionRef = collection(db, "feedback");
//     const feedbackSnapshot = await getDocs(feedbackCollectionRef);

//     let feedbackData = [];
//     let scoreCounts = Array(11).fill(0); // Array to count scores from 0 to 10

//     feedbackSnapshot.forEach((doc) => {
//       const data = doc.data();
//       const feedbackRecord = {
//         email: doc.id,
//         score: data.score ?? "N/A",
//         submittedAt: data.submittedAt ? data.submittedAt.toDate().toISOString() : "N/A",
//         feedback: data.feedback ?? "N/A",
//       };
//       feedbackData.push(feedbackRecord);

//       if (data.score !== undefined && data.score !== null) {
//         scoreCounts[data.score]++;
//       }
//     });

//     // Sort the data by submittedAt in descending order
//     feedbackData.sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt));

//     // Convert to CSV
//     let csvContent = "Email,Score,Submitted At,Feedback\n";
//     feedbackData.forEach((record) => {
//       const row = `${record.email},${record.score},${record.submittedAt},${record.feedback}`;
//       csvContent += row + "\n";
//     });

//     // Trigger a download of the CSV file in the browser
//     const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "feedback_data.csv");
//     document.body.appendChild(link); // Required for Firefox
//     link.click();
//     document.body.removeChild(link); // Clean up

//     // Log the score counts
//     console.log("Score counts:");
//     scoreCounts.forEach((count, score) => console.log(`Score ${score}: ${count} times`));

//   } catch (error) {
//     console.error("Error exporting feedback data: ", error);
//   }
// }
// // Call the function
// exportNPSFeedbackToCSV();


// children is all components inside AuthProvider in App.js
export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()

    const [currentUserTier, setCurrentUserTier] = useState()
    const [currentTier, setCurrentTier] = useState() // not being used im pretty sure
    const [pricingPlan, setPricingPlan] = useState()
    const [UTMCode, setUTMCode] = useState()

    const [currentTeamsTier, setCurrentTeamsTier] = useState()

    const history = useHistory();

    // creating document in database
    const createUserDocument = async (user, additionalData) => {
      if (!user) {
        return;
      }
    
      const userRef = doc(db, "users", user.email); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      // if user id does not exist in database, create using set
      if (!snapshot.exists()) {
        let currDate = new Date();
        let data = {
          name: user.displayName,
          uid: user.uid,
          tier: additionalData,
          createdAt: currDate,
          subscription_id: null,
          requests: { month: currDate.getMonth(), reqCount: 0 },
        };
    
        try {
          await setDoc(userRef, data); // Updated setDoc usage
    
          try {
            // Send the signup event to Google Analytics after the user is successfully created
            window.gtag &&
              window.gtag("event", "signup", {
                event_category: "Account",
                event_label: "Signup",
                value: 1,
              });
            console.log("New SignUp");
          } catch (error) {
            console.log("Error in tracking signup in Google Analytics", error);
          }
    
          // Amplitude tracking
          amplitude.setUserId(user.uid); // Unique identifier for the user
          const identify = new amplitude.Identify()
            .set("email", user.email)
            .set("name", user.displayName)
            .set("createdAt", currDate);
          amplitude.identify(identify);
    
          amplitude.track("Sign Up");
        } catch (error) {
          console.log("Error in creating user", error);
        }
    
        try {
          await addEmailToCampaign(user.email, user.displayName, "Existing Users🚗");
        } catch (error) {
          console.log("Error in adding user to email campaign", error);
        }
      }
    };

    // calls routora api endpoint to add an email to group in mailerlite
    const addEmailToCampaign = async (email, name, group) => {

      let body = {
        email: email,
        name: name,
        group: group
      }

      Axios.post(`https://api.routora.com/addEmailToCampaign`, body).then((response) => {
          console.log('Status for Adding Email to Campaign: ' + response)
      }).catch((error) => {
          console.log(error)
      })

    }

    const getUserTier = async (user) => {
      if (!user) {
        return;
      }
    
      const userRef = doc(db, "users", user.email); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      if (snapshot.exists()) {
        return snapshot.data().tier; // Use snapshot.data() directly
      }
    };

    const updateUserTier = async (user, newTier) => {
      if (!user) {
        return;
      }
    
      const userRef = doc(db, "users", user.email); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      if (snapshot.exists()) {
        let data = {
          tier: newTier,
        };
    
        try {
          await updateDoc(userRef, data); // Updated updateDoc usage
          setCurrentUserTier(newTier);
        } catch (error) {
          console.log("Error in updating user", error);
        }
      }
    };

    const getSubId = async (user) => {
      if (!user) {
        return;
      }
    
      const userRef = doc(db, "users", user.email); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      if (snapshot.exists()) {
        return snapshot.data().subscription_id; // Use snapshot.data() directly
      }
    };

    const updateSubId = async (user, subId) => {
      if (!user) {
        return;
      }
    
      const userRef = doc(db, "users", user.email); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      if (snapshot.exists()) {
        let data = {
          subscription_id: subId,
        };
    
        try {
          await updateDoc(userRef, data); // Updated updateDoc usage
        } catch (error) {
          console.log("Error in updating user", error);
        }
      }
    };

    const addOnboarding = async (user, responses) => {
      if (!user) {
        return;
      }
    
      const userRef = doc(db, "users", user.email); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      if (snapshot.exists()) {
        let data = {
          onboarding: responses,
        };
    
        try {
          await updateDoc(userRef, data); // Updated updateDoc usage
    
          // Amplitude tracking
          const eventProperties = {
            segment: responses.segment,
            medium: responses.medium,
            organization: responses.organization,
          };
          amplitude.track("Onboarding", eventProperties);
    
          // Set group properties for segment and medium
          amplitude.setGroup("segment", responses.segment);
          amplitude.setGroup("medium", responses.medium);
        } catch (error) {
          console.error("Error in updating user", error);
        }
      }
    }; 
    
    const addFeedback = async (user, responses) => {
      if (!user) {
        return;
      }
    
      const userRef = doc(db, "users", user.email); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      if (snapshot.exists()) {
        let data = {
          cancelation: responses,
        };
    
        try {
          await updateDoc(userRef, data); // Updated updateDoc usage
        } catch (error) {
          console.error("Error in updating user", error);
        }
      }
    };

    const trackWebOptimizationRequest = async (userEmail, addressListLength) => {
      if (!userEmail) {
          console.error("No user email provided");
          return null;
      }
  
      const currentDate = new Date();
      const monthYear = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;
      const requestRef = doc(db, `users/${userEmail}/webRequests/${monthYear}`);
  
      try {
          const snapshot = await getDoc(requestRef);
  
          if (snapshot.exists()) {
              // Increment the request count and stop count if the document exists
              const currentCount = snapshot.data().reqCount || 0;
              const currentStopCount = snapshot.data().stopCount || 0;
  
              await updateDoc(requestRef, { 
                  reqCount: currentCount + 1, 
                  stopCount: currentStopCount + addressListLength 
              });
  
              return currentCount + 1;
          } else {
              // Create a new document for the new month
              await setDoc(requestRef, { reqCount: 1, stopCount: addressListLength });
              return 1;
          }
      } catch (error) {
          console.error("Error tracking web optimization request:", error);
          return null;
      }
    };

    const getWebMonthlyReqCount = async (userEmail) => {
      if (!userEmail) {
          console.error("No user email provided");
          return 0;
      }
  
      const currentDate = new Date();
      const monthYear = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;
      const requestRef = doc(db, `users/${userEmail}/webRequests/${monthYear}`);
  
      try {
          const snapshot = await getDoc(requestRef);
  
          if (snapshot.exists()) {
              return snapshot.data().reqCount || 0;
          } else {
              return 0;
          }
      } catch (error) {
          console.error("Error retrieving web optimization request count:", error);
          return 0;
      }
    };
  
  

    // creating document in blog
    const createBlogDocument = async (title, content, image) => {
      let imageUrl = null;
      try {
        const imageRef = ref(storage, `images/${image.name}`);
        const snapshot = await uploadBytes(imageRef, image); // Modular uploadBytes usage
        imageUrl = await getDownloadURL(snapshot.ref); // Modular getDownloadURL usage
        console.log("image url -> " + imageUrl);
      } catch (error) {
        console.log("Error in uploading image to storage", error);
      }
    
      const userRef = doc(db, "blog", title); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      if (!snapshot.exists()) {
        let currDate = new Date();
        let data = {
          title,
          content,
          timestamp: currDate,
          thumbnailUrl: imageUrl,
          viewCount: 0,
        };
    
        try {
          await setDoc(userRef, data); // Updated setDoc usage
        } catch (error) {
          console.log("Error in creating blog document", error);
        }
      }
    };

    const getAllBlogData = async () => {
      let allData = [];
      const options = { month: "short", day: "2-digit", year: "numeric" }; // Date format
      const q = query(collection(db, "blog"), orderBy("timestamp", "desc")); // Updated query usage
      const querySnapshot = await getDocs(q); // Updated getDocs usage
    
      querySnapshot.forEach((doc) => {
        let documentData = {
          title: doc.data().title,
          content: doc.data().content,
          thumbnailUrl: doc.data().thumbnailUrl,
          timestamp: doc.data().timestamp.toDate().toLocaleString("en-US", options),
        };
        allData.push(documentData);
      });
    
      console.log("ALL BLOG DATA - FIREBASE");
      console.log(allData);
    
      return allData;
    };

    const getBlogData = async (blogTitle) => {
      const userRef = doc(db, "blog", blogTitle); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      const options = { month: "short", day: "2-digit", year: "numeric" }; // Date format
    
      if (snapshot.exists()) {
        let data = snapshot.data();
        let documentData = {
          title: data.title,
          content: data.content,
          thumbnailUrl: data.thumbnailUrl,
          timestamp: data.timestamp.toDate().toLocaleString("en-US", options),
        };
    
        return documentData;
      }
    
      return false;
    };

    const updateBlogViewCount = async (blogTitle) => {
      const userRef = doc(db, "blog", blogTitle); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      if (snapshot.exists()) {
        try {
          await updateDoc(userRef, { viewCount: increment(1) }); // Use increment for atomic updates
        } catch (error) {
          console.log("Error in updating blog view count", error);
        }
      }
    };

    const updateFleetClicks = async () => {
      const userRef = doc(db, "fleetManagement", "clicks"); // Updated doc reference
      const snapshot = await getDoc(userRef); // Updated getDoc usage
    
      if (snapshot.exists()) {
        try {
          await updateDoc(userRef, { count: increment(1) }); // Use increment for atomic updates
        } catch (error) {
          console.log("Error in updating fleet clicks", error);
        }
      }
    };

    // USER FEEDBACK
    const checkFeedbackStatus = async (user) => {
      if (!user || !user.email) return false;
    
      try {
        const feedbackRef = doc(db, "feedback", user.email); // Updated doc reference
        const feedbackSnap = await getDoc(feedbackRef); // Updated getDoc usage
    
        if (feedbackSnap.exists()) {
          console.log("Feedback already submitted.");
          return false;
        }
    
        const userRef = doc(db, "users", user.email); // Updated doc reference
        const userSnap = await getDoc(userRef); // Updated getDoc usage
    
        if (userSnap.exists()) {
          const userData = userSnap.data();
          const accountCreationDate = new Date(userData.createdAt.seconds * 1000);
          const currentDate = new Date();
          const cutoffDate = new Date(currentDate.setDate(currentDate.getDate() - 10));
    
          console.log("accountCreationDate", accountCreationDate);
          console.log("cutoffDate", cutoffDate);
    
          if (accountCreationDate < cutoffDate) {
            console.log("Eligible for feedback.");
            return true;
          } else {
            console.log("Account too new for feedback.");
            return false;
          }
        }
      } catch (error) {
        console.error("Error checking feedback status: ", error);
        return false;
      }
    };
    const submitUserFeedback = async (user, score, feedback) => {
      if (!user || !user.email) return;
    
      try {
        const feedbackRef = doc(db, "feedback", user.email); // Updated doc reference
        await setDoc(feedbackRef, {
          email: user.email,
          score,
          feedback,
          submittedAt: new Date(), // Timestamp for when the feedback was submitted
        });
        console.log("Feedback successfully submitted.");
      } catch (error) {
        console.error("Error submitting feedback: ", error);
      }
    };

    // check the user's subscription status
    async function checkSubStatus(user) {
      let subscriptionId = await getSubId(user);
      if (subscriptionId == null) {
        return "Null subscriptionID";
      }
    
      try {
        const response = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/checksub`, { subscriptionId });
        return response.data.subscription.status;
      } catch (error) {
        console.error("Error checking subscription status:", error);
        return "Error checking subscription status";
      }
    }

    // get affiliate codes
    const getAffiliateCodes = async () => {
      const PROMO_CODES = {};
      const querySnapshot = await getDocs(collection(db, "affiliate")); // Updated getDocs usage
    
      querySnapshot.forEach((doc) => {
        PROMO_CODES[doc.data().code] = { price: doc.data().price, isYearly: doc.data().isYearly };
      });
    
      return PROMO_CODES;
    };

    // Track promo code usage
    const trackPromoCodeUsage = async (code, email) => {
      const promoCodeRef = doc(db, "affiliate", code); // Updated doc reference
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed in JavaScript
      const currentYear = currentDate.getFullYear();
      const monthYear = `${currentMonth}-${currentYear}`;
    
      try {
        const promoCodeDoc = await getDoc(promoCodeRef); // Updated getDoc usage
    
        if (!promoCodeDoc.exists()) {
          console.log("Promo code does not exist in the database.");
          return;
        }
    
        const usageCollectionRef = collection(promoCodeRef, "usage"); // Subcollection reference
        const usageDocRef = doc(usageCollectionRef, monthYear); // Updated doc reference
        const usageDoc = await getDoc(usageDocRef); // Updated getDoc usage
    
        if (!usageDoc.exists()) {
          // Case 1: Collection does not exist, create it with the current month
          await setDoc(usageDocRef, {
            count: 1,
            emails: [email],
          });
          console.log(`Created new usage record for ${monthYear} with count 1 and added email.`);
        } else {
          // Case 2: Collection and current month exist, increment the count and add email to the array
          await updateDoc(usageDocRef, {
            count: increment(1), // Use increment for atomic updates
            emails: arrayUnion(email), // Add email to the array without duplicates
          });
          console.log(`Incremented usage count for ${monthYear} and added email.`);
        }
      } catch (error) {
        console.error("Error tracking promo code usage: ", error);
      }
    };


    

    // if there is a user on load, update currentUser
    useEffect(() => {
        stateChange()
    }, [])
    function stateChange() {
      onAuthStateChanged(auth, async (user) => {
        setCurrentUser(user);
        console.log("in useEffect, user:", user);
        createUserDocument(user, 0);
    
        if (user) { 
          amplitude.setUserId(user.uid); // Amplitude tracking
    
          let userTier = await getUserTier(user);
          console.log('USER ' + user.displayName + ' tier: ', userTier);
          setCurrentUserTier(userTier);
    
          let teamsSub = await getTeamsSubscription(user.email);
    
          // Extract only the pathname (excluding query parameters)
          let currentPath = window.location.pathname;
          console.log('CURRENT PATH: ' + currentPath);
    
          // Get origin parameter correctly
          const searchParams = new URLSearchParams(window.location.search);
          const origin = searchParams.get("origin");
          console.log('CURRENT ORIGIN: ' + origin);
    
          if (currentPath === '/signin' || currentPath === '/signup') {
            if (teamsSub && teamsSub.tier && teamsSub.tier >= 1) {
              history.push('/teams');
              return;
            }
    
            if (userTier > 0) {
              history.push('/playground');
            } else {
              if (origin === "web") {
                history.push('/pricing-web'); // Redirect to Web Route Planner pricing page
              } else if (origin === "teams") {
                history.push('/teams/onboarding'); // Redirect to Teams onboarding page
              } else {
                history.push('/pricing/redirect'); // Default fallback
              }
            }
          } else if (currentPath === '/extension-instructions') { 
            history.push('/pricing-extension');
          }
        }
      });
    }
    
    

    function googleOauth() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
  
      signInWithPopup(auth, provider)
          .then((result) => {
              const user = result.user;
              console.log("user", user);
              console.log("result", result);
          })
          .catch((error) => {
              console.error(error);
          });
    }

    function facebookOauth() {
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
  
      signInWithPopup(auth, provider)
          .then((result) => {
              const user = result.user;
              console.log("user", user);
              console.log("result", result);
          })
          .catch((error) => {
              console.error(error);
          });
    }

    function signOutUser() {
      const auth = getAuth();
      setCurrentUserTier(null);
      signOut(auth)
          .then(() => {
              console.log("Successfully signed out");
          })
          .catch((error) => {
              console.error("Error signing out:", error);
          });
    }

    async function signUp(name, email, password) {
      const auth = getAuth();
  
      return createUserWithEmailAndPassword(auth, email, password)
          .then(async (result) => {
              const user = result.user;
              await updateProfile(user, {
                  displayName: name,
              });
              stateChange(); // Call stateChange after successful signup
              return null; // Indicate no error
          })
          .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
  
              switch (errorCode) {
                  case "auth/email-already-in-use":
                      return "This email is already in use. Please try another email or sign in.";
                  case "auth/invalid-email":
                      return "Invalid email format. Please check the email address and try again.";
                  case "auth/operation-not-allowed":
                      return "Email/password accounts are not enabled. Please contact support by emailing info@routora.com.";
                  case "auth/weak-password":
                      return "Password is too weak. Please use a stronger password.";
                  case "auth/network-request-failed":
                      return "Network error. Please check your internet connection and try again.";
                  default:
                      console.error(errorMessage);
                      return errorMessage;
              }
          });
    }
    

    async function signIn(email, password) {
      const auth = getAuth();
  
      return signInWithEmailAndPassword(auth, email, password)
          .then((result) => {
              console.log("Successfully signed in");
              stateChange();
              return null; // Indicate no error
          })
          .catch((error) => {
              const errorCode = error.code;
  
              if (errorCode === "auth/wrong-password") {
                  return "Incorrect password. Please try again.";
              } else if (errorCode === "auth/too-many-requests") {
                  return "Access to this account has been temporarily disabled due to many failed login attempts. Please reset your password or try again later.";
              } else if (errorCode === "auth/user-not-found") {
                  return "User does not exist, please ensure the email is correct. If you do not have an account, please sign up.";
              } else if (errorCode === "auth/invalid-email") {
                  return "Email is invalid. Please check again.";
              } else {
                  console.error("Error signing in:", error.message);
                  return error.message;
              }
          });
    }
    
    

    function resetPassword(email) {
      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
          .then(() => {
              console.log("Password reset email sent");
          })
          .catch((error) => {
              console.error("Error sending password reset email:", error);
          });
    }

    async function utmHit(utm) {
      const userRef = doc(db, `utmTracking/${utm}`);
      const snapshot = await getDoc(userRef);
  
      if (snapshot.exists()) {
          const docData = snapshot.data();
          const currDate = new Date();
          const day = String(currDate.getDate()).padStart(2, "0");
          const month = String(currDate.getMonth() + 1).padStart(2, "0");
          const year = currDate.getFullYear();
  
          const dateString = `${month}-${day}-${year}`;
          console.log(dateString);
  
          if (docData.days && docData.days[dateString]) {
              const currHits = docData.days[dateString].hits;
  
              try {
                  await updateDoc(userRef, {
                      [`days.${dateString}.hits`]: currHits + 1,
                  });
              } catch (error) {
                  console.error("Error updating hit count:", error);
              }
          } else {
              try {
                  await updateDoc(userRef, {
                      [`days.${dateString}`]: {
                          androidClicks: 0,
                          hits: 1,
                          iOSClicks: 0,
                      },
                  });
              } catch (error) {
                  console.error("Error updating hit data:", error);
              }
          }
      } else {
          console.log("utm does not exist");
      }
    }

    async function utmLinkHit(utm, storeType) {
      const userRef = doc(db, `utmTracking/${utm}`);
      const snapshot = await getDoc(userRef);
  
      if (snapshot.exists()) {
          const docData = snapshot.data();
          const currDate = new Date();
          const day = String(currDate.getDate()).padStart(2, "0");
          const month = String(currDate.getMonth() + 1).padStart(2, "0");
          const year = currDate.getFullYear();
          const dateString = `${month}-${day}-${year}`;
  
          if (docData.days && docData.days[dateString]) {
              try {
                  if (storeType === "ios") {
                      await updateDoc(userRef, {
                          [`days.${dateString}.iOSClicks`]: (docData.days[dateString].iOSClicks || 0) + 1,
                      });
                  } else if (storeType === "android") {
                      await updateDoc(userRef, {
                          [`days.${dateString}.androidClicks`]: (docData.days[dateString].androidClicks || 0) + 1,
                      });
                  }
              } catch (error) {
                  console.error(`Error updating ${storeType} click count:`, error);
              }
          } else {
              const newDayData = storeType === "ios"
                  ? { androidClicks: 0, hits: 1, iOSClicks: 1 }
                  : { androidClicks: 1, hits: 1, iOSClicks: 0 };
              try {
                  await updateDoc(userRef, {
                      [`days.${dateString}`]: newDayData,
                  });
              } catch (error) {
                  console.error("Error initializing daily click data:", error);
              }
          }
      } else {
          console.log("utm does not exist");
      }
    }


    /*
      FLEET AUTHCONTEXT
    */

    const createTeamProfile = async (userEmail) => {
      const userRef = doc(db, `users/${userEmail}`);
      const teamRef = doc(collection(userRef, "teams"), userEmail);

      try {
          const snapshot = await getDoc(teamRef);

          if (!snapshot.exists()) {
              const currDate = new Date();
              const data = {
                  createdAt: currDate,
                  numDrivers: 0,
                  drivers: [],
                  companyName: null,
                  industry: null,
                  role: null,
                  depotName: null,
                  startAddress: null,
                  startTime: null,
                  endAddress: null,
                  averageStopTime: 0,
                  subscription_id: null,
                  tier: 0,
              };

              await setDoc(teamRef, data);
              console.log("Team profile created successfully");
          } else {
              console.log("Team profile already exists");
          }
      } catch (error) {
          console.error("Error creating team profile:", error);
      }
    };

    const updateCompanyProfile = async (userEmail, companyName, industry, role, numDrivers) => {
      const userRef = doc(db, `users/${userEmail}`);
      const teamRef = doc(collection(userRef, "teams"), userEmail);
  
      try {
          const snapshot = await getDoc(teamRef);
  
          if (!snapshot.exists()) {
              const currDate = new Date();
              const data = {
                  createdAt: currDate,
                  numDrivers: numDrivers || 0,
                  drivers: [],
                  companyName: companyName || null,
                  industry: industry || null,
                  role: role || null,
                  depotName: null,
                  startAddress: null,
                  startTime: null,
                  endAddress: null,
                  averageStopTime: 0,
                  subscription_id: null,
                  tier: 0,
              };
  
              await setDoc(teamRef, data);
              console.log("Team profile created and updated successfully.");
          } else {
              const updatedData = {
                  companyName: companyName || null,
                  numDrivers: numDrivers || 0,
                  industry: industry || null,
                  role: role || null,
              };
  
              await updateDoc(teamRef, updatedData);
              console.log("Team profile updated successfully.");
          }
      } catch (error) {
          console.error("Error updating team profile:", error);
      }
    };

    const updateDepotProfile = async (userEmail, depotName, startAddress, startTime, endAddress, averageStopTime) => {
      const userRef = doc(db, `users/${userEmail}`);
      const teamRef = doc(collection(userRef, "teams"), userEmail);
  
      try {
          const snapshot = await getDoc(teamRef);
  
          if (snapshot.exists()) {
              const updatedData = {
                  depotName: depotName || null,
                  startAddress: startAddress || null,
                  startTime: startTime || null,
                  endAddress: endAddress || null,
                  averageStopTime: averageStopTime || 0,
              };
  
              await updateDoc(teamRef, updatedData);
              console.log("Team profile updated successfully.");
          }
      } catch (error) {
          console.error("Error updating depot profile:", error);
      }
    };

    const updateDriverCount = async (userEmail, numDrivers) => {
      const userRef = doc(db, `users/${userEmail}`);
      const teamRef = doc(collection(userRef, "teams"), userEmail);
  
      try {
          const snapshot = await getDoc(teamRef);
  
          if (snapshot.exists()) {
              await updateDoc(teamRef, { numDrivers });
              console.log("Number of drivers updated successfully.");
          }
      } catch (error) {
          console.error("Error updating driver count:", error);
      }
    };

    const updateDriverInfo = async (userEmail, drivers) => {
      const userRef = doc(db, `users/${userEmail}`);
      const teamRef = doc(collection(userRef, "teams"), userEmail);
  
      try {
          const snapshot = await getDoc(teamRef);
  
          if (snapshot.exists()) {
              const updatedData = {
                  drivers,
                  numDrivers: drivers.length,
              };
  
              await updateDoc(teamRef, updatedData);
              console.log("Driver information updated successfully.");
          }
      } catch (error) {
          console.error("Error updating driver information:", error);
      }
    };
    

    const deleteTeamProfile = async (userEmail) => {
      const userRef = doc(db, `users/${userEmail}`);
      const teamRef = doc(db, `users/${userEmail}/teams/${userEmail}`);
  
      try {
          const snapshot = await getDoc(teamRef);
  
          if (snapshot.exists()) {
              await deleteDoc(teamRef);
              console.log("Team profile deleted successfully");
          } else {
              console.log("Team profile does not exist");
          }
      } catch (error) {
          console.error("Error deleting team profile:", error);
      }
    };

    const getTeamProfile = async (userEmail) => {
      if (!userEmail) {
          console.error("No email provided");
          return null;
      }
  
      const teamRef = doc(db, `users/${userEmail}/teams/${userEmail}`);
  
      try {
          const snapshot = await getDoc(teamRef);
  
          if (snapshot.exists()) {
              return snapshot.data();
          } else {
              console.log("Team profile does not exist");
              return null;
          }
      } catch (error) {
          console.error("Error retrieving team profile:", error);
          return null;
      }
    };

    const getTeamsSubscription = async (userEmail) => {
      const teamRef = doc(db, `users/${userEmail}/teams/${userEmail}`);
  
      try {
          const snapshot = await getDoc(teamRef);
  
          if (!snapshot.exists()) {
              console.log("No teams collection or document exists for this user.");
              return null; // Return null if no document exists
          }
  
          const teamData = snapshot.data();
          const { tier, subscription_id } = teamData;
  
          return {
              tier: tier ?? null,
              subscription_id: subscription_id ?? null,
          };
      } catch (error) {
          console.error("Error retrieving subscription information:", error);
          throw new Error("Failed to fetch subscription information.");
      }
    };

    const getUserProfile = async (email) => {
      if (!email) {
          console.error("No email provided");
          return null;
      }
  
      const userRef = doc(db, `users/${email}`);
  
      try {
          const snapshot = await getDoc(userRef);
  
          if (snapshot.exists()) {
              return snapshot.data();
          } else {
              console.log("User profile does not exist");
              return null;
          }
      } catch (error) {
          console.error("Error retrieving user profile:", error);
          return null;
      }
    };

    const updateTeamProfile = async (user, data) => {
      if (!user) {
          console.error("No user provided");
          return;
      }
  
      const teamRef = doc(db, `users/${user.email}/teams/${user.email}`);
  
      try {
          await updateDoc(teamRef, data);
          console.log("Team profile updated successfully");
      } catch (error) {
          console.error("Error updating team profile:", error);
      }
    };


    // route calendar history collection creation and adding route
    const addTeamsRoutes = async (userEmail, date, driverRoutes) => {
      const routeRef = doc(db, `users/${userEmail}/teamsRoutes/${date}`);
  
      try {
          const snapshot = await getDoc(routeRef);
  
          if (!snapshot.exists()) {
              await setDoc(routeRef, driverRoutes);
              console.log(`Team route stored successfully for ${date}`);
          } else {
              await updateDoc(routeRef, driverRoutes);
              console.log(`Team route updated successfully for ${date}`);
          }
      } catch (error) {
          console.error("Error saving team route:", error);
      }
    };

    const getTeamsRoute = async (userEmail, date) => {
      const routeRef = doc(db, `users/${userEmail}/teamsRoutes/${date}`);
  
      try {
          const snapshot = await getDoc(routeRef);
  
          if (snapshot.exists()) {
              console.log(`Team route found for ${date}`);
              return snapshot.data(); // Return the document data
          } else {
              console.log(`No team route found for ${date}`);
              return null; // Return null if no document is found
          }
      } catch (error) {
          console.error("Error getting team route:", error);
          return null;
      }
    };

    const updateTeamsSub = async (userEmail, subscription_id, tier) => {
      const teamRef = doc(db, `users/${userEmail}/teams/${userEmail}`);
  
      try {
          const snapshot = await getDoc(teamRef);
  
          if (snapshot.exists()) {
              const updatedData = {
                  subscription_id: subscription_id || null,
                  tier: tier || 0,
              };
  
              await updateDoc(teamRef, updatedData);
              console.log("Team subscription and tier updated successfully.");
              return true;
          } else {
              console.log("Team profile does not exist. Cannot update subscription or tier.");
              return false;
          }
      } catch (error) {
          console.error("Error updating team subscription and tier:", error);
          return false;
      }
    };

    const addTeamCancelationFeedback = async (userEmail, responses) => {
      const teamRef = doc(db, `users/${userEmail}/teams/${userEmail}`);
  
      try {
          const snapshot = await getDoc(teamRef);
  
          if (snapshot.exists()) {
              const updatedData = {
                  cancelation: responses,
              };
  
              await updateDoc(teamRef, updatedData);
              console.log("Cancellation feedback added successfully.");
          } else {
              console.log("Team profile does not exist. Cannot add cancellation feedback.");
          }
      } catch (error) {
          console.error("Error adding cancellation feedback:", error);
      }
    };

    const trackOptimizationRequest = async (userEmail, addressListLength = 0) => {
      if (!userEmail) {
          console.error("No user email provided");
          return null;
      }
  
      if (typeof addressListLength !== "number" || addressListLength < 0) {
          console.error("Invalid address list length, defaulting to 0");
          addressListLength = 0;
      }
  
      const currentDate = new Date();
      const monthYear = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;
      const requestRef = doc(db, `users/${userEmail}/teamsRequests/${monthYear}`);
  
      try {
          const snapshot = await getDoc(requestRef);
  
          if (snapshot.exists()) {
              // Increment counts if the document exists
              const currentCount = snapshot.data().reqCount || 0;
              const currentStopCount = snapshot.data().stopCount || 0;
  
              await updateDoc(requestRef, {
                  reqCount: currentCount + 1,
                  stopCount: currentStopCount + addressListLength
              });
  
              console.log(`Updated ${monthYear}: reqCount=${currentCount + 1}, stopCount=${currentStopCount + addressListLength}`);
              return currentCount + 1;
          } else {
              // Create a new document for the new month
              await setDoc(requestRef, { reqCount: 1, stopCount: addressListLength });
  
              console.log(`New document for ${monthYear} created: reqCount=1, stopCount=${addressListLength}`);
              return 1;
          }
      } catch (error) {
          console.error("Error tracking optimization request:", error);
          return null;
      }
    };

    const getMonthlyReqCount = async (userEmail) => {
      if (!userEmail) {
          console.error("No user email provided");
          return 0;
      }
  
      const currentDate = new Date();
      const monthYear = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;
      const requestRef = doc(db, `users/${userEmail}/teamsRequests/${monthYear}`);
  
      try {
          const snapshot = await getDoc(requestRef);
  
          if (snapshot.exists()) {
              return snapshot.data().reqCount || 0;
          } else {
              return 0; // No requests yet for the month
          }
      } catch (error) {
          console.error("Error retrieving monthly request count:", error);
          return 0;
      }
    };
  
  
  
  
    



    const value = {
        currentUser,
        currentTier,
        googleOauth,
        facebookOauth,
        signOutUser,
        signUp,
        signIn,
        resetPassword,
        setPricingPlan,
        pricingPlan,
        getUserTier,
        updateUserTier,
        setCurrentUserTier,
        getSubId,
        updateSubId,
        currentUserTier,
        createBlogDocument,
        getAllBlogData,
        getBlogData,
        updateBlogViewCount,
        setUTMCode,
        UTMCode,
        utmHit,
        utmLinkHit,
        updateFleetClicks,
        addOnboarding,
        addFeedback,
        checkFeedbackStatus,
        submitUserFeedback,
        checkSubStatus,
        getAffiliateCodes,
        trackPromoCodeUsage,
        createTeamProfile,
        updateCompanyProfile, 
        updateDepotProfile,
        updateDriverCount,
        updateDriverInfo,
        deleteTeamProfile,
        getTeamProfile,
        updateTeamProfile, 
        getUserProfile,
        getTeamsSubscription,
        addTeamsRoutes,
        getTeamsRoute,
        updateTeamsSub,
        addTeamCancelationFeedback,
        trackOptimizationRequest,
        getMonthlyReqCount,
        trackWebOptimizationRequest,
        getWebMonthlyReqCount
    }

    // every page will have access to data in value, AuthContext.Provider wrapped around children, which is every component inside AuthContext in app.js
    return (
        <AuthContext.Provider value={value}>
          {children}
        </AuthContext.Provider>
      )
}


/* OLD AUTHCONTEXT CODE */

// import { createContext, useState, useEffect } from "react";
// import firebase from 'firebase/compat/app'
// import firebaseAuth from 'firebase/compat/auth'
// import 'firebase/compat/firestore'
// import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
// import { doc, getDoc, updateDoc, FieldValue } from "firebase/firestore";

// import { useHistory } from 'react-router-dom';

// import React from 'react';
// import Axios from 'axios';

// import * as amplitude from '@amplitude/analytics-browser';
// amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

// // initial creation of context, will be placed inside useContext() in other components
// export const AuthContext = createContext(null);

// const app = firebase.initializeApp({
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: "gs://romioauth.appspot.com",
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
//   })
// const db = firebase.firestore()
// const storage = getStorage(app);

// // function to get all the business emails
// // businessEmails()
// // function businessEmails() {
// //   let emailArr = []
// //   let fullEmailArr = []
// //   console.log('***** DATA OF EACH DOCUMENT *****')
// //   db.collection("users").get().then(function(querySnapshot) {
// //     querySnapshot.forEach(function(doc) {
// //         // doc.data() is never undefined for query doc snapshots
// //         let email = doc.id
// //         let email1 = email.split('@')[1]
        
// //         let index = email1.length-1
// //         for (let i = email1.length-2; i >= 0; i--) {
// //           if (email1.substring(i,i+1) == '.') {
// //             index = i
// //           }
// //         }

// //         let email2 = email1.substring(0,index)
// //         if (email2 != 'gmail') {
// //           emailArr.push(email2)
// //           fullEmailArr.push(email)
// //           console.log(email2)
// //         }
        
// //     });
// //   });

// //   console.log(emailArr)
// //   console.log(fullEmailArr)
// // }

// // function to get ALL the emails on firebase
// // allEmails()
// // function allEmails() {
// //   let fullEmailArr = []
// //   console.log('***** DATA OF EACH DOCUMENT *****')
// //   db.collection("users").get().then(function(querySnapshot) {
// //     querySnapshot.forEach(function(doc) {
// //         // doc.data() is never undefined for query doc snapshots
// //         let email = doc.id
// //         let name = doc.data().name

// //         fullEmailArr.push({email: email, name: name})
        
// //     });
// //   });

// //   return fullEmailArr
// // }

// // function to get a breakdown of onboarding breakdown
// // console.log('Breakdown of Onboarding Questions')
// // onboardingBreakdown()
// // function onboardingBreakdown() {
// //   let medium = {
// //     google: 0,
// //     article: 0,
// //     friend: 0,
// //     ad: 0,
// //     email: 0,
// //     social: 0,
// //     other: 0
// //   };
// //   let segment = {
// //     delivery: 0,
// //     personal: 0,
// //     travel: 0,
// //     realestate: 0,
// //     fieldservice: 0,
// //     sales: 0,
// //     healthcare: 0,
// //     other: 0
// //   };

// //   db.collection("users").where("onboarding", "!=", null).get().then(function(querySnapshot) {
// //     querySnapshot.forEach(function(doc) {
// //       let data = doc.data();
// //       if (data.onboarding && data.onboarding.medium) {
        
// //         if (data.onboarding.medium == 'Google') {
// //           medium.google = medium.google + 1
// //         } else if (data.onboarding.medium == 'Article/Blog') {
// //           medium.article = medium.article + 1
// //         } else if (data.onboarding.medium == 'Friend/Colleague') {
// //           medium.friend = medium.friend + 1
// //         } else if (data.onboarding.medium == 'Ad') {
// //           medium.ad = medium.ad + 1
// //         } else if (data.onboarding.medium == 'Email') {
// //           medium.email = medium.email + 1
// //         } else if (data.onboarding.medium == 'Social Media') {
// //           medium.social = medium.social + 1
// //         } else if (data.onboarding.medium == 'Other') {
// //           medium.other = medium.other + 1
// //         }

// //       }
      
// //       if (data.onboarding && data.onboarding.segment) {
        
// //         if (data.onboarding.segment == 'Delivery') {
// //           segment.delivery = segment.delivery + 1
// //         } else if (data.onboarding.segment == 'Personal Use') {
// //           segment.personal = segment.personal + 1
// //         } else if (data.onboarding.segment == 'Travel') {
// //           segment.travel = segment.travel + 1
// //         } else if (data.onboarding.segment == 'Real Estate') {
// //           segment.realestate = segment.realestate + 1
// //         } else if (data.onboarding.segment == 'Field Service') {
// //           segment.fieldservice = segment.fieldservice + 1
// //         } else if (data.onboarding.segment == 'Sales') {
// //           segment.sales = segment.sales + 1
// //         } else if (data.onboarding.segment == 'Health Care') {
// //           segment.healthcare = segment.healthcare + 1
// //         } else if (data.onboarding.segment == 'Other') {
// //           segment.other = segment.other + 1
// //         }
        
// //       }
// //     });

// //     console.log('Medium breakdown:', medium);
// //     console.log('Segment breakdown:', segment);
// //   })
// //   .catch(function(error) {
// //     console.error("Error getting documents: ", error);
// //   });

// //   return true; // This will return immediately, not wait for the database operation
// // }

// // Export the onboarding data at an individual level
// // exportOnboardingBreakdownToCSV();
// // function exportOnboardingBreakdownToCSV() {
// //   // Fetch the data from Firebase
// //   db.collection("users").where("onboarding", "!=", null).get()
// //     .then(function(querySnapshot) {
// //       let usersData = [];
// //       querySnapshot.forEach(function(doc) {
// //         let data = doc.data();
// //         let userRecord = {
// //           email: doc.id,
// //           subscription_id: data.subscription_id,
// //           createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : 'N/A',
// //           medium: data.onboarding.medium || 'N/A',
// //           segment: data.onboarding.segment || 'N/A',
// //           organization: data.onboarding.organization || 'N/A'
// //         };
// //         usersData.push(userRecord);
// //       });

// //       // Sort the data by createdAt in descending order
// //       usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

// //       // Format the data into CSV
// //       let csvContent = "data:text/csv;charset=utf-8,";
// //       csvContent += "Email,subscription_id,Created At,Medium,Segment,Organization\n";
// //       usersData.forEach(function(user) {
// //         let row = `${user.email},${user.subscription_id},${user.createdAt},${user.medium},${user.segment},${user.organization}`;
// //         csvContent += row + "\n";
// //       });

// //       // Trigger a download of the CSV file
// //       let encodedUri = encodeURI(csvContent);
// //       let link = document.createElement("a");
// //       link.setAttribute("href", encodedUri);
// //       link.setAttribute("download", "onboarding_breakdown.csv");
// //       document.body.appendChild(link); // Required for FF

// //       link.click();
// //       document.body.removeChild(link); // Clean up
// //     })
// //     .catch(function(error) {
// //       console.error("Error getting documents: ", error);
// //     });
// // }

// // Export signup count by month (MoM growth)
// // exportMonthlySignupsToCSV();
// // function exportMonthlySignupsToCSV() {
// //   // Fetch all user data from Firebase
// //   db.collection("users").get()
// //     .then(function(querySnapshot) {
// //       let monthlySignups = {};

// //       querySnapshot.forEach(function(doc) {
// //         let data = doc.data();
// //         if (data.createdAt) {
// //           let date = data.createdAt.toDate();
// //           let yearMonth = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}`;

// //           if (!monthlySignups[yearMonth]) {
// //             monthlySignups[yearMonth] = 0;
// //           }
// //           monthlySignups[yearMonth]++;
// //         }
// //       });

// //       // Format the data into CSV
// //       let csvContent = "data:text/csv;charset=utf-8,";
// //       csvContent += "Year-Month,Signups\n";

// //       for (let yearMonth in monthlySignups) {
// //         let row = `${yearMonth},${monthlySignups[yearMonth]}`;
// //         csvContent += row + "\n";
// //       }

// //       // Trigger a download of the CSV file
// //       let encodedUri = encodeURI(csvContent);
// //       let link = document.createElement("a");
// //       link.setAttribute("href", encodedUri);
// //       link.setAttribute("download", "monthly_signups.csv");
// //       document.body.appendChild(link); // Required for FF

// //       link.click();
// //       document.body.removeChild(link); // Clean up
// //     })
// //     .catch(function(error) {
// //       console.error("Error getting documents: ", error);
// //     });
// // }

// // Function to export cancelation feedback data to CSV
// // exportCancelationDataToCSV();
// // function exportCancelationDataToCSV() {
// //   // Fetch all user data with cancellation field from Firebase
// //   db.collection("users").where("cancelation", "!=", null).get()
// //     .then(function(querySnapshot) {
// //       let usersData = [];
// //       querySnapshot.forEach(function(doc) {
// //         let data = doc.data();
// //         let userRecord = {
// //           email: doc.id,
// //           name: data.name || 'N/A',
// //           createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : 'N/A',
// //           feedback: data.cancelation?.feedback || 'N/A',
// //           reason: data.cancelation?.reason || data.cancelation?.segment || 'N/A'
// //         };
// //         usersData.push(userRecord);
// //       });

// //       // Sort the data by createdAt in descending order
// //       usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

// //       // Format the data into CSV
// //       let csvContent = "data:text/csv;charset=utf-8,";
// //       csvContent += "Email,Name,Created At,Feedback,Reason\n";
// //       usersData.forEach(function(user) {
// //         let row = `${user.email},${user.name},${user.createdAt},${user.feedback},${user.reason}`;
// //         csvContent += row + "\n";
// //       });

// //       // Trigger a download of the CSV file
// //       let encodedUri = encodeURI(csvContent);
// //       let link = document.createElement("a");
// //       link.setAttribute("href", encodedUri);
// //       link.setAttribute("download", "cancelation_data.csv");
// //       document.body.appendChild(link); // Required for FF

// //       link.click();
// //       document.body.removeChild(link); // Clean up
// //     })
// //     .catch(function(error) {
// //       console.error("Error getting documents: ", error);
// //     });
// // }


// // function to retrieve all current subscribers from the database
// // exportSubscribersToCSV();
// // async function exportSubscribersToCSV() {
// //   try {
// //     const querySnapshot = await db.collection("users")
// //       .where("tier", "==", 2)
// //       .get();

// //     let usersData = [];
// //     for (const doc of querySnapshot.docs) {
// //       const data = doc.data();
// //       let status = 'N/A'; // Default status when no subscription_id is present
// //       if (data.subscription_id) {
// //         // Check if the subscription is active
// //         status = await checkSubscriptionStatus(data.subscription_id);
// //       }

// //       const userRecord = {
// //         email: doc.id,
// //         name: data.name || 'N/A',
// //         createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : 'N/A',
// //         tier: data.tier || 'N/A',
// //         subscription_id: data.subscription_id || 'N/A',
// //         status
// //       };
// //       usersData.push(userRecord);
// //     }

// //     // Sort the data by createdAt in descending order
// //     usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

// //     // Convert to CSV
// //     let csvContent = "data:text/csv;charset=utf-8,Email,Name,Created At,Tier,Subscription ID,Status\n";
// //     usersData.forEach(user => {
// //       const row = `${user.email},${user.name},${user.createdAt},${user.tier},${user.subscription_id},${user.status}`;
// //       csvContent += row + "\n";
// //     });

// //     // Trigger a download of the CSV file
// //     const encodedUri = encodeURI(csvContent);
// //     const link = document.createElement("a");
// //     link.setAttribute("href", encodedUri);
// //     link.setAttribute("download", "subscribers_data.csv");
// //     document.body.appendChild(link); // Required for FF
// //     link.click();
// //     document.body.removeChild(link); // Clean up

// //   } catch (error) {
// //     console.error("Error exporting subscriber data: ", error);
// //   }
// // }


// // axios call to check status of a subscription
// // async function checkSubscriptionStatus(subscriptionId) {
// //   try {
// //     const response = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/checksub`, { subscriptionId });
// //     if (response.data.active) {
// //         console.log('Subscription is active (' + response.data.subscription.status + ')', response.data.subscription);
// //     } else {
// //         console.log('Subscription is not active (' + response.data.subscription.status + ')', response.data.subscription);
// //     }
// //     return response.data.subscription.status
// //   } catch (error) {
// //       console.error('Error checking subscription status:', error);
// //       return 'Error'
// //   }
// // }


// // retrieve NPS score
// // async function exportNPSFeedbackToCSV() {
// //   try {
// //     const feedbackCollection = await db.collection("feedback").get();
// //     let feedbackData = [];
// //     let scoreCounts = Array(11).fill(0); // Array to count scores from 0 to 10

// //     feedbackCollection.forEach((doc) => {
// //       const data = doc.data();
// //       const feedbackRecord = {
// //         email: doc.id,
// //         score: data.score || 'N/A',
// //         submittedAt: data.submittedAt ? data.submittedAt.toDate().toISOString() : 'N/A',
// //         feedback: data.feedback || 'N/A',
// //       };
// //       feedbackData.push(feedbackRecord);

// //       if (data.score !== undefined && data.score !== null) {
// //         scoreCounts[data.score]++;
// //       }
// //     });

// //     // Sort the data by submittedAt in descending order
// //     feedbackData.sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt));

// //     // Convert to CSV
// //     let csvContent = "Email,Score,Submitted At,Feedback\n";
// //     feedbackData.forEach(record => {
// //       const row = `${record.email},${record.score},${record.submittedAt},${record.feedback}`;
// //       csvContent += row + "\n";
// //     });

// //     // Trigger a download of the CSV file in the browser
// //     const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
// //     const link = document.createElement("a");
// //     link.setAttribute("href", encodedUri);
// //     link.setAttribute("download", "feedback_data.csv");
// //     document.body.appendChild(link); // Required for FF
// //     link.click();
// //     document.body.removeChild(link); // Clean up

// //     // Log the score counts
// //     console.log("Score counts:");
// //     for (let i = 0; i < scoreCounts.length; i++) {
// //       console.log(`Score ${i}: ${scoreCounts[i]} times`);
// //     }

// //   } catch (error) {
// //     console.error("Error exporting feedback data: ", error);
// //   }
// // }
// // // Call the function
// // exportNPSFeedbackToCSV();


// // children is all components inside AuthProvider in App.js
// export function AuthProvider({ children }) {
//     const [currentUser, setCurrentUser] = useState()

//     const [currentUserTier, setCurrentUserTier] = useState()
//     const [currentTier, setCurrentTier] = useState() // not being used im pretty sure
//     const [pricingPlan, setPricingPlan] = useState()
//     const [UTMCode, setUTMCode] = useState()

//     const history = useHistory();

//     // creating document in database
//     const createUserDocument = async (user, additionalData) => {
      
//         if (!user) {
//           return;
//         }
    
//         const userRef = db.doc(`users/${user.email}`)
//         const snapshot = userRef.get()
    
//         // if user id does not exist in database, create using set
//         if (!(await snapshot).exists) {
    
//           let currDate = new Date()
//           let data = {
//             name: user.displayName,
//             uid: user.uid,
//             tier: additionalData,
//             createdAt: currDate,
//             subscription_id: null,
//             requests: {month: currDate.getMonth(), reqCount: 0}
//           }
    
//           try {
//             await userRef.set(data);

//             try {
//               // Send the signup event to Google Analytics after the user is successfully created
//               window.gtag && window.gtag('event', 'signup', {
//                 'event_category': 'Account',
//                 'event_label': 'Signup',
//                 'value': 1
//               });
//               console.log('New SignUp')
//             } catch (error) {
//               console.log('Error in tracking signup in Google Analytics', error);
//             }

//             // amplitude tracking
//             amplitude.setUserId(user.uid); // Unique identifier for the user
//             const identify = new amplitude.Identify()
//               .set('email', user.email)
//               .set('name', user.displayName)
//               .set('createdAt', currDate);
//             amplitude.identify(identify);

//             amplitude.track('Sign Up');

//           } catch (error) {
//             console.log('Error in creating user', error);
//           }

//           try {
//             await addEmailToCampaign(user.email, user.displayName, 'Existing Users🚗')
//           } catch (error) {
//             console.log('Error in adding user to email campaign', error);
//           }
//         } 
//     }

//     // calls routora api endpoint to add an email to group in mailerlite
//     const addEmailToCampaign = async (email, name, group) => {

//       let body = {
//         email: email,
//         name: name,
//         group: group
//       }

//       Axios.post(`https://api.routora.com/addEmailToCampaign`, body).then((response) => {
//           console.log('Status for Adding Email to Campaign: ' + response)
//       }).catch((error) => {
//           console.log(error)
//       })

//     }

//     const getUserTier = async (user) => {
//       if (!user) {
//         return;
//       }
  
//       const userRef = db.doc(`users/${user.email}`)
//       const snapshot = userRef.get()
  
//       if ((await snapshot).exists) {
//         let document = await db.doc(`users/${user.email}`).get()

//         return document.data().tier
//       }
//     }

//     const updateUserTier = async (user, newTier) => {
//       if (!user) {
//         return;
//       }
  
//       const userRef = db.doc(`users/${user.email}`)
//       const snapshot = userRef.get()

//       if ((await snapshot).exists) {
//         let data = {
//           tier: newTier
//         }
  
//         try {
//           await userRef.update(data)
//           setCurrentUserTier(newTier)
//         } catch (error) {
//           console.log('Error in updating user', error);
//         }
//       } 
//     }

//     const getSubId = async (user) => {
//       if (!user) {
//         return;
//       }
  
//       const userRef = db.doc(`users/${user.email}`)
//       const snapshot = userRef.get()
  
//       if ((await snapshot).exists) {
//         let document = await db.doc(`users/${user.email}`).get()

//         return document.data().subscription_id
//       }
//     }

//     const updateSubId = async (user, subId) => {
//       if (!user) {
//         return;
//       }
  
//       const userRef = db.doc(`users/${user.email}`)
//       const snapshot = userRef.get()

//       if ((await snapshot).exists) {
//         let data = {
//           subscription_id: subId
//         }
  
//         try {
//           await userRef.update(data)
//         } catch (error) {
//           console.log('Error in updating user', error);
//         }
//       } 
//     }

//     const addOnboarding = async (user, responses) => {
//       if (!user) {
//         return;
//       }
    
//       const userRef = db.doc(`users/${user.email}`);
//       const snapshot = await userRef.get(); // Added await here
    
//       if (snapshot.exists) {
//         let data = {
//           onboarding: responses
//         };
    
//         try {
//           await userRef.update(data);

//           // amplitude tracking
//           const eventProperties = {
//             segment: responses.segment,
//             medium: responses.medium,
//             organization: responses.organization
//           };
//           amplitude.track('Onboarding', eventProperties);

//           // Set group properties for segment and medium
//           amplitude.setGroup('segment', responses.segment);
//           amplitude.setGroup('medium', responses.medium);

//         } catch (error) {
//           console.error('Error in updating user', error);
//         }
//       } 
//     }  
    
//     const addFeedback = async (user, responses) => {
//       if (!user) {
//         return;
//       }
    
//       const userRef = db.doc(`users/${user.email}`);
//       const snapshot = await userRef.get(); // Added await here
    
//       if (snapshot.exists) {
//         let data = {
//           cancelation: responses
//         };
    
//         try {
//           await userRef.update(data);
//         } catch (error) {
//           console.error('Error in updating user', error);
//         }
//       } 
//     } 

//     // creating document in blog
//     const createBlogDocument = async (title, content, image) => {

//       let imageUrl = null;
//       try {
//         const imageRef = ref(storage, `images/${image.name}`);
//         await uploadBytes(imageRef, image).then(async (snapshot) => {
//           await getDownloadURL(snapshot.ref).then((url) => {
//             console.log('image url -> ' + url)
//             imageUrl = url
//           });
//         });
//       } catch (error) {
//         console.log('Error in uploading image to storage', error);
//       }
  
//       const userRef = db.doc(`blog/${title}`)
//       const snapshot = userRef.get()
  
//       // if userRef does not exist in database, create using set
//       if (!(await snapshot).exists) {
  
//         let currDate = new Date()
//         let data = {
//           title: title,
//           content: content,
//           timestamp: currDate,
//           thumbnailUrl: imageUrl,
//           viewCount: 0
//         }
  
//         try {
//           await userRef.set(data);
//         } catch (error) {
//           console.log('Error in creating user', error);
//         }
//       } 
//     }

//     const getAllBlogData = async () => { 

//       let allData = []
//       const options = { month: 'short', day: '2-digit', year: 'numeric' }; // date format
//       await db.collection("blog").orderBy("timestamp", "desc").get().then(function(querySnapshot) {
//         querySnapshot.forEach(function(doc) {

//             let documentData = {
//               title: doc.data().title,
//               content: doc.data().content,
//               thumbnailUrl: doc.data().thumbnailUrl,
//               timestamp: doc.data().timestamp.toDate().toLocaleString('en-US', options)
//             }

//             allData.push(documentData)
        
//         });
//       });

//       console.log('ALL BLOG DATA - FIREBASE')
//       console.log(allData)

//       return allData
//     }

//     const getBlogData = async (blogTitle) => { 
//       const userRef = db.doc(`blog/${blogTitle}`)
//       const snapshot = userRef.get()
  
//       const options = { month: 'short', day: '2-digit', year: 'numeric' }; // date format
      
//       if ((await snapshot).exists) {
//         let doc = await db.doc(`blog/${blogTitle}`).get()

//         let documentData = {
//           title: doc.data().title,
//           content: doc.data().content,
//           thumbnailUrl: doc.data().thumbnailUrl,
//           timestamp: doc.data().timestamp.toDate().toLocaleString('en-US', options)
//         }

//         return documentData
//       }

//       return false
//     }

//     const updateBlogViewCount = async (blogTitle) => {
//       const userRef = db.doc(`blog/${blogTitle}`)
//       const snapshot = userRef.get()

//       if ((await snapshot).exists) {
//         let doc = await db.doc(`blog/${blogTitle}`).get()
        
//         let viewCount = doc.data().viewCount
//         let newViewCount = viewCount + 1
  
//         try {
//           await userRef.update({ viewCount: newViewCount })
//         } catch (error) {
//           console.log('Error in updating blog view count', error);
//         }
//       } 
//     }

//     const updateFleetClicks = async () => {
  
//       const userRef = db.doc(`fleetManagement/clicks`)
//       const snapshot = userRef.get()

//       if ((await snapshot).exists) {
//         let doc = await db.doc(`fleetManagement/clicks`).get()
        
//         let count = doc.data().count
//         let newCount = count + 1
  
//         try {
//           await userRef.update({ count: newCount })
//         } catch (error) {
//           console.log('Error in updating user', error);
//         }
//       } 
//     }

//     // USER FEEDBACK
//     const checkFeedbackStatus = async (user) => {
//       if (!user || !user.email) return false;
    
//       try {
//         const feedbackRef = db.doc(`feedback/${user.email}`);
//         const feedbackSnap = await feedbackRef.get();
    
//         if (feedbackSnap.exists) {
//           console.log("Feedback already submitted.");
//           return false;
//         }
    
//         const userRef = db.doc(`users/${user.email}`);
//         const userSnap = await userRef.get();
    
//         if (userSnap.exists) {
//           const userData = userSnap.data();
//           const accountCreationDate = new Date(userData.createdAt.seconds * 1000);
//           const currentDate = new Date();
//           const cutoffDate = new Date(currentDate.setDate(currentDate.getDate() - 10));

//           console.log('accountCreationDate', accountCreationDate)
//           console.log('cutoffDate', cutoffDate)
    
//           if (accountCreationDate < cutoffDate) {
//             console.log("Eligible for feedback.");
//             return true;
//           } else {
//             console.log("Account too new for feedback.");
//             return false;
//           }
//         }
//       } catch (error) {
//         console.error("Error checking feedback status: ", error);
//         return false;
//       }
//     };
//     const submitUserFeedback = async (user, score, feedback) => {
//       if (!user || !user.email) return;
    
//       try {
//         const feedbackRef = db.doc(`feedback/${user.email}`);
//         await feedbackRef.set({
//           email: user.email,
//           score: score,
//           feedback: feedback,
//           submittedAt: new Date() // Timestamp for when the feedback was submitted
//         });
//         console.log("Feedback successfully submitted.");
//       } catch (error) {
//         console.error("Error submitting feedback: ", error);
//       }
//     };

//     // check the user's subscription status
//     async function checkSubStatus(user) {

//       let subscriptionId = await getSubId(user)
//       if (subscriptionId == null) {
//         return 'Null subscriptionID'
//       }

//       try {
//         const response = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/checksub`, { subscriptionId });

//         return response.data.subscription.status

//       } catch (error) {
//           console.error('Error checking subscription status:', error);
//           return 'Error checking subscription status'
//       }
//     }

//     // get affiliate codes
//     const getAffiliateCodes = async () => { 

//       const PROMO_CODES = {}
//       await db.collection("affiliate").get().then(function(querySnapshot) {
//         querySnapshot.forEach(function(doc) {

//             PROMO_CODES[doc.data().code] = {price: doc.data().price, isYearly: doc.data().isYearly}
        
//         });
//       });

//       return PROMO_CODES
//     }

//     // Track promo code usage
//     const trackPromoCodeUsage = async (code, email) => {
//       const db = firebase.firestore();
//       const promoCodeRef = db.collection("affiliate").doc(code);
//       const currentDate = new Date();
//       const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed in JavaScript
//       const currentYear = currentDate.getFullYear();
//       const monthYear = `${currentMonth}-${currentYear}`;

//       try {
//         const promoCodeDoc = await promoCodeRef.get();

//         if (!promoCodeDoc.exists) {
//           console.log("Promo code does not exist in the database.");
//           return;
//         }

//         const usageCollectionRef = promoCodeRef.collection("usage");
//         const usageDocRef = usageCollectionRef.doc(monthYear);
//         const usageDoc = await usageDocRef.get();

//         if (!usageDoc.exists) {
//           // Case 1: Collection does not exist, create it with the current month
//           await usageDocRef.set({
//             count: 1,
//             emails: [email]
//           });
//           console.log(`Created new usage record for ${monthYear} with count 1 and added email.`);
//         } else {
//           // Case 2: Collection and current month exist, increment the count and add email to the array
//           await usageDocRef.update({
//             count: firebase.firestore.FieldValue.increment(1),
//             emails: firebase.firestore.FieldValue.arrayUnion(email)
//           });
//           console.log(`Incremented usage count for ${monthYear} and added email.`);
//         }
//       } catch (error) {
//         console.error("Error tracking promo code usage: ", error);
//       }
//     };


    

//     // if there is a user on load, update currentUser
//     useEffect(() => {
//         stateChange()
//     }, [])
//     function stateChange() {
//       firebase.auth().onAuthStateChanged(async user => {
//         setCurrentUser(user)
//         console.log("in useEffect, user:", user)
//         createUserDocument(user, 0)

//         if (user) { 
//           amplitude.setUserId(user.uid); // Unique identifier for the user set each time user logs in (amplitude tracking)

//           let userTier = await getUserTier(user)
//           console.log('USER ' + user.displayName + ' tier: ', userTier) 
//           setCurrentUserTier(userTier)

//           let teamsSub = await getTeamsSubscription(user.email)

//           // checks current path to make sure redirection only occurs when signing in
//           let currentPath = (window.location.href).split('/').slice(-1)[0]
//           console.log('CURRENT PATH: *' + currentPath + '*')

//           // when signed in, user tier determines where to go
//           if (currentPath === 'signin' || currentPath === 'signup') {

//             if (teamsSub && teamsSub.tier && teamsSub.tier >= 1) {
//               history.push('/teams')
//               return
//             }
            
//             if (userTier > 0) {
//               history.push('/playground')
//             } else {
//               console.log('pricingPlan', pricingPlan)
//               // if pricing plan is already selected from /pricing, redirect straight to /subscribe page (currently not working)
//               // if (!pricingPlan) {
//               //   history.push('/pricing')
//               // } else {
//               //   history.push('/subscribe')
//               // }
//               history.push('/pricing')
//             }
//           } else if (currentPath === 'extension-instructions') { // coming from extensions signup page (just google)
//             history.push('/pricing-extension')
//           }

//         }
        
//       })
//     }

//     function googleOauth() {
//         var provider = new firebase.auth.GoogleAuthProvider();
//         firebase.auth().signInWithPopup(provider).then(function(result) {
//           // The signed-in user info.
//           var user = result.user;
//           console.log("user", user);
//           console.log("result", result);
        
//         })
//         .catch(function(error) {
//           console.log(error);
//         });
//     }

//     function facebookOauth() {
//       var provider = new firebase.auth.FacebookAuthProvider();
//       firebase.auth().signInWithPopup(provider).then(function(result) {
//         // The signed-in user info.
//         var user = result.user;
//         console.log("user", user);
//         console.log("result", result);
      
//       })
//       .catch(function(error) {
//         console.log(error);
//       });
//     }

//     function signOutUser() {
//         setCurrentUserTier(null)
//         firebase.auth().signOut()
//     }

//     async function signUp(name, email, password) {
//       // Return the promise from createUserWithEmailAndPassword
//       return firebase.auth().createUserWithEmailAndPassword(email, password)
//         .then(async function(result) {
//           await result.user.updateProfile({
//             displayName: name
//           });
//           stateChange(); // Call stateChange after successful signup
//           return null; // Return null to indicate no error occurred
//         }).catch(function(error) {
//           var errorCode = error.code;
//           var errorMessage = error.message;
    
//           switch (errorCode) {
//             case 'auth/email-already-in-use':
//               return 'This email is already in use. Please try another email or sign in.';
//             case 'auth/invalid-email':
//               return 'Invalid email format. Please check the email address and try again.';
//             case 'auth/operation-not-allowed':
//               return 'Email/password accounts are not enabled. Please contact support by emailing info@routora.com';
//             case 'auth/weak-password':
//               return 'Password is too weak. Please use a stronger password.';
//             case 'auth/network-request-failed':
//               return 'Network error. Please check your internet connection and try again.';
//             default:
//               console.log(errorMessage);
//               return errorMessage;
//           }
//         });
//     }
    

//     async function signIn(email, password) {
//       // Return the promise from signInWithEmailAndPassword
//       return firebase.auth().signInWithEmailAndPassword(email, password)
//         .then(function(result) {
//           // User is signed in
//           console.log('Successfully signed in');
//           stateChange();
//           return null; // Return null to indicate no error occurred
//         }).catch(function(error) {
//           var errorCode = error.code;
//           var errorMessage = error.message;
          
//           if (errorCode === 'auth/wrong-password') {
//             return 'Incorrect password. Please try again.';
//           } else if (errorCode === 'auth/too-many-requests') {
//             return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.';
//           } else if (errorCode === 'auth/user-not-found') {
//             return 'User does not exist, please make sure the email is correct. If you do not have an account please sign up.'
//           } else if (errorCode === 'auth/invalid-email') {
//             return 'Email is invalid. Please check again.';
//           } else {
//             console.log(errorMessage);
//             return errorMessage;
//           }
//         });
        
//     }
    
    

//     function resetPassword(email) {
//         firebase.auth().sendPasswordResetEmail(email)
//     }

//     async function utmHit(utm) {
//       const userRef = db.doc(`utmTracking/${utm}`)
//       const snapshot = userRef.get()

//       if ((await snapshot).exists) {
//         let doc = await db.doc(`utmTracking/${utm}`).get()
        
//         let currDate = new Date();
//         const day = String(currDate.getDate()).padStart(2, '0');  // padStart ensures it's always two digits
//         const month = String(currDate.getMonth() + 1).padStart(2, '0');  // months are 0-indexed in JS
//         const year = currDate.getFullYear();

//         const dateString = `${month}-${day}-${year}`;
//         console.log(dateString)

//         if (doc.data().days && doc.data().days[dateString]) {

//           let currHits = doc.data().days[dateString].hits

//           try {

//             // Increment hits in Firestore
//             await updateDoc(userRef, {
//               [`days.${dateString}.hits`]: currHits + 1
//             });

//           } catch (error) {
//             console.log('Error in updating hit count', error);
//           }

//         } else {
          
//           // Add date in Firestore
//           await updateDoc(userRef, {
//             [`days.${dateString}`]: {
//               androidClicks: 0,
//               hits: 1,
//               iOSClicks: 0
//             }
//           });

//         }
  

//       } else {
//         console.log('utm does not exist')
//       }
//     }

//     async function utmLinkHit(utm, storeType) {
//       const userRef = db.doc(`utmTracking/${utm}`)
//       const snapshot = userRef.get()

//       if ((await snapshot).exists) {
//         let doc = await db.doc(`utmTracking/${utm}`).get()
        
//         let currDate = new Date();
//         const day = String(currDate.getDate()).padStart(2, '0');  // padStart ensures it's always two digits
//         const month = String(currDate.getMonth() + 1).padStart(2, '0');  // months are 0-indexed in JS
//         const year = currDate.getFullYear();

//         const dateString = `${month}-${day}-${year}`;

//         if (doc.data().days && doc.data().days[dateString]) {

//           if (storeType == 'ios') {
//             let currHits = doc.data().days[dateString].iOSClicks
//             try {
//               await updateDoc(userRef, {
//                 [`days.${dateString}.iOSClicks`]: currHits + 1
//               });
  
//             } catch (error) {
//               console.log('Error in updating ios hit count', error);
//             }
//           } else if (storeType == 'android') {
//             let currHits = doc.data().days[dateString].androidClicks
//             try {
//               await updateDoc(userRef, {
//                 [`days.${dateString}.androidClicks`]: currHits + 1
//               });
  
//             } catch (error) {
//               console.log('Error in updating android hit count', error);
//             }
//           }

//         } else {
          
//           if (storeType == 'ios') {
//             await updateDoc(userRef, {
//               [`days.${dateString}`]: {
//                 androidClicks: 0,
//                 hits: 1,
//                 iOSClicks: 1
//               }
//             });
//           } else if (storeType == 'android') {
//             await updateDoc(userRef, {
//               [`days.${dateString}`]: {
//                 androidClicks: 1,
//                 hits: 1,
//                 iOSClicks: 0
//               }
//             });
//           }
          

//         }
  

//       } else {
//         console.log('utm does not exist')
//       }
//     }


//     /*
//       FLEET AUTHCONTEXT
//     */

//     const createTeamProfile = async(userEmail) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teams").doc(userEmail);

//       try {
//           const snapshot = await teamRef.get();

//           if (!snapshot.exists) {
//               const currDate = new Date();
//               const data = {
//                   createdAt: currDate,
//                   numDrivers: 0,
//                   drivers: [],
//                   companyName: null,
//                   industry: null,
//                   role: null,
//                   depotName: null,
//                   startAddress: null,
//                   startTime: null,
//                   endAddress: null,
//                   averageStopTime: 0,
//                   subscription_id: null,
//                   tier: 0
//               };

//               await teamRef.set(data);
//               console.log("Team profile created successfully");
//           } else {
//               console.log("Team profile already exists");
//           }
//       } catch (error) {
//           console.error("Error creating team profile", error);
//       }
//     };

//     const updateCompanyProfile = async (userEmail, companyName, industry, role, numDrivers) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teams").doc(userEmail);
    
//       try {
//         const snapshot = await teamRef.get();
    
//         // If the team profile doesn't exist, create it with provided values
//         if (!snapshot.exists) {
//           const currDate = new Date();
//           const data = {
//             createdAt: currDate,
//             numDrivers: numDrivers || 0,
//             drivers: [],
//             companyName: companyName || null,
//             industry: industry || null,
//             role: role || null,
//             depotName: null,
//             startAddress: null,
//             startTime: null,
//             endAddress: null,
//             averageStopTime: 0,
//             subscription_id: null,
//             tier: 0
//           };
    
//           await teamRef.set(data);
//           console.log("Team profile created and updated successfully.");
//         } else {
//           // If the team profile exists, update only the specified fields
//           const updatedData = {
//             companyName: companyName || null,
//             numDrivers: numDrivers || 0,
//             industry: industry || null,
//             role: role || null
//           };
    
//           await teamRef.update(updatedData);
//           console.log("Team profile updated successfully.");
//         }
//       } catch (error) {
//         console.error("Error updating team profile", error);
//       }
//     };

//     const updateDepotProfile = async (userEmail, depotName, startAddress, startTime, endAddress, averageStopTime) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teams").doc(userEmail);
    
//       try {

//         const snapshot = await teamRef.get();

//         if (snapshot.exists) {

//           const updatedData = {
//             depotName: depotName || null,
//             startAddress: startAddress || null,
//             startTime: startTime || null,
//             endAddress: endAddress || null,
//             averageStopTime: averageStopTime || 0
//           };
    
//           await teamRef.update(updatedData);
//           console.log("Team profile updated successfully.");

//         }

//       } catch (error) {
//         console.error("Error updating team profile", error);
//       }
//     };

//     const updateDriverCount = async (userEmail, numDrivers) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teams").doc(userEmail);
    
//       try {

//         const snapshot = await teamRef.get();

//         if (snapshot.exists) {

//           const updatedData = {
//             numDrivers: numDrivers
//           };
    
//           await teamRef.update(updatedData);
//           console.log("Number of drivers updated successfully.");

//         }

//       } catch (error) {
//         console.error("Error updating driver count in team profile", error);
//       }
//     };

//     const updateDriverInfo = async (userEmail, drivers) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teams").doc(userEmail);
    
//       try {

//         const snapshot = await teamRef.get();

//         if (snapshot.exists) {

//           const updatedData = {
//             drivers: drivers,
//             numDrivers: drivers.length
//           };
    
//           await teamRef.update(updatedData);
//           console.log("Driver information updated successfully.");

//         }

//       } catch (error) {
//         console.error("Error updating driver information in team profile", error);
//       }
//     };
    

//     const deleteTeamProfile = async (userEmail) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teams").doc(userEmail);

//       try {
//           const snapshot = await teamRef.get();

//           if (snapshot.exists) {
//               await teamRef.delete();
//               console.log("Team profile deleted successfully");
//           } else {
//               console.log("Team profile does not exist");
//           }
//       } catch (error) {
//           console.error("Error deleting team profile", error);
//       }
//     }

//     const getTeamProfile = async (userEmail) => {
//       if (!userEmail) {
//           console.error("No email provided");
//           return null;
//       }

//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teams").doc(userEmail);

//       try {
//           const snapshot = await teamRef.get();

//           if (snapshot.exists) {
//               return snapshot.data();
//           } else {
//               console.log("Team profile does not exist");
//               return null;
//           }
//       } catch (error) {
//           console.error("Error retrieving team profile", error);
//           return null;
//       }
//     }

//     const getTeamsSubscription = async (userEmail) => {
//         try {
//             const userRef = db.doc(`users/${userEmail}`);
//             const teamRef = userRef.collection("teams").doc(userEmail);
    
//             // Check if the team document exists
//             const snapshot = await teamRef.get();
    
//             if (!snapshot.exists) {
//                 console.log("No teams collection or document exists for this user.");
//                 return null; // Return null if no document exists
//             }
    
//             const teamData = snapshot.data();
//             const { tier, subscription_id } = teamData;
    
//             // Return tier and subscription_id
//             return {
//                 tier: tier ?? null,
//                 subscription_id: subscription_id ?? null,
//             };
//         } catch (error) {
//             console.error("Error retrieving subscription information:", error);
//             throw new Error("Failed to fetch subscription information.");
//         }
//     };

//     const getUserProfile = async (email) => {
//       if (!email) {
//           console.error("No email provided");
//           return null;
//       }

//       const userRef = db.doc(`users/${email}`);

//       try {
//           const snapshot = await userRef.get();

//           if (snapshot.exists) {
//               return snapshot.data();
//           } else {
//               console.log("User profile does not exist");
//               return null;
//           }
//       } catch (error) {
//           console.error("Error retrieving user profile", error);
//           return null;
//       }
//     }

//     const updateTeamProfile = async (user, data) => {
//       if (!user) {
//           console.error("No user provided");
//           return;
//       }

//       const userRef = db.doc(`users/${user.email}`);
//       const teamRef = userRef.collection("teams").doc(user.email);

//       try {
//           await teamRef.update(data);
//           console.log("Team profile updated successfully");
//       } catch (error) {
//           console.error("Error updating team profile", error);
//       }
//     }


//     // route calendar history collection creation and adding route
//     const addTeamsRoutes = async(userEmail, date, driverRoutes) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teamsRoutes").doc(date);

//       try {
//           const snapshot = await teamRef.get();

//           if (!snapshot.exists) {

//               await teamRef.set(driverRoutes);
//               console.log("Team route stored successfully for " + date);
//           } else {

//               await teamRef.update(driverRoutes)
//               console.log("Team route updated successfuly for " + date);
//           }
//       } catch (error) {
//           console.error("Error saving team route", error);
//       }
//     };

//     const getTeamsRoute = async (userEmail, date) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teamsRoutes").doc(date);
    
//       try {
//         const snapshot = await teamRef.get();
    
//         if (snapshot.exists) {
//           console.log("Team route found for " + date);
//           return snapshot.data(); // Return the document data
//         } else {
//           console.log("No team route found for " + date);
//           return null; // Return null if no document is found
//         }
//       } catch (error) {
//         console.error("Error getting team route", error);
//         return null;
//       }
//     };

//     const updateTeamsSub = async (userEmail, subscription_id, tier) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teams").doc(userEmail);
  
//       try {
//           const snapshot = await teamRef.get();
  
//           if (snapshot.exists) {
//               // Update the subscription_id and tier fields
//               const updatedData = {
//                   subscription_id: subscription_id || null,
//                   tier: tier || 0,
//               };
  
//               await teamRef.update(updatedData);
//               console.log("Team subscription and tier updated successfully.");
//               return true
//           } else {
//               console.log("Team profile does not exist. Cannot update subscription or tier.");
//               return false
//           }
//       } catch (error) {
//           console.error("Error updating team subscription and tier", error);
//           return false
//       }
//     };

//     const addTeamCancelationFeedback = async (userEmail, responses) => {
//       const userRef = db.doc(`users/${userEmail}`);
//       const teamRef = userRef.collection("teams").doc(userEmail);
  
//       try {
//           const snapshot = await teamRef.get();
  
//           if (snapshot.exists) {
//               // Add the responses object to the team's profile
//               const updatedData = {
//                   cancelation: responses,
//               };
  
//               await teamRef.update(updatedData);
//               console.log("Cancellation feedback added successfully.");
//           } else {
//               console.log("Team profile does not exist. Cannot add cancellation feedback.");
//           }
//       } catch (error) {
//           console.error("Error adding cancellation feedback", error);
//       }
//     };
  
  
    



//     const value = {
//         currentUser,
//         currentTier,
//         googleOauth,
//         facebookOauth,
//         signOutUser,
//         signUp,
//         signIn,
//         resetPassword,
//         setPricingPlan,
//         pricingPlan,
//         getUserTier,
//         updateUserTier,
//         setCurrentUserTier,
//         getSubId,
//         updateSubId,
//         currentUserTier,
//         createBlogDocument,
//         getAllBlogData,
//         getBlogData,
//         updateBlogViewCount,
//         setUTMCode,
//         UTMCode,
//         utmHit,
//         utmLinkHit,
//         updateFleetClicks,
//         addOnboarding,
//         addFeedback,
//         checkFeedbackStatus,
//         submitUserFeedback,
//         checkSubStatus,
//         getAffiliateCodes,
//         trackPromoCodeUsage,
//         createTeamProfile,
//         updateCompanyProfile, 
//         updateDepotProfile,
//         updateDriverCount,
//         updateDriverInfo,
//         deleteTeamProfile,
//         getTeamProfile,
//         updateTeamProfile, 
//         getUserProfile,
//         getTeamsSubscription,
//         addTeamsRoutes,
//         getTeamsRoute,
//         updateTeamsSub,
//         addTeamCancelationFeedback
//     }

//     // every page will have access to data in value, AuthContext.Provider wrapped around children, which is every component inside AuthContext in app.js
//     return (
//         <AuthContext.Provider value={value}>
//           {children}
//         </AuthContext.Provider>
//       )
// }