import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { validateInspectionDurationData, validateColumns, REQUIRED_COLUMNS } from '../utils/inspectionDurationValidation';

const InspectionDurationFileUpload = (props) => {
    const { onFileLoaded, status, inputId, useDragDrop = false } = props;
    const [fileName, setFileName] = useState('');
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [invalidRows, setInvalidRows] = useState([]);
    const [showValidationResults, setShowValidationResults] = useState(false);
    const [columnValidationError, setColumnValidationError] = useState(null);
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [hasValidData, setHasValidData] = useState(false);
    const [validRowCount, setValidRowCount] = useState(0);

    const processData = (json) => {
        // Process in chunks for progress indication
        return json.map((row, index) => {
            if (index % 1000 === 0) {
                setProgress(Math.round((index / json.length) * 100));
            }
            return row;
        });
    };

    const handleFileRead = (file) => {
        setFileName(file.name);
        setError('');
        setIsProcessing(true);
        setProgress(0);
        setShowValidationResults(false);
        setInvalidRows([]);
        setColumnValidationError(null);
        
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const acceptedExtensions = ['xlsx', 'xls', 'csv'];

        if (!acceptedExtensions.includes(fileExtension)) {
            setFileName('');
            setError("Please upload an Excel or CSV file.");
            setIsProcessing(false);
            return;
        }

        // File size check (100MB limit)
        if (file.size > 100 * 1024 * 1024) {
            setError("File size exceeds 100MB limit. Please contact support for larger files.");
            setIsProcessing(false);
            return;
        }

        const reader = new FileReader();
        
        reader.onload = async (e) => {
            try {
                let data;
                if (fileExtension === 'csv') {
                    data = e.target.result;
                } else {
                    data = new Uint8Array(e.target.result);
                }

                // Process the file in a separate async operation to prevent UI blocking
                setTimeout(async () => {
                    try {
                        const workbook = XLSX.read(data, { 
                            type: fileExtension === 'csv' ? 'string' : 'array',
                            cellDates: true,
                            dateNF: 'yyyy-mm-dd'
                        });
                        
                        const sheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[sheetName];
                        const json = XLSX.utils.sheet_to_json(worksheet, {
                            raw: false,
                            dateNF: 'yyyy-mm-dd',
                            defval: '' // Set default value for empty cells
                        });

                        if (json.length === 0) {
                            throw new Error("The file appears to be empty.");
                        }

                        // Validate columns first
                        const columnValidation = validateColumns(json);
                        if (!columnValidation.isValid) {
                            setColumnValidationError(columnValidation);
                            setIsProcessing(false);
                            return;
                        }

                        // Proceed with row validation if columns are valid
                        const invalidResults = validateInspectionDurationData(json);
                        setInvalidRows(invalidResults);
                        setShowValidationResults(true);

                        // Only pass valid rows to the parent component
                        const validRows = json.filter((row, index) => 
                            !invalidResults.some(invalid => invalid.rowIndex === index + 2)
                        );

                        setValidRowCount(validRows.length);
                        setHasValidData(validRows.length > 0);
                        setProgress(100);
                        onFileLoaded('inspectionDuration', validRows, invalidResults);
                    } catch (error) {
                        setError(error.message || "Error processing file. Please ensure it's properly formatted.");
                        console.error('File processing error:', error);
                    } finally {
                        setIsProcessing(false);
                    }
                }, 100);

            } catch (error) {
                setFileName('');
                setError("Error reading file. Please ensure it's properly formatted.");
                console.error('File reading error:', error);
                setIsProcessing(false);
            }
        };

        reader.onerror = () => {
            setError("Error reading file. Please try again.");
            setIsProcessing(false);
        };

        if (fileExtension === 'csv') {
            reader.readAsText(file);
        } else {
            reader.readAsArrayBuffer(file);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleFileRead(file);
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            handleFileRead(file);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.xlsx, .xls, .csv',
        maxFiles: 1,
        maxSize: 100 * 1024 * 1024
    });

    // Function to toggle row expansion
    const toggleRowExpansion = (rowIndex) => {
        setExpandedRows(prev => {
            const newSet = new Set(prev);
            if (newSet.has(rowIndex)) {
                newSet.delete(rowIndex);
            } else {
                newSet.add(rowIndex);
            }
            return newSet;
        });
    };

    // Function to format cell value for display
    const formatCellValue = (value) => {
        if (value === null || 
            value === undefined || 
            value === '' || 
            String(value).trim() === '') {
            return '(empty)';
        }
        return String(value);
    };

    return (
        <div className="max-w-6xl mx-auto space-y-8">
            {useDragDrop ? (
                <div 
                    {...getRootProps()} 
                    className={`w-full p-8 border-2 border-dashed rounded-lg cursor-pointer transition-colors duration-200 ease-in-out
                        ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 bg-white hover:bg-gray-50'}
                        ${isProcessing ? 'pointer-events-none' : ''}`}
                >
                    <input {...getInputProps()} />
                    <div className="space-y-3 text-center">
                        <svg 
                            className="mx-auto h-12 w-12 text-gray-400" 
                            stroke="currentColor" 
                            fill="none" 
                            viewBox="0 0 48 48" 
                            aria-hidden="true"
                        >
                            <path 
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" 
                                strokeWidth={2} 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                            />
                        </svg>
                        
                        <div className="flex justify-center">
                            <button 
                                type="button"
                                className={`px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                    ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isProcessing}
                            >
                                {isProcessing ? 'Processing...' : 'Choose file'}
                            </button>
                        </div>
                        
                        <p className="text-sm text-gray-500">
                            or drag and drop your inspection duration file here
                        </p>
                        
                        <p className="text-xs text-gray-500">
                            Excel or CSV files up to 100MB
                        </p>
                    </div>
                </div>
            ) : (
                <input
                    type="file"
                    id={inputId}
                    className="hidden"
                    accept=".xlsx, .xls, .csv"
                    onChange={handleFileChange}
                />
            )}

            {fileName && (
                <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                    <div className="flex items-center">
                        <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        <span className="text-sm text-gray-600">{fileName}</span>
                    </div>
                    {isProcessing && (
                        <div className="flex items-center">
                            <div className="w-24 bg-gray-200 rounded-full h-2 mr-2">
                                <div 
                                    className="bg-blue-500 h-2 rounded-full transition-all duration-300" 
                                    style={{ width: `${progress}%` }}
                                ></div>
                            </div>
                            <span className="text-sm text-gray-500">{progress}%</span>
                        </div>
                    )}
                </div>
            )}

            {error && (
                <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                    <div className="flex items-center">
                        <svg className="h-5 w-5 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        <p className="ml-3 text-sm text-red-700">
                            {error}
                        </p>
                    </div>
                </div>
            )}

            {columnValidationError && (
                <div className="mt-8 bg-red-50 border border-red-200 rounded-lg p-6">
                    <h3 className="text-lg font-medium text-red-800">
                        Missing Required Columns
                    </h3>
                    <p className="mt-2 text-sm text-red-600">
                        Your file is missing the following required columns:
                    </p>
                    <ul className="mt-3 list-disc list-inside text-sm text-red-600">
                        {columnValidationError.missingColumns.map((column, index) => (
                            <li key={index}>{column}</li>
                        ))}
                    </ul>
                    <div className="mt-4 bg-white rounded-lg p-4 border border-red-100">
                        <p className="text-sm text-gray-700 font-medium">Required Column Format:</p>
                        <code className="mt-2 block text-xs text-gray-600 bg-gray-50 p-3 rounded">
                            {REQUIRED_COLUMNS.join(', ')}
                        </code>
                    </div>
                </div>
            )}

            {showValidationResults && invalidRows.length > 0 && !columnValidationError && (
                <div className="mt-8 bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="px-4 py-5 sm:px-6 bg-red-50 text-center">
                        <h3 className="text-lg leading-6 font-medium text-red-800">
                            Validation Errors Found
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-red-600 mx-auto">
                            {invalidRows.length} rows contain errors and will be excluded from import
                        </p>
                    </div>
                    <div className="border-t border-gray-200">
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="w-12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Expand
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Row
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Complaint Category
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Errors
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {invalidRows.map((row) => (
                                        <React.Fragment key={row.rowIndex}>
                                            <tr className="hover:bg-gray-50 cursor-pointer" onClick={() => toggleRowExpansion(row.rowIndex)}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <button
                                                        className="text-gray-400 hover:text-gray-600 focus:outline-none"
                                                        aria-expanded={expandedRows.has(row.rowIndex)}
                                                    >
                                                        <svg
                                                            className={`h-5 w-5 transform transition-transform ${
                                                                expandedRows.has(row.rowIndex) ? 'rotate-180' : ''
                                                            }`}
                                                            fill="none"
                                                            stroke="currentColor"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M19 9l-7 7-7-7"
                                                            />
                                                        </svg>
                                                    </button>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {row.rowIndex}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {row.rawData['Complaint Category'] || 'N/A'}
                                                </td>
                                                <td className="px-6 py-4 text-sm text-red-500">
                                                    <ul className="list-disc list-inside">
                                                        {row.errors.map((error, index) => (
                                                            <li key={index}>{error}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                            {expandedRows.has(row.rowIndex) && (
                                                <tr className="bg-gray-50">
                                                    <td colSpan="4" className="px-6 py-4">
                                                        <div className="text-sm text-gray-900">
                                                            <h4 className="font-medium mb-2">Row Details:</h4>
                                                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                                                {REQUIRED_COLUMNS.map((column) => (
                                                                    <div key={column} className="overflow-hidden">
                                                                        <dt className="text-xs font-medium text-gray-500 truncate">
                                                                            {column}
                                                                        </dt>
                                                                        <dd className="mt-1 text-sm text-gray-900 break-all">
                                                                            {formatCellValue(row.rawData[column])}
                                                                        </dd>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {status.status === 'success' && (
                <div className="bg-green-50 border border-green-200 rounded-lg p-4">
                    <div className="flex items-center">
                        <svg className="h-5 w-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <p className="ml-3 text-sm text-green-700">
                            File successfully processed
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InspectionDurationFileUpload; 