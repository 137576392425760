import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../AuthContext'

function RoutoraWebDemo(props) {

    const history = useHistory();

    const { currentUser, currentUserTier } = useContext(AuthContext);

    function goSignUp() {
        if (currentUser) {
            if (currentUserTier == 2) {
                history.push('/playground')
            } else {
                history.push('/pricing-web')
            }
        } else {

            // google tracking
            try {
                // Send the trial start event to Google Analytics (and ultimately Google Ads) after the user is successfully created
                window.gtag && window.gtag('event', 'web_button_click', {
                'event_category': 'Button Click',
                'event_label': 'Web Button Click',
                'value': 0.1
                });
                console.log('Web Button Click')
            } catch (error) {
                console.log('Error in tracking web button click start in Google Analytics', error);
            }

            history.push('/signup?origin=web')
        }
    }

  return (
    <section className="relative">

        {!props.isWebIntro && 
            <div>
            <div className="absolute inset-0 bg-white pointer-events-none mb-16" aria-hidden="true"></div>
            <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
            </div>
        }

        <div id="web-intro" className="relative max-w-6xl mx-auto px-4 sm:px-6">

            <div className="max-w-3xl mx-auto text-center pb-4 md:pb-10 pt-20">
                <h1 className="h2 mb-4 text-myblue-900">Routora on Web</h1>
                <p className="md:text-xl text-lg text-gray-600 mb-7">Create your optimized route in less than 2 minutes. Spend less time on the road and more time for what's important with the Routora Web Route Planner.</p>
                <div>
                    <a onClick={goSignUp} className="btn cursor-pointer text-white font-semibold bg-blue-500 hover:bg-blue-600 w-1/2 mb-4 md:w-64 md:mb-0 py-4">Get Started</a>
                </div>
            </div>

            {/* looping demo video auto play on scroll */}
            <div className={`video-container ${props.isWebIntro ? 'mb-10' : 'mb-24'}`}>

                <video className="w-full rounded border" autoPlay loop muted playsInline>
                    <source src="/routora-web-demo-muted-compressed1.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                
            </div>

        </div>

    </section>
  );
}

export default RoutoraWebDemo;
