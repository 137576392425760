import React, { useEffect } from 'react';
import { AuthProvider } from './AuthContext'
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import HomeWeb from './pages/HomeWeb'
import WebIntro from './pages/WebIntro'
import TeamsIntro from './pages/TeamsIntro';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import Interface from './mycomponents/Interface/Interface'
import Pricing from './mycomponents/PricingPage/Pricing'
import PricingIntro from './mycomponents/PricingPage/PricingIntro';
import PricingTeams from './mycomponents/PricingPage/PricingTeams'
import PricingExtension from './mycomponents/PricingPage/PricingExtension'
import StripeMain from './mycomponents/StripeSub/StripeMain'
import WelcomePage from './mycomponents/WelcomePage/WelcomePage'
import ProfilePage from './mycomponents/ProfilePage/ProfilePage'
import ExtensionLandingPage from './mycomponents/ExtensionLandingPage/ExtensionLandingPage'
import ErrorPage from './mycomponents/ErrorPage/ErrorPage'
import APIDocumentation from './mycomponents/PublicAPI/APIDocumentation'
import CreatePost from './mycomponents/Blog/CreatePost';
import BlogHome from './mycomponents/Blog/BlogHome';
import Blog from './mycomponents/Blog/Blog';
import AppInstall from './mycomponents/AppInstall/AppInstall'
import SharedRoute from './mycomponents/SharedRoute/SharedRoute'
import PrivacyPolicy from './mycomponents/Forms/PrivacyPolicy'
import AppPrivacyPolicy from './mycomponents/Forms/AppPrivacyPolicy'
import TermsConditions from './mycomponents/Forms/TermsConditions'
import OnboardingQuestions from './mycomponents/WelcomePage/OnboardingQuestions';
import CancelSubForm from './mycomponents/ProfilePage/CancelSubForm'
import AccComp from './mycomponents/AccComp/AccComp';
import TeamsWaitlist from './mycomponents/TeamsWaitlist/TeamsWaitlist';
import ImportDashboard from './ny/ImportDashboard';

// Routora for Teams specific
import Dashboard from './teams/Dashboard';
import Onboarding from './teams/onboarding/view'
import CompanyDetails from './teams/onboarding/company_details';
import RouteDetails from './teams/onboarding/depot_details';
import TrialDetails from './teams/onboarding/trial_details';
import Settings from './teams/Settings/Settings'
import CancelSubFormTeams from './teams/Settings/Billing/CancelSubFormTeams';


function App() {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <AuthProvider>
          <Route exact path="/">
            {/* <Home /> */}
            <HomeWeb />
          </Route>
          <Route path="/utm/:utm">
            <HomeWeb />
          </Route>
          <Route path="/webapp">
            <HomeWeb />
          </Route>
          <Route path="/web-intro">
            <WebIntro />
          </Route>
          <Route path="/teams-intro">
            <TeamsIntro />
          </Route>
          <Route path="/playground">
            <Interface />
          </Route>
          <Route path="/pricing">
            <PricingIntro />
          </Route>
          <Route path="/pricing-web">
            <Pricing />
          </Route>
          <Route path="/pricing-teams">
            <PricingTeams />
          </Route>
          <Route path="/pricing-extension">
            <PricingExtension />
          </Route>
          <Route path="/subscribe">
            <StripeMain />
          </Route>
          <Route path="/welcome-basic">
            <WelcomePage
                tier={1}
            ></WelcomePage>
          </Route>
          <Route path="/welcome-plus">
            <WelcomePage
                tier={2}
            ></WelcomePage>
          </Route>
          <Route path="/welcome-plus/:checkout_session_id">
            <WelcomePage
                tier={2}
            ></WelcomePage>
          </Route>
          <Route path="/signin">
            <SignIn />
          </Route>
          <Route path="/signup">
            <SignUp
              fromExtension={false}
            ></SignUp>
          </Route>
          <Route path="/signup-extension">
            <SignUp
              fromExtension={true}
            ></SignUp>
          </Route>
          <Route path="/profile">
            <ProfilePage />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/extension-instructions">
            <ExtensionLandingPage />
          </Route>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path="/api-documentation">
            <APIDocumentation />
          </Route>
          <Route path="/create-post">
            <CreatePost />
          </Route>
          <Route path="/blog-main">
            <BlogHome />
          </Route>
          <Route path="/blogtest/:id">
            <Blog />
          </Route>
          <Route exact path="/app-install">
            <AppInstall />
          </Route>
          <Route path="/app-install/:utm">
            <AppInstall />
          </Route>
          <Route path="/shared-route/:dataurl/:routesurl/:coordinatesurl">
            <SharedRoute />
          </Route>
          <Route path="/shared-route-id/:routeid">
            <SharedRoute />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/app-privacy-policy">
            <AppPrivacyPolicy />
          </Route>
          <Route path="/app-terms-and-conditions">
            <TermsConditions />
          </Route>
          <Route path="/onboarding-plus">
            <OnboardingQuestions />
          </Route>
          <Route path="/cancelation">
            <CancelSubForm />
          </Route>
          <Route path="/acc">
            <AccComp />
          </Route>
          <Route path="/teamswaitlist">
            {/* <TeamsWaitlist /> */}
            <TeamsIntro />
          </Route>

          
          <Route exact path="/teams">
            <Dashboard />
          </Route>
          <Route exact path="/teams/onboarding">
            <Onboarding />
          </Route>
          <Route exact path="/teams/settings">
            <Settings />
          </Route>
          <Route exact path="/teams/settings/:sectionParam">
            <Settings />
          </Route>
          <Route exact path="/teams/cancel">
            <CancelSubFormTeams />
          </Route>
          {/* Temporary routes, just to see components as I am building them */}
          {/* <Route path="/teams/onboarding/company-details">
            <CompanyDetails />
          </Route>
          <Route path="/teams/onboarding/route-details">
            <RouteDetails />
          </Route>
          <Route path="/teams/onboarding/trial-details">
            <TrialDetails />
          </Route> */}


          <Route exact path="/import/inspections">
            <ImportDashboard />
          </Route>

        </AuthProvider>
      </Switch>
    </>
  );
}

export default App;
