import React, { useContext, useState, useRef, useEffect } from 'react'
import Axios from 'axios';
import './Pricing.css'
import Header from '../../partials/Header.js'
import Footer from '../../partials/Footer.js'
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../AuthContext'

import { Range, getTrackBackground } from 'react-range';


const SMALL_TEAM_PRICE = 75
const SMALL_TEAM_PRICE_YEARLY = 0
const GROWING_TEAM_PRICE = 125
const GROWING_TEAM_PRICE_YEARLY = 0
const LARGE_TEAM_PRICE = 175
const LARGE_TEAM_PRICE_YEARLY = 0

function PricingTeams() {

    const { currentUser, getTeamsSubscription } = useContext(AuthContext);

    const history = useHistory();

    const [driverCount, setDriverCount] = useState(10)
    const [tier, setTier] = useState('Growing')
    const [isYearly, toggle] = useState(false)
    
    const toggleClicked = () => {
        toggle(!isYearly)
    }

    // Slider constants (you can adjust as needed)
    const MIN_DRIVERS = 0;
    const MAX_DRIVERS = 20;

    if (driverCount <=7) {
        if (tier != 'Small') {
            setTier('Small')
        }
    } else if (driverCount <= 14) {
        if (tier != 'Growing') {
            setTier('Growing')
        }
    } else if (driverCount <= 20) {
        if (tier != 'Large') {
            setTier('Large')
        }
    }

    async function continueClicked() {
        if (currentUser) {

            // PUT CODE TO UPGRADE/DOWNGRADE
            // if (currentUser.email) {
            //     let userDbData = await getTeamProfile(email)
    
            //     // check to see if user has access to teams
            //     if (userDbData && userDbData.tier && userDbData.tier >= 1) {
                    
                    

            //     }
            // }

            // if already subbed to teams
            let teamsSub = await getTeamsSubscription(currentUser.email)
            if (teamsSub && teamsSub.tier && teamsSub.tier >= 1) {
                history.push('/teams/settings/subscription')
                return
            }

            history.push('/teams/onboarding')
        } else {
            history.push('/signup')
        }
    }

    return (
        <div>
            <Header></Header>
            <div className="buffer-100"></div>

            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 min-h-screen">
                <div className="pb-4 md:pb-7"></div>
                {/* Section header with slider */}
                <div className="flex flex-col md:flex-row justify-between items-center max-w-7xl mb-10">

                    {/* Left: Title */}
                    <div className="w-full md:w-2/5 text-center md:text-left mb-4 md:mb-0">
                        <p className="text-sm md:text-md font-medium mb-3 text-gray-600">TEAMS PRICING</p>
                        <h2 className="h2 text-myblue-900">Plans that scale with your team</h2>
                    </div>

                    {/* Right: Custom Styled Slider */}
                    <div className="w-full md:w-3/5 flex flex-col items-center md:items-end mt-3 mb-7 md:mb-0">

                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600 mb-2 text-center md:text-right">
                        How many drivers are on your team?
                        </p>

                        <Range
                        step={1}
                        min={MIN_DRIVERS}
                        max={MAX_DRIVERS}
                        values={[driverCount]}
                        onChange={(values) => {
                            const clamped = Math.max(values[0], 1);
                            setDriverCount(clamped);
                        }}
                        renderTrack={({ props, children }) => (
                            <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '8px',
                                width: '95%',
                                borderRadius: '0px',
                                background: getTrackBackground({
                                values: [driverCount],
                                colors: ['#548BF4', '#ccc'],
                                min: MIN_DRIVERS,
                                max: MAX_DRIVERS,
                                }),
                                margin: '1rem 0',
                            }}
                            >
                            {children}
                            </div>
                        )}
                        // Our custom thumb + tooltip
                        renderThumb={({ props }) => (
                            <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '24px',
                                width: '24px',
                                borderRadius: '50%',
                                backgroundColor: '#FFF',
                                border: '2px solid #548BF4',
                                boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                                position: 'relative',
                            }}
                            >
                            <div
                                style={{
                                position: 'absolute',
                                top: '30px',
                                left: '-6px',
                                background: '#548BF4',
                                color: '#FFF',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                fontSize: '0.85rem',
                                fontWeight: 'bold',
                                pointerEvents: 'none',
                                whiteSpace: 'nowrap',
                                width: '32px',
                                textAlign: 'center',
                                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)'
                                }}
                            >
                                {driverCount}
                            </div>
                            </div>
                        )}
                        renderMark={({ props, index }) => {
                            const markValue = MIN_DRIVERS + index;
                            return (
                            <div
                                {...props}
                                style={{
                                ...props.style,
                                width: '1px',
                                height: '14px',
                                backgroundColor: markValue <= driverCount ? '#548BF4' : '#ccc',
                                transform: 'translateX(1px)',
                                }}
                            >
                                {[0, 5, 10, 15, 20].includes(markValue) && (
                                <div
                                    style={{
                                    position: 'absolute',
                                    top: '25px',
                                    left: '-7px',
                                    fontSize: '0.75rem',
                                    color: '#555',
                                    }}
                                >
                                    {markValue}
                                </div>
                                )}
                            </div>
                            );
                        }}
                        />
                    </div>
                </div>

                
                {/* Items */}
                <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-4 lg:grid-cols-4 items-start md:max-w-2xl lg:max-w-none">

                    <div className="bg-white" data-aos="zoom-y-out" data-aos-delay="150">
                        <div className="mt-7">
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Create and optimize routes</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">File upload</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Balance your routes</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Route history</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Accurate ETA's</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Download itineraries</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Dispatch to driver mobile apps</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Synced with Google, Apple, Waze</p>
                            </div>
                        </div>
                    </div>

                    {/* 1st item */}
                    <div className="p-6 bg-white rounded-2xl shadow-xl border-gray-200 border-2 hover:shadow-2xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                        <h4 className="text-xl text-myblue-900 font-semibold">Small</h4>
                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600">The best choice for smaller teams streamlining logistics.</p>
                        <div className="dashed-line"></div>
                        <div className="flex">
                            <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                                { isYearly ? SMALL_TEAM_PRICE_YEARLY : SMALL_TEAM_PRICE }
                            </div>
                            <div className="md:text-1xl text-gray-600 font-medium mt-6">/ month</div>
                        </div>
                        {/* { isYearly ? 
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-gray-500">${173.99} Billed Annually |</p>
                                <p className="md:text-sm text-sm text-myteal-500 ml-1 font-semibold">You Save ${42}</p>
                            </div>
                            :
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-white">-</p>
                            </div>
                        } */}
                        <div className="flex justify-between mb-2 font-medium">
                            <p className="md:text-md text-md text-gray-500">
                                Included per month:
                            </p>
                            <p className="md:text-md font-bold text-md text-gray-500">
                                7 drivers
                            </p>
                        </div>
                        <div className="flex mb-2"></div>
                        {tier == 'Small' 
                        ? 
                            <div onClick={continueClicked} id="Plus" className="btn mt-3 mb-3 py-2 font-semibold rounded-lg bg-myteal-500 text-white border-myteal-500 border-2 hover:bg-myteal-600 hover:border-myteal-600 w-full cursor-pointer">
                                <p>Continue</p>
                            </div>
                        : 
                            <div onClick={continueClicked} id="Plus" className="btn mt-3 mb-3 py-2 font-semibold rounded-lg text-myteal-500 border-gray-200 border-2 hover:text-white hover:bg-myteal-500 hover:border-myteal-500 w-full cursor-pointer">
                                <p>Continue</p>
                            </div>
                        }
                    </div>

                    {/* 2nd item */}
                    <div className="p-6 bg-white rounded-2xl shadow-xl border-gray-200 border-2 hover:shadow-2xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                        <h4 className="text-xl text-myblue-900 font-semibold">Growing</h4>
                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600">More drivers, same powerful optimization.</p>
                        <div className="dashed-line"></div>
                        <div className="flex">
                            <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                                { isYearly ? GROWING_TEAM_PRICE_YEARLY : GROWING_TEAM_PRICE }
                            </div>
                            <div className="md:text-1xl text-gray-600 font-medium mt-6">/ month</div>
                        </div>
                        {/* { isYearly ? 
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-gray-500">${173.99} Billed Annually |</p>
                                <p className="md:text-sm text-sm text-myteal-500 ml-1 font-semibold">You Save ${42}</p>
                            </div>
                            :
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-white">-</p>
                            </div>
                        } */}
                        <div className="flex justify-between mb-2 font-medium">
                            <p className="md:text-md text-md text-gray-500">
                                Included per month:
                            </p>
                            <p className="md:text-md font-bold text-md text-gray-500">
                                14 drivers
                            </p>
                        </div>
                        <div className="flex mb-2"></div>
                        {tier == 'Growing' 
                        ? 
                            <div onClick={continueClicked} id="Plus" className="btn mt-3 mb-3 py-2 font-semibold rounded-lg bg-myteal-500 text-white border-myteal-500 border-2 hover:bg-myteal-600 hover:border-myteal-600 w-full cursor-pointer">
                                <p>Continue</p>
                            </div>
                        : 
                            <div onClick={continueClicked} id="Plus" className="btn mt-3 mb-3 py-2 font-semibold rounded-lg text-myteal-500 border-gray-200 border-2 hover:text-white hover:bg-myteal-500 hover:border-myteal-500 w-full cursor-pointer">
                                <p>Continue</p>
                            </div>
                        }
                    </div>

                    {/* 3rd item */}
                    <div className="p-6 bg-white rounded-2xl shadow-xl border-gray-200 border-2 hover:shadow-2xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                        <h4 className="text-xl text-myblue-900 font-semibold">Large</h4>
                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600">Built for high-volume teams managing complex logistics.</p>
                        <div className="dashed-line"></div>
                        <div className="flex">
                            <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                                { isYearly ? LARGE_TEAM_PRICE_YEARLY : LARGE_TEAM_PRICE }
                            </div>
                            <div className="md:text-1xl text-gray-600 font-medium mt-6">/ month</div>
                        </div>
                        {/* { isYearly ? 
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-gray-500">${863.99} Billed Annually |</p>
                                <p className="md:text-sm text-sm text-myteal-500 ml-1 font-semibold">You Save ${216}</p>
                            </div>
                            :
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-white">-</p>
                            </div>
                        } */}
                        <div className="flex justify-between mb-2 font-medium">
                            <p className="md:text-md text-md text-gray-500">
                                Included per month:
                            </p>
                            <p className="md:text-md font-bold text-md text-gray-500">
                                20 drivers
                            </p>
                        </div>
                        <div className="flex mb-2"></div>
                        {tier == 'Large' 
                        ? 
                            <div onClick={continueClicked} id="Plus" className="btn mt-3 mb-3 py-2 font-semibold rounded-lg bg-myteal-500 text-white border-myteal-500 border-2 hover:bg-myteal-600 hover:border-myteal-600 w-full cursor-pointer">
                                <p>Continue</p>
                            </div>
                        : 
                            <div onClick={continueClicked} id="Plus" className="btn mt-3 mb-3 py-2 font-semibold rounded-lg text-myteal-500 border-gray-200 border-2 hover:text-white hover:bg-myteal-500 hover:border-myteal-500 w-full cursor-pointer">
                                <p>Continue</p>
                            </div>
                        }
                    </div>

                </div>
                
                

            </div>
            
            <div className="buffer-100"></div>
            <Footer></Footer>
        </div>
    );
}

export default PricingTeams;