import React, { useEffect, useState } from 'react';
import './DriversModal.css'
import { useHistory } from 'react-router-dom';

// temporary, will need to make a call to db to load drivers from account
// let fullDriversList = [
//     {
//         id: 1,
//         name: 'Tom Vazhekatt',
//         email: null,
//         phoneNum: 9723586088,
//         colorCode: '#8377fd'
//     },
//     {
//         id: 2,
//         name: 'Luke Blazek',
//         email: 'lukeblazek24@gmail.com',
//         phoneNum: null,
//         colorCode: '#55caf5'
//     },
//     {
//         id: 3,
//         name: 'Akif Abidi',
//         email: null,
//         phoneNum: 4697636720,
//         colorCode: '#f55578'
//     },
//     {
//         id: 4,
//         name: 'Sujoy Purkayastha',
//         email: 'sujoyp@drivetuktuk.com',
//         phoneNum: null,
//         colorCode: '#ffaf00'
//     },
//     {
//         id: 5,
//         name: 'Brian George',
//         email: 'bgeorge3@nd.edu ',
//         phoneNum: null,
//         colorCode: '#01a240'
//     }
// ]

function DriversModal(props) {
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [fullDriversList, setFullDriversList] = useState([])

    const history = useHistory();

    useEffect(() => {
          let tempDriverList = []
          if (props.userData.drivers) {
              for (let i = 0; i < props.userData.drivers.length; i++) {
                tempDriverList.push(props.userData.drivers[i])
              }

              setFullDriversList(tempDriverList)
          }
    }, [props.userData]);

    useEffect(() => {

        // if the driver list is empty, set default to select all drivers. if not, select the drivers that were already selected to start
        if (props.selectedDriverList.length == 0) {
            setSelectedDrivers(fullDriversList);
        } else {
            setSelectedDrivers(props.selectedDriverList)
        }
    }, []);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedDrivers(fullDriversList);
        } else {
            setSelectedDrivers([]);
        }
    };

    const handleDriverSelection = (driver) => {
        setSelectedDrivers(prevSelected => 
            prevSelected.some(selectedDriver => selectedDriver.id === driver.id) 
            ? prevSelected.filter(selectedDriver => selectedDriver.id !== driver.id) 
            : [...prevSelected, driver]
        );
    };

    function addDrivers() {
        props.setSelectedDriverList(selectedDrivers)
        props.setOpenDriversModal(false)
    }

    function goDriversSettings() {
        history.push('/teams/settings/drivers')
    }

    return (
        <div className="driversModalBackground">
            <div className="driversModalContainer">
                <div className="driversCloseBtn z-20">
                    <button className="text-gray-500" onClick={() => { props.setOpenDriversModal(false) }} >
                        <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
                    </button>
                </div>

                <div className="text-lg p-3 h-12 border-b border-gray-300 sticky top-0 bg-white z-10">
                    Manage drivers
                </div>

                <div id="driver-select" className="px-4 h-full pt-5 overflow-y-auto">
                    <div className="mb-4">
                        <label className="flex items-center space-x-2">
                            <input 
                                type="checkbox" 
                                checked={selectedDrivers.length === fullDriversList.length}
                                onChange={handleSelectAll} 
                                className="form-checkbox"
                            />
                            <span className="text-gray-700">Select All</span>
                        </label>
                    </div>
                    {fullDriversList.map((driver, index) => (
                        <div key={driver.id} className="mb-2">
                            <label className="flex items-center space-x-3">
                                <input 
                                    type="checkbox" 
                                    checked={selectedDrivers.some(selectedDriver => selectedDriver.id === driver.id)}
                                    onChange={() => handleDriverSelection(driver)} 
                                    className="form-checkbox"
                                />
                                <div className="w-4 h-4 rounded-full" style={{ backgroundColor: driver.colorCode }}></div>
                                <div>
                                    <div className="text-sm font-medium text-gray-900">{driver.name}</div>
                                    <div className="text-xs text-gray-500">{driver.email || driver.phoneNum}</div>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="driversFooter sticky bottom-0 bg-white z-10 flex justify-between items-center p-3 border-t border-gray-300">
                    <button onClick={goDriversSettings} className="text-myteal-500 hover:text-myteal-600 underline">More drivers?</button>
                    <button onClick={addDrivers} className="bg-myteal-500 text-white font-semibold py-1 px-4 rounded hover:bg-myteal-600">Done</button>
                </div>
            </div>
        </div>
    );
}

export default DriversModal;
