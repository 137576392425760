import React, { useState, useEffect } from 'react';
import { rankInspectionRequests } from '../calculations/sortInspections';
import { getAvailableInspectorsForDate } from '../calculations/getInspectorAvailability';
import InspectorSelection from './components/InspectorSelection';
import InspectionSelection from './components/InspectionSelection';
import './SelectionDashboard.css';

function SelectionDashboard({ inspections, inspectors }) {
    const [sortedInspections, setSortedInspections] = useState([]);
    const [availableInspectors, setAvailableInspectors] = useState([]);
    const [selectedInspectors, setSelectedInspectors] = useState([]);
    const [selectedInspections, setSelectedInspections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [holidayInfo, setHolidayInfo] = useState(null);


    // console.log('selectedInspectors', selectedInspectors)
    // console.log('selectedInspections', selectedInspections)

    // Sort inspections and get available inspectors on component mount
    useEffect(() => {
        const processData = async () => {
            setIsLoading(true);
            setHolidayInfo(null);

            // Wrap the processing in a Promise to ensure loading state is shown
            await new Promise(resolve => {
                setTimeout(() => {
                    // Get today's date
                    // For testing, you can set a specific date
                    // const [year, month, day] = '2025-1-24'.split('-').map(Number);
                    // const today = new Date(year, month - 1, day, 12, 0, 0);
                    const today = new Date(); // Production date - would need similar timezone handling
                    
                    // Check if it's a holiday first
                    const holiday = inspectors.holidayListData?.find(h => {
                        const holDate = new Date(h.START_DATE);
                        return holDate.getFullYear() === today.getFullYear() &&
                               holDate.getMonth() === today.getMonth() &&
                               holDate.getDate() === today.getDate();
                    });

                    if (holiday) {
                        setHolidayInfo(holiday);
                        setAvailableInspectors([]);
                    } else {
                        // Get available inspectors for today
                        const available = getAvailableInspectorsForDate(
                            inspectors.inspectorsData || [],
                            inspectors.calendarData || [],
                            inspectors.holidayListData || [],
                            today
                        );

                        const availableWithDistricts = addDistrictCodesToInspectors(available, inspectors.districtData);

                        setAvailableInspectors(availableWithDistricts);
                    }

                    // Process inspections
                    if (inspections.inspectionsData) {
                        const ranked = rankInspectionRequests(inspections.inspectionsData, {
                            locationTieBreak: false
                        });

                        const rankedWithDuration = addImportedDurationToInspections(ranked, inspections.inspectionDurationData);

                        setSortedInspections(rankedWithDuration);
                    }

                    resolve();
                }, 0);
            });

            setIsLoading(false);
        };

        processData();
    }, [inspections.inspectionsData, inspectors.inspectorsData, inspectors.calendarData, inspectors.holidayListData]);


    // Helper function to add district codes to inspectors
    function addDistrictCodesToInspectors(inspectors, districtData) {
        if (!districtData) return inspectors;

        return inspectors.map(inspector => {
            // Find matching district data
            const inspectorDistrict = districtData.find(
                d => d.INSPECTOR_ID === inspector.INSPECTOR_ID
            );

            // If found, split the district string into an array, handling spaces
            const districtCodes = inspectorDistrict?.District
                ? inspectorDistrict.District.split('|')
                    .map(code => code.trim()) // Remove any spaces
                    .filter(code => code.length > 0) // Remove empty strings
                : [];

            // Return inspector with added districtCodes
            return {
                ...inspector,
                districtCodes
            };
        });
    }

    function addImportedDurationToInspections(inspections, durationData) {
        return inspections.map(inspection => {
            // Extract category code before the "-", accounting for spaces
            const categoryCode = inspection.ComplaintCategory.split('-')[0].trim();
            
            // Find matching duration data
            const matchedDuration = durationData?.find(
                d => d['Complaint Category'] === categoryCode
            );

            // Convert duration string to integer, fallback to existing DURATION if no match
            const duration = matchedDuration 
                ? parseInt(matchedDuration['Inspection Duration'], 10)
                : parseInt(inspection.DURATION, 10);

            // Add importedDuration as a number (not a string)
            return {
                ...inspection,
                importedDuration: isNaN(duration) ? 1800 : duration // default to 30 minutes (1800 seconds) if parsing fails
            };
        });
    }

    
    // Handle inspector selection
    const handleInspectorSelect = (inspectorId) => {
        if (holidayInfo) return; // Prevent selection on holidays
        setSelectedInspectors(prev => {
            if (prev.includes(inspectorId)) {
                return prev.filter(id => id !== inspectorId);
            }
            return [...prev, inspectorId];
        });
    };

    // Handle inspection selection
    const handleInspectionSelect = (inspectionId) => {
        if (holidayInfo) return; // Prevent selection on holidays
        setSelectedInspections(prev => {
            if (prev.includes(inspectionId)) {
                return prev.filter(id => id !== inspectionId);
            }
            return [...prev, inspectionId];
        });
    };

    function goRoutes() {
        console.log('Selected Inspectors:', selectedInspectors);
        console.log('Selected Inspections:', selectedInspections);
    }

    if (isLoading) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-white">
                <div className="text-center">
                    <div className="spinner-inspections">
                        <div className="spinner-outer-circle"></div>
                        <div className="spinner-inner-circle"></div>
                    </div>
                    <h3 className="mt-8 text-xl font-medium text-gray-900">Processing Data</h3>
                    <p className="mt-2 text-sm text-gray-500">
                        Please wait while we sort the inspection data and retrieve available inspectors...
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="fixed inset-0 pt-20 pb-10">
            <div className="h-full flex px-8">
                {/* Left panel - Inspector Selection */}
                <div className="w-1/3 h-full pr-4">
                    {holidayInfo ? (
                        <div>
                            <InspectorSelection
                                availableInspectors={availableInspectors}
                                selectedInspectors={selectedInspectors}
                                onInspectorSelect={handleInspectorSelect}
                            />
                            <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4 mt-4">
                                <div className="flex items-center">
                                    <svg className="h-5 w-5 text-red-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <h3 className="text-lg font-medium text-red-800">Holiday Notice</h3>
                                </div>
                                <p className="mt-2 text-sm text-red-700">
                                    {holidayInfo.HOLIDAY_NAME} - No inspectors are available on this holiday.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <InspectorSelection
                            availableInspectors={availableInspectors}
                            selectedInspectors={selectedInspectors}
                            onInspectorSelect={handleInspectorSelect}
                        />
                    )}
                </div>

                {/* Right panel - Inspection Selection */}
                <div className="w-2/3 h-full pl-4">
                    <InspectionSelection
                        sortedInspections={sortedInspections}
                        selectedInspections={selectedInspections}
                        onInspectionSelect={handleInspectionSelect}
                        goRoutes={goRoutes}
                    />
                </div>
            </div>
        </div>
    );
}

export default SelectionDashboard;