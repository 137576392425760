import React, { useContext } from 'react';

function TeamsGrid5() {
    return (
        <div className="group grid-elements grid3-parent flex flex-col justify-between text-lg items-end rounded-xl border transition duration-300 ease-in-out shadow-md hover:bg-gray-100 hover:shadow-lg">
            
            {/* Upload File Visual Component */}
            <div className="flex w-full pl-16 md:pl-32">

                <div className="w-full rounded-bl-xl py-20 md:py-24 px-7 md:px-16 border-l-2 border-b-2 border-myblue-300 bg-myblue-50 border-dashed bg-white text-center shadow-lg">
                    <div className="space-y-1 text-center pl-10">
                        <div className="flex justify-center items-center">
                            <div className="px-6 py-3 bg-blue-500 text-myblue-50 text-xs md:text-sm font-bold rounded-full focus:outline-none transition-colors">
                            Upload File
                            </div>
                        </div>
                        <p className="text-xs text-gray-500">
                            or drag 'n' drop file here; .xlsx, .xls, .csv accepted 
                        </p>
                    </div>
                </div>
                
            </div>

            <div className="text-container w-full p-5 md:p-10">
                <h1 className="text-3xl text-white group-hover:text-myblue-900 font-extrabold leading-tighter tracking-tighter mb-4">
                    Bulk address import
                </h1>
                <p className="text-myblue-100 group-hover:text-gray-500">
                    Upload an Excel or CSV file and generate fully optimized route itineraries in under two minutes—no manual entry required.
                </p>
            </div>
        </div>
    );
}

export default TeamsGrid5;






