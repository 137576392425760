import { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../AuthContext';
import Input from "../../onboarding/components/input";
import LocationEntry from "../../components/location_entry";
import './DepotTeam.css'

function DepotTeams(props) {
    const { currentUser, updateDepotProfile } = useContext(AuthContext);

    // State to store the message and whether it should be displayed
    const [statusMessage, setStatusMessage] = useState(null);

    const showMessage = (message, isSuccess) => {
        setStatusMessage({ text: message, success: isSuccess, showing: true });

        setTimeout(() => {
            setStatusMessage(prev => ({ ...prev, showing: false }));  // Trigger fade-out after 2.5 seconds
        }, 2500);

        setTimeout(() => {
            setStatusMessage(null);  // Remove the message completely after fade-out
        }, 3000);  // Total display time 3 seconds (fade-out happens in the last 0.5s)
    };

    const saveData = async (field, data) => {
        try {
            let depotName = props.teamsProfile.depotName;
            let startAddress = props.teamsProfile.startAddress;
            let startTime = props.teamsProfile.startTime;
            let endAddress = props.teamsProfile.endAddress;  // Keep the existing endAddress
            let averageStopTime = props.teamsProfile.averageStopTime;

            if (field === 'depotName' && data != depotName) {
                await updateDepotProfile(currentUser.email, data, startAddress, startTime, endAddress, averageStopTime);
                props.retrieveTeamProfile();
                showMessage('Depot name saved', true);
            } else if (field === 'startAddress' && data != startAddress) {
                await updateDepotProfile(currentUser.email, depotName, data, startTime, endAddress, averageStopTime);
                props.retrieveTeamProfile();
                showMessage('Start address saved', true);
            } else if (field === 'startTime' && data != startTime) {
                await updateDepotProfile(currentUser.email, depotName, startAddress, data, endAddress, averageStopTime);
                props.retrieveTeamProfile();
                showMessage('Start time saved', true);
            } else if (field === 'averageStopTime' && data != averageStopTime) {
                await updateDepotProfile(currentUser.email, depotName, startAddress, startTime, endAddress, data);
                props.retrieveTeamProfile();
                showMessage('Average stop time saved', true);
            }
        } catch (error) {
            console.error("Error updating depot profile:", error);
            showMessage('Error saving data', false);
        }
    };

    // Handle number inputs
    const handleNumberInput = (e) => {
        const value = e.target.value;
        const numValue = value !== "" ? Number(value) : 0;
        saveData("averageStopTime", numValue);
    };

    if (!props.teamsProfile) {
        return <div>Loading depot information...</div>;
    }

    return (
        <div className="relative px-36 pt-28">
            {/* Success/Failure message */}
            {statusMessage && (
                <div
                    className={`fixed bottom-5 left-1/2 transform -translate-x-1/2 z-50 py-2 px-4 rounded ${statusMessage.success ? 'bg-myteal-500' : 'bg-myred-600'} text-white text-xs shadow-lg 
                    ${statusMessage.showing ? 'fade-in-up' : 'fade-out-down'} transition-opacity duration-500 ease-in-out`}
                >
                    <div className="flex">
                        {statusMessage.text}
                        {statusMessage.success ? 
                            <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop:"2px", marginLeft:"5px"}} width="14" height="14" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
                        : 
                            <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop:"2px", marginLeft:"5px"}} width="15" height="15" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
                        }
                    </div>
                </div>
            )}

            <div className="title text-lg font-medium text-myblue-900 px-4">
                <h1>Manage your main depot</h1>
                <label className="block text-gray-600 text-xs font-medium mb-1">
                    Edit depot information including name, address, and average stop time at each location.
                </label>
            </div>

            <Input
                label={"Depot name"}
                placeholder={"Depot 1"}
                defaultValue={props.teamsProfile.depotName ?? ""}
                onBlur={(e) => saveData("depotName", e.target.value)}
            />

            <div className="mt-2">
                <div className="title text-sm font-medium text-myblue-900 px-4">
                    <h1>Starting your routes</h1>
                </div>

                <LocationEntry
                    label={"Start Location"}
                    placeholder={"Start Location"}
                    defaultAddress={props.teamsProfile.startAddress.name ?? ""}
                    setStartAddress={() => {}}
                    addressCoordinateList={[]}
                    setAddressCoordinateList={(e) => {
                        saveData("startAddress", {
                            name: e.start.name,
                            coordinates: e.start.coordinates,
                        });
                    }}
                />

                <Input
                    label={"Start Time"}
                    placeholder={"00:00"}
                    defaultValue={props.teamsProfile.startTime ?? ""}
                    type="time"
                    onBlur={(e) => saveData("startTime", e.target.value)}
                />
            </div>

            <div className="mt-5">
                <div className="title text-sm font-medium text-myblue-900 px-4">
                    <h1>Average time at stop</h1>
                    <label className="block text-gray-600 text-xs font-medium mb-1">
                        How long do drivers spend at each stop? This will help us calculate ETAs.
                    </label>
                </div>

                <div className="flex">
                    <Input
                        label={"Minutes"}
                        type={"number"}
                        placeholder={0}
                        defaultValue={props.teamsProfile.averageStopTime ?? 0}
                        onBlur={handleNumberInput}
                    />
                </div>
            </div>
        </div>
    );
}

export default DepotTeams;