// Validation constants
const VALID_EVENT_TYPES = ['Regular', 'Holiday', 'Vacation', 'Training', 'Other'];

// Required columns for calendar data
export const REQUIRED_COLUMNS = [
    'INSPECTOR_ID',
    'CALENDAR_ID',
    'CALENDAR_NAME',
    'EVENT_TYPE',
    'EVENT_NAME',
    'EVENT_ID',
    'START_TIME',
    'END_TIME'
];

// Helper functions
const isValidEventType = (type) => {
    if (!type) return false;
    return VALID_EVENT_TYPES.includes(String(type).trim());
};

const isValidDateTime = (dateTimeStr) => {
    if (!dateTimeStr) {
        return false;
    }
    
    // Original format: "M/D/YY HH:mm" or "M/D/YYYY HH:mm"
    const formatOne = /^(\d{1,2})\/(\d{1,2})\/(\d{2}|\d{4})\s*(\d{1,2}):(\d{1,2})$/;
    
    // New format: "DD-MMM-YY HH:MM AM/PM"
    const formatTwo = /^(\d{1,2})-([A-Z]{3})-(\d{2})\s*(\d{1,2}):(\d{2})\s*(AM|PM)$/i;

    // Month abbreviation to number mapping
    const monthMap = {
        'JAN': 1, 'FEB': 2, 'MAR': 3, 'APR': 4, 'MAY': 5, 'JUN': 6,
        'JUL': 7, 'AUG': 8, 'SEP': 9, 'OCT': 10, 'NOV': 11, 'DEC': 12
    };
    
    let match;
    let year, month, day, hours, minutes;
    
    if (formatOne.test(dateTimeStr)) {
        [, month, day, year, hours, minutes] = dateTimeStr.match(formatOne);
        // Convert 2-digit year to 4-digit
        if (year.length === 2) {
            year = parseInt(year);
            if (year < 20 || year > 99) return false;
            year = 2000 + year;
        }
        month = parseInt(month);
        hours = parseInt(hours);
    } else if (formatTwo.test(dateTimeStr)) {
        const [, d, m, y, h, min, ampm] = dateTimeStr.match(formatTwo);
        day = parseInt(d);
        month = monthMap[m.toUpperCase()];
        if (!month) return false;
        year = 2000 + parseInt(y);
        hours = parseInt(h);
        minutes = parseInt(min);
        
        // Convert 12-hour to 24-hour format
        if (ampm.toUpperCase() === 'PM' && hours < 12) hours += 12;
        if (ampm.toUpperCase() === 'AM' && hours === 12) hours = 0;
    } else {
        return false;
    }
    
    // Convert all remaining strings to numbers
    day = parseInt(day);
    minutes = parseInt(minutes);
    
    // Validate ranges
    if (month < 1 || month > 12) return false;
    if (day < 1 || day > 31) return false;
    if (year < 2020 || year > 2199) return false;
    if (hours < 0 || hours > 23) return false;
    if (minutes < 0 || minutes > 59) return false;
    
    // Validate date is real
    const date = new Date(year, month - 1, day, hours, minutes);
    return date instanceof Date && 
           !isNaN(date) && 
           date.getMonth() === month - 1 && 
           date.getDate() === day && 
           date.getFullYear() === year &&
           date.getHours() === hours &&
           date.getMinutes() === minutes;
};

const isValidCalendarId = (calendarId) => {
    if (!calendarId) return false;
    const num = parseInt(String(calendarId).trim());
    return !isNaN(num) && Number.isInteger(num);
};

const isValidCalendarName = (calendarName) => {
    if (!calendarName) return false;
    return /^[a-zA-Z]+_[Cc]alendar$/.test(String(calendarName).trim());
};

const isValidEventId = (eventId) => {
    if (!eventId) return false;
    const num = parseInt(String(eventId).trim());
    return !isNaN(num) && Number.isInteger(num);
};

// Column validation function
export const validateColumns = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        return {
            isValid: false,
            missingColumns: REQUIRED_COLUMNS,
            message: 'No data found in file'
        };
    }

    const fileColumns = Object.keys(data[0]);
    const missingColumns = REQUIRED_COLUMNS.filter(col => !fileColumns.includes(col));

    return {
        isValid: missingColumns.length === 0,
        missingColumns,
        message: missingColumns.length > 0
            ? `Missing required columns: ${missingColumns.join(', ')}`
            : 'All required columns present'
    };
};

// Main validation function
export const validateCalendarRow = (row) => {
    const errors = [];
    if (!row) {
        errors.push('Invalid row: empty or undefined');
        return errors;
    }

    // Check if all required columns exist in the data structure
    const missingFields = REQUIRED_COLUMNS.filter(field => !(field in row));

    if (missingFields.length > 0) {
        errors.push(`Missing required fields: ${missingFields.join(', ')}`);
        return errors;
    }

    // INSPECTOR_ID validation
    if (!row.INSPECTOR_ID || String(row.INSPECTOR_ID).trim() === '') {
        errors.push('INSPECTOR_ID cannot be empty');
    }

    // CALENDAR_ID validation
    if (!isValidCalendarId(row.CALENDAR_ID)) {
        errors.push('Invalid CALENDAR_ID: Must be a number');
    }

    // CALENDAR_NAME validation
    if (!isValidCalendarName(row.CALENDAR_NAME)) {
        errors.push('Invalid CALENDAR_NAME: Must be in format "name_Calendar" or "name_calendar"');
    }

    // EVENT_ID validation
    if (!isValidEventId(row.EVENT_ID)) {
        errors.push('Invalid EVENT_ID: Must be a number');
    }

    // START_TIME validation
    if (!isValidDateTime(row.START_TIME)) {
        errors.push('Invalid START_TIME: Must be in format "M/D/YY HH:mm" or "M/D/YYYY HH:mm"');
    }

    // END_TIME validation
    if (!isValidDateTime(row.END_TIME)) {
        errors.push('Invalid END_TIME: Must be in format "M/D/YY HH:mm" or "M/D/YYYY HH:mm"');
    }

    // Validate that END_TIME is after START_TIME
    if (isValidDateTime(row.START_TIME) && isValidDateTime(row.END_TIME)) {
        const startDate = new Date(row.START_TIME);
        const endDate = new Date(row.END_TIME);
        if (endDate <= startDate) {
            errors.push('END_TIME must be after START_TIME');
        }
    }

    return errors;
};

export const validateCalendarData = (data) => {
    if (!Array.isArray(data)) {
        throw new Error('Invalid data format: Expected an array of calendar records');
    }

    // First validate columns
    const columnValidation = validateColumns(data);
    if (!columnValidation.isValid) {
        throw new Error(columnValidation.message);
    }

    return data.map((row, index) => ({
        rowIndex: index + 2, // Add 2 to account for 0-based index and header row
        inspectorId: row?.INSPECTOR_ID || 'N/A',
        errors: validateCalendarRow(row),
        rawData: row
    })).filter(result => result.errors.length > 0);
}; 