import { createContext, useState, useEffect, useContext } from "react";

import { useParams, useHistory } from 'react-router-dom';

import ProfileTeams from "./Profile/ProfileTeams";
import DriverTeams from "./Drivers/DriversTeams"
import DepotTeams from "./Depot/DepotTeams";
import BillingTeams from "./Billing/BillingTeams"
import SubscriptionTeams from "./Subscription/SubscriptionTeams";

import { AuthContext } from "../../AuthContext"


function Settings(props) {

    const { currentUser, getTeamProfile } = useContext(AuthContext);

    const { sectionParam } = useParams();
    const history = useHistory();

    const [section, setSection] = useState('Profile')
    const [teamsProfile, setTeamsProfile] = useState()

    useEffect(() => {

        if (sectionParam) {
            if (sectionParam == 'profile') {
                setSection('Profile')
            } else if (sectionParam == 'drivers') {
                setSection('Drivers')
            } else if (sectionParam == 'depot') {
                setSection('Depot')
            } else if (sectionParam == 'billing') {
                setSection('Billing')
            } else if (sectionParam == 'subscription') {
                setSection('Subscription')
            } else {
                history.push('/teams/settings')
            }
        }
        
    }, [])

    useEffect(async () => {

        retrieveTeamProfile()
        
    }, [currentUser])

    async function retrieveTeamProfile() {
        if (currentUser) {
            let teamProfile = await getTeamProfile(currentUser.email)
            setTeamsProfile(teamProfile)
        }
    }

    // Function to handle section click
    const handleSectionClick = (selectedSection) => {
        setSection(selectedSection);
    };

    function goTeams() {
        history.push('/teams')
    }

    return (
        <div className="flex h-screen">
            <div style={{width: '550px'}} className="relative pt-12 pl-32 pr-8 flex flex-col h-screen bg-gray-100">
                <div onClick={goTeams} className="flex mb-10 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style={{marginTop: 2, marginRight: 5}} viewBox="0 0 24 24" id="arrow-left"><path fill="#000000" d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>
                    <p className="text-black">Back</p>
                </div>
                <h1 className="mb-7 text-2xl font-bold text-myblue-900">Settings</h1>
                <div onClick={() => handleSectionClick('Profile')} className={`flex p-2 rounded items-center cursor-pointer ${ (section == 'Profile') ? "text-myblue-900 bg-myblue-50" : "text-gray-500 bg-gray-100 hover:bg-gray-200"}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mr-4" viewBox="0 0 512 512"><path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>
                    <p>Profile</p>
                </div>
                <div onClick={() => handleSectionClick('Drivers')} className={`flex p-2 rounded items-center cursor-pointer ${ (section == 'Drivers') ? "text-myblue-900 bg-myblue-50" : "text-gray-500 bg-gray-100 hover:bg-gray-200"}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mr-4" viewBox="0 0 512 512"><path d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/></svg>
                    <p>Drivers</p>
                </div>
                <div onClick={() => handleSectionClick('Depot')} className={`flex p-2 rounded items-center cursor-pointer ${ (section == 'Depot') ? "text-myblue-900 bg-myblue-50" : "text-gray-500 bg-gray-100 hover:bg-gray-200"}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mr-4" viewBox="0 0 512 512"><path d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                    <p>Depot</p>
                </div>
                <div onClick={() => handleSectionClick('Billing')} className={`flex p-2 rounded items-center cursor-pointer ${ (section == 'Billing') ? "text-myblue-900 bg-myblue-50" : "text-gray-500 bg-gray-100 hover:bg-gray-200"}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mr-4" viewBox="0 0 512 512"><rect x="48" y="96" width="416" height="320" rx="56" ry="56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="60" d="M48 192h416M128 300h48v20h-48z"/></svg>
                    <p>Billing</p>
                </div>
                <div onClick={() => handleSectionClick('Subscription')} className={`flex p-2 rounded items-center cursor-pointer ${ (section == 'Subscription') ? "text-myblue-900 bg-myblue-50" : "text-gray-500 bg-gray-100 hover:bg-gray-200"}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mr-4" viewBox="0 0 24 24" id="browser"><path fill="currentColor" d="M19,2H9A3,3,0,0,0,6,5V6H5A3,3,0,0,0,2,9V19a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM16,19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12H16Zm0-9H4V9A1,1,0,0,1,5,8H15a1,1,0,0,1,1,1Zm4,5a1,1,0,0,1-1,1H18V9a3,3,0,0,0-.18-1H20Zm0-9H8V5A1,1,0,0,1,9,4H19a1,1,0,0,1,1,1Z"></path></svg>
                    <p>Subscription</p>
                </div>
            </div>

            <div className="w-full">
                {(section == 'Profile') &&
                    <ProfileTeams
                        setSection={setSection}
                        setTeamsProfile={setTeamsProfile}
                        teamsProfile={teamsProfile}
                    ></ProfileTeams>
                }
                {(section == 'Drivers') &&
                    <DriverTeams
                        teamsProfile={teamsProfile}
                        retrieveTeamProfile={retrieveTeamProfile}
                    ></DriverTeams>
                }
                {(section == 'Depot') &&
                    <DepotTeams
                        teamsProfile={teamsProfile}
                        retrieveTeamProfile={retrieveTeamProfile}
                    ></DepotTeams>
                }
                {(section == 'Billing') &&
                    <BillingTeams
                        teamsProfile={teamsProfile}
                    ></BillingTeams>
                }
                {(section == 'Subscription') &&
                    <SubscriptionTeams
                        teamsProfile={teamsProfile}
                    ></SubscriptionTeams>
                }
            </div>
        </div>
    );
}

export default Settings;