import React, { useState } from "react";
import Input from "../components/input";
import LocationEntry from "../../components/location_entry";

const DepotDetails = (props) => {
  const getValue = (key) => {
    if (props.form != null) {
      return props.form[key];
    }

    return null;
  };

  const getAddressName = (key) => {
    const value = getValue(key);

    if (value != null) {
      return value.name;
    }

    return null;
  };

  // Function to handle number inputs and convert to a number
  const handleNumberInput = (e) => {
    const value = e.target.value;

    // Make sure we convert to a number if it's a valid number, otherwise use 0
    const numValue = value !== "" ? Number(value) : 0;

    // Save the number value
    props.saveData("averageStopTime", numValue);
  };

  return (
    <div class="px-4">
      <div className="title text-lg font-medium text-black-500 px-4">
        <h1>Set up your main depot</h1>
        <label className="block text-gray-600 text-xs font-medium mb-1">
          Enter the location where all drivers begin their routes. You can also edit all this
          information later in settings.
        </label>
      </div>
      <Input
        label={"Depot name"}
        placeholder={"Depot 1"}
        defaultValue={getValue("depotName")}
        onBlur={(e) => {
          props.saveData("depotName", e.target.value);
        }}
      />

      <div className="mt-2">
        <div className="title text-sm font-medium text-black-500 px-4">
          <h1>Starting your routes</h1>
        </div>
        <LocationEntry
          label={"Start Location"}
          placeholder={"Start Location"}
          defaultAddress={getAddressName("startAddress") ?? ""}
          setStartAddress={() => {}}
          addressCoordinateList={[]}
          setAddressCoordinateList={(e) => {
            props.saveData("startAddress", {
              name: e.start.name,
              coordinates: e.start.coordinates,
            });
          }}
        />
        <Input
          label={"Start Time"}
          placeholder={"08:00"}
          defaultValue={getValue("startTime") || "08:00"}  // Default to 8:00 AM if no value is provided
          type="time"  // This will restrict the input to valid time values
          onBlur={(e) => {
            props.saveData("startTime", e.target.value);
          }}
        />

      </div>

      {/* <div className="mt-5">
        <div className="title text-sm font-medium text-black-500 px-4">
          <h1>Finishing your routes</h1>
        </div>
        <LocationEntry
          label={"End Location"}
          placeholder={"End Location"}
          defaultAddress={getAddressName("endAddress") ?? ""}
          setStartAddress={() => {}}
          addressCoordinateList={[]}
          setAddressCoordinateList={(e) => {
            props.saveData("endAddress", {
              name: e.end.name,
              coordinates: e.end.coordinates,
            });
          }}
        />
      </div> */}

      <div className="mt-5">
        <div className="title text-sm font-medium text-black-500 px-4">
          <h1>Average time at stop</h1>
          <label className="block text-gray-600 text-xs font-medium mb-1">
            How long do drivers spend at each stop? This will help us
            calculate ETA's.
          </label>
        </div>
        <div className="flex">
          <Input
            label={"Minutes"}
            type={"number"}
            placeholder={15}
            defaultValue={getValue("averageStopTime")}
            onBlur={handleNumberInput}
          />
        </div>
      </div>
    </div>
  );
};

export default DepotDetails;