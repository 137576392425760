import React from 'react';

function TeamsGrid6() {
    return (
        <div className="group grid-elements grid4-parent flex flex-col justify-between text-lg items-end rounded-xl border transition duration-300 ease-in-out shadow-md hover:bg-gray-100 hover:shadow-lg overflow-hidden">
            
            <div className="text-container w-full p-5 md:p-10 md:order-1">
                <h1 className="text-3xl text-white group-hover:text-myblue-900 font-extrabold leading-tighter tracking-tighter mb-4">Driver app for easy navigation</h1>
                <p className="text-myblue-100 group-hover:text-gray-500">
                    Keep track of your route in real-time. Fully integrated with Google Maps, Apple Maps, and Waze for a smooth navigation experience.
                </p>
            </div>

            {/* Image container */}
            <div className="flex justify-center items-center w-full md:order-2 px-3 md:px-5">
                <img 
                    src={require('./TeamsGridImages/mobile-teamsgrid1.1.png').default} 
                    alt="Routora Maps Dashboard" 
                    width="190" 
                    className="h-auto"
                />
            </div>


        </div>
    );
}

export default TeamsGrid6;
