import React, { useState } from "react";

function TeamsGrid3() {

    const COLORS = [
        '#8377fd', '#55caf5', '#f55578', '#ffaf00', '#01a240', '#4426d9'
    ];

    // State to manage checkbox selections
    const [selected, setSelected] = useState([true, true, true, true, false, false]);

    // Handle checkbox change
    const toggleCheckbox = (index) => {
        setSelected((prevSelected) => {
            const newSelected = [...prevSelected];
            newSelected[index] = !newSelected[index];
            return newSelected;
        });
    };

    const names = ["Tom Vazhekatt", "Luke Blazek", "Brian George", "Shoaib Huq", "Aryaman Dubey", "Connor Laveau"];

    return (
        <div className="group grid-elements grid3-parent flex flex-col justify-between text-lg items-end rounded-xl border transition duration-300 ease-in-out shadow-md hover:bg-gray-100 hover:shadow-lg">
            
            <div className="flex w-full pl-16 md:pl-24">
                <div className="route-chart-new shadow-md hover:shadow-lg flex w-full bg-white rounded-bl-xl rounded-tr-xl border-l border-b border-myblue-300 text-gray-500">
                    
                    {/* Column for checks */}
                    <div className="flex flex-col w-1/6 border-r border-myblue-300 bg-myblue-100 rounded-bl-xl">
                        {selected.map((isChecked, index) => (
                            <div
                                key={index}
                                className={`h-12 flex items-center justify-center ${
                                    index !== selected.length - 1 ? "border-b border-myblue-300" : ""
                                }`}
                            >
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => toggleCheckbox(index)}
                                    className="form-checkbox h-5 w-5 text-myblue-600"
                                />
                            </div>
                        ))}
                    </div>

                    {/* Column for names with colored circles */}
                    <div className="flex flex-col bg-myblue-50 w-5/6">
                        {names.map((name, index) => (
                            <div key={index} className={`h-12 flex items-center border-myblue-300 ${index !== names.length - 1 ? "border-b" : ""}`}>
                                {/* Color Circle */}
                                <div
                                    className="w-3 h-3 rounded-full ml-5 mr-3"
                                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                                ></div>
                                {/* Name */}
                                <p className="overflow-hidden whitespace-nowrap">{name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="text-container w-full p-5 md:p-10">
                <h1 className="text-3xl text-white group-hover:text-myblue-900 font-extrabold leading-tighter tracking-tighter mb-4">
                    Assign and manage drivers
                </h1>
                <p className="text-myblue-100 group-hover:text-gray-500">
                    Select your drivers for the day’s routes.
                </p>
            </div>
        </div>
    );
}

export default TeamsGrid3;
