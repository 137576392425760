import React, { useState, useCallback } from 'react';
import * as XLSX from 'xlsx';
import RelatedInspectionsPopup from './RelatedInspectionsPopup';

function InspectionSelection({ sortedInspections, selectedInspections, onInspectionSelect, goRoutes }) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [relatedInspections, setRelatedInspections] = useState([]);

    const getPriorityColor = (priority) => {
        switch (priority?.toUpperCase()) {
            case 'A':
                return 'bg-red-50 text-red-700';
            case 'B+':
                return 'bg-purple-50 text-purple-700';
            case 'B':
                return 'bg-blue-50 text-blue-700';
            case 'C':
                return 'bg-yellow-50 text-yellow-700';
            case 'D':
                return 'bg-green-50 text-green-700';
            default:
                return 'bg-gray-50 text-gray-700';
        }
    };

    const findRelatedInspections = useCallback((inspection) => {
        // Find all inspections with the same address
        return sortedInspections.filter(insp => 
            insp.INSPECTION_ID !== inspection.INSPECTION_ID && // Exclude the current inspection
            insp.Street === inspection.Street &&
            insp.City === inspection.City &&
            !selectedInspections.includes(insp.INSPECTION_ID) // Exclude already selected inspections
        );
    }, [sortedInspections, selectedInspections]);

    const handleInspectionSelect = (inspectionId) => {
        // If deselecting, just call the parent handler
        if (selectedInspections.includes(inspectionId)) {
            onInspectionSelect(inspectionId);
            return;
        }

        // Find the selected inspection
        const selectedInspection = sortedInspections.find(insp => insp.INSPECTION_ID === inspectionId);
        if (!selectedInspection) return;

        // Find related inspections
        const related = findRelatedInspections(selectedInspection);

        // If there are related inspections, show the popup
        if (related.length > 0) {
            setRelatedInspections(related);
            setIsPopupOpen(true);
        }

        // Always select the clicked inspection
        onInspectionSelect(inspectionId);
    };

    const handleDownload = () => {
        // Columns to include in the export
        const columnsToExport = [
            'INSPECTION_ID', 'Record_ID', 'Record_Open_Date', 'Priority',
            'TaskType', 'UNIT', 'DURATION', 'Status', 'District', 'Street',
            'City', 'State', 'Postcode', 'Latitude', 'Longitude',
            'AppointmentStart', 'AppointmentFinish', 'RequiredEngineers',
            'ComplaintCategory'
        ];

        // Create a new array with only the specified columns
        const exportData = sortedInspections.map(inspection => {
            const row = {};
            columnsToExport.forEach(col => {
                row[col] = inspection[col];
            });
            return row;
        });

        // Create a new workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(exportData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sorted Inspections');

        // Generate the Excel file and trigger download
        XLSX.writeFile(wb, 'sorted_inspections.xlsx');
    };

    return (
        <div className="h-full flex flex-col">
            {/* Fixed Header */}
            <div className="py-4 border-gray-200">
                <div className="flex justify-between items-start">
                    <div className="flex-1">
                        <h3 className="text-lg font-medium text-myblue-900">
                            Sorted Inspections
                        </h3>
                        <div className="flex items-center">
                            <p className="text-sm text-gray-500">
                                Select inspections to be assigned
                            </p>
                            <span className="inline-flex ml-2 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800" style={{marginTop: "3px"}}>
                                {selectedInspections.length} selected
                            </span>
                        </div>
                    </div>
                    <div className="flex space-x-3">
                        <button
                            onClick={handleDownload}
                            className="ml-4 mt-3 inline-flex items-center px-4 py-2.5 bg-gray-700 text-gray-200 text-sm font-medium rounded-lg 
                            shadow-sm hover:bg-gray-500 active:bg-gray-300 
                            focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2
                            transition-colors duration-150 ease-in-out"
                        >
                            <svg 
                                className="w-4 h-4" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth="2" 
                                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={goRoutes}
                            className="ml-4 mt-3 inline-flex items-center px-4 py-2.5 bg-myteal-500 text-white text-sm font-medium rounded-lg 
                            shadow-sm hover:bg-myteal-600 active:bg-myteal-700 
                            focus:outline-none focus:ring-2 focus:ring-myteal-500 focus:ring-offset-2
                            transition-colors duration-150 ease-in-out
                            disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            <svg 
                                className="w-4 h-4 mr-2" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth="2" 
                                    d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                                />
                            </svg>
                            Generate Routes
                        </button>
                    </div>
                </div>
            </div>

            {/* Table Container with fixed header and scrollable body */}
            <div className="flex-1 overflow-hidden rounded-lg shadow">
                <div className="h-full relative">
                    <div className="absolute inset-0 overflow-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        #
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        Select
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        Days Active
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        SLA Margin
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        ID
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        Priority
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        Type
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        District
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        Duration
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        Unit
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        Category
                                    </th>
                                    <th scope="col" className="sticky top-0 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        Location
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {sortedInspections.map((inspection, index) => (
                                    <tr key={inspection.INSPECTION_ID}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                            {index + 1}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={selectedInspections.includes(inspection.INSPECTION_ID)}
                                                onChange={() => handleInspectionSelect(inspection.INSPECTION_ID)}
                                            />
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {inspection.daysSinceOpen}
                                        </td>
                                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${
                                            inspection.daysLeft < 0 ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'
                                        }`}>
                                            {inspection.daysLeft}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {inspection.INSPECTION_ID}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getPriorityColor(inspection.priority)}`}>
                                                {inspection.priority}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {inspection.TaskType}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {inspection.District}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {Math.round(inspection.importedDuration / 60)} min
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {inspection.UNIT}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {inspection.ComplaintCategory}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {inspection.Street}, {inspection.City}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Related Inspections Popup */}
            <RelatedInspectionsPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                relatedInspections={relatedInspections}
                selectedInspections={selectedInspections}
                onInspectionSelect={onInspectionSelect}
                getPriorityColor={getPriorityColor}
                sortedInspections={sortedInspections}
            />
        </div>
    );
}

export default InspectionSelection; 