/***************************************************************
 * sortInspections.js
 *
 * Ranks NYC DOB inspection requests by urgency and returns
 * a sorted array (high priority first).
 ***************************************************************/

/**
 * SLA lookup for priority + taskType (Inspection vs. Re-Inspection).
 *
 * Priority + TaskType => SLA days from the Record Open Date
 */
const SLA_LOOKUP = {
    'B': {
      inspection: 40,
      reinspection: 150,
    },
    'B+': {
      inspection: 40,
      reinspection: 150,
    },
    'C': {
      inspection: 60,
      reinspection: 175,
    },
    'D': {
      inspection: 90,
      reinspection: 200,
    },
  };
  
  /**
   * Helper: compute integer day difference between two dates.
   * If dateA >= dateB, the result is how many whole days dateA is after dateB.
   */
  function daysBetween(dateA, dateB) {
    const msPerDay = 24 * 60 * 60 * 1000;
    // e.g.  If dateA is today, dateB is the record open date,
    //       we get how many days have passed since open date.
    return Math.floor((dateA - dateB) / msPerDay);
  }
  
  /**
   * Returns the SLA (days) for a given priority & taskType
   */
  function getSlaDays(priority, taskType) {
    const p = (priority || '').toUpperCase();    // e.g. 'B', 'B+', 'C', 'D'
    const t = (taskType || '').toLowerCase();    // 'inspection' or 'reinspection'
  
    if (SLA_LOOKUP[p] && SLA_LOOKUP[p][t] != null) {
      return SLA_LOOKUP[p][t];
    }
    return 0; // fallback
  }
  
  /**
   * rankInspectionRequests
   *
   * - Filters out rows with Status === "Scheduled".
   * - Determines daysLeft = SLA - daysSinceOpen
   * - Sorts ascending by daysLeft (lowest => highest priority)
   * - Optionally tie-breaks by location.
   * - Logs a summary array (priority, taskType, daysPassed) in sorted order.
   *
   * @param {Array} data - The array of objects read from XLSX, each like:
   *   {
   *     "INSPECTION_ID": "5826025",
   *     "Record_Open_Date": "2023-08-18 00:00:00",
   *     "Priority": "B",
   *     "TaskType": "Complaint Re-Inspection - QOL",
   *     "Status": "Scheduled",
   *     "Latitude": "40.643533",
   *     "Longitude": "-74.084529",
   *     ...
   *   }
   * @param {Object} options
   *   - locationTieBreak?: boolean
   *   - referenceLat?: number
   *   - referenceLng?: number
   *
   * @return {Array} sorted from high urgency (fewest daysLeft) to lower
   */
  function rankInspectionRequests(data, options = {}) {
    const {
      locationTieBreak = false,
      referenceLat = 0,
      referenceLng = 0,
    } = options;
  
    const today = new Date();
  
    // 1) Transform and filter
    const transformed = data
      .filter((row) => {
        // If row.Status === "Scheduled", skip it
        return row.Status !== 'Scheduled';
      })
      .map((row) => {
        // parse open date
        const openDate = new Date(row.Record_Open_Date);
  
        // how many days since the record was opened?
        const daysSinceOpen = daysBetween(today, openDate);
  
        // parse priority
        const priority = String(row.Priority || '').toUpperCase();
  
        // parse task type: check if "TaskType" string has "re-inspection"
        let taskType = 'inspection';
        const taskTypeStr = (row.TaskType || '').toLowerCase();
        if (taskTypeStr.includes('re-inspection')) {
          taskType = 'reinspection';
        }
  
        // get SLA
        const slaDays = getSlaDays(priority, taskType);
  
        // daysLeft = slaDays - daysSinceOpen
        const daysLeft = slaDays - daysSinceOpen;
  
        // parse lat/lng
        const lat = parseFloat(row.Latitude);
        const lng = parseFloat(row.Longitude);
  
        // location-based tie-break
        let tieBreakValue = 0;
        if (locationTieBreak && !Number.isNaN(lat) && !Number.isNaN(lng)) {
          // distance from reference
          const dx = lng - referenceLng;
          const dy = lat - referenceLat;
          tieBreakValue = Math.sqrt(dx * dx + dy * dy);
        }
  
        return {
          ...row,
          openDate,
          daysSinceOpen, // how many days have elapsed since open
          slaDays,
          daysLeft,
          lat,
          lng,
          taskType,  // 'inspection' or 'reinspection'
          priority,  // normalized e.g. 'B'
          tieBreakValue
        };
      });
  
    // 2) Sort by daysLeft ascending => smaller => more urgent
    transformed.sort((a, b) => {
      if (a.daysLeft !== b.daysLeft) {
        return a.daysLeft - b.daysLeft; // ascending
      }
      // optional tie-break by location distance
      return a.tieBreakValue - b.tieBreakValue;
    });
  
    // 3) Build a summary array and log it
    const summary = transformed.map((item) => ({
      priority: item.priority,
      taskType: item.taskType,
      daysPassed: item.daysSinceOpen,
    }));
  
    return transformed;
  }
  
  // Export the function
  export {
    rankInspectionRequests,
  };
  