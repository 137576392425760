// Validation constants
const NYC_BOROUGHS = ['Manhattan', 'Bronx', 'Brooklyn', 'Queens', 'Staten Island'];
const VALID_DEPARTMENTS = ['Enforcement Construction', 'Enforcement Plumbing'];
const VALID_MOBILITY = ['Car', 'Walking'];
const VALID_SKILLS = ['Construction Enforcement', 'QOL Enforcement', 'Plumbing', 'Plumbing Enforcement'];

// Required columns for inspector data
export const REQUIRED_COLUMNS = [
    'INSPECTOR_ID',
    'INSPECTOR_DEPARTMENT',
    'Districts',
    'Postcode',
    'Calendar_ID',
    'Calendar_Name',
    'Skills',
    'Street',
    'City',
    'State',
    'Mobility'
];

// Helper functions
const isValidNYCZipCode = (zipCode) => {
    if (!zipCode) return false;
    const zipStr = String(zipCode).trim();
    return /^\d{5}$/.test(zipStr) && 
           parseInt(zipStr) >= 10001 && 
           parseInt(zipStr) <= 11697; // NYC ZIP code range
};

const isValidDepartment = (department) => {
    if (!department) return false;
    return VALID_DEPARTMENTS.some(valid => department.includes(valid));
};

const isValidMobility = (mobility) => {
    if (!mobility) return false;
    return VALID_MOBILITY.includes(String(mobility).trim());
};

const isValidSkills = (skills) => {
    if (!skills || String(skills).trim() === '') return false;
    return true;
};

const isValidDistricts = (districts) => {
    if (!districts) return false;
    const districtArray = districts.split(',').map(district => district.trim());
    return districtArray.every(district => NYC_BOROUGHS.includes(district));
};

const isValidCalendarId = (calendarId) => {
    if (!calendarId) return false;
    const num = parseInt(String(calendarId).trim());
    return !isNaN(num) && Number.isInteger(num);
};

const isValidCalendarName = (calendarName) => {
    if (!calendarName) return false;
    return /^[a-zA-Z]+_[Cc]alendar$/.test(String(calendarName).trim());
};

// Column validation function
export const validateColumns = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        return {
            isValid: false,
            missingColumns: REQUIRED_COLUMNS,
            message: 'No data found in file'
        };
    }

    const fileColumns = Object.keys(data[0]);
    const missingColumns = REQUIRED_COLUMNS.filter(col => !fileColumns.includes(col));

    return {
        isValid: missingColumns.length === 0,
        missingColumns,
        message: missingColumns.length > 0
            ? `Missing required columns: ${missingColumns.join(', ')}`
            : 'All required columns present'
    };
};

// Main validation function
export const validateInspectorRow = (row) => {
    const errors = [];
    if (!row) {
        errors.push('Invalid row: empty or undefined');
        return errors;
    }

    // Check if all required columns exist in the data structure
    const missingFields = REQUIRED_COLUMNS.filter(field => !(field in row));

    if (missingFields.length > 0) {
        errors.push(`Missing required fields: ${missingFields.join(', ')}`);
        return errors;
    }

    // INSPECTOR_ID validation
    if (!row.INSPECTOR_ID || String(row.INSPECTOR_ID).trim() === '') {
        errors.push('INSPECTOR_ID cannot be empty');
    }

    // INSPECTOR_DEPARTMENT validation
    if (!isValidDepartment(row.INSPECTOR_DEPARTMENT)) {
        errors.push(`Invalid INSPECTOR_DEPARTMENT: Must be one of ${VALID_DEPARTMENTS.join(', ')}`);
    }

    // Districts validation
    if (!isValidDistricts(row.Districts)) {
        errors.push(`Invalid Districts: Must be one or more of ${NYC_BOROUGHS.join(', ')}`);
    }

    // Postcode validation
    if (!isValidNYCZipCode(row.Postcode)) {
        errors.push('Invalid Postcode: Must be a valid NYC ZIP code');
    }

    // Calendar_ID validation
    if (!isValidCalendarId(row.Calendar_ID)) {
        errors.push('Invalid Calendar_ID: Must be a number');
    }

    // Calendar_Name validation
    if (!isValidCalendarName(row.Calendar_Name)) {
        errors.push('Invalid Calendar_Name: Must be in format "name_Calendar" or "name_calendar"');
    }

    // Skills validation
    if (!isValidSkills(row.Skills)) {
        errors.push('Skills cannot be empty');
    }

    // Street validation
    if (!row.Street || String(row.Street).trim() === '') {
        errors.push('Street cannot be empty');
    }

    // City validation
    if (!row.City || String(row.City).trim() !== 'NYC') {
        errors.push('City must be NYC');
    }

    // State validation
    if (!row.State || String(row.State).trim() !== 'NY') {
        errors.push('State must be NY');
    }

    // Mobility validation
    if (!isValidMobility(row.Mobility)) {
        errors.push(`Invalid Mobility: Must be one of ${VALID_MOBILITY.join(', ')}`);
    }

    return errors;
};

export const validateInspectorData = (data) => {
    if (!Array.isArray(data)) {
        throw new Error('Invalid data format: Expected an array of inspector records');
    }

    // First validate columns
    const columnValidation = validateColumns(data);
    if (!columnValidation.isValid) {
        throw new Error(columnValidation.message);
    }

    return data.map((row, index) => ({
        rowIndex: index + 2, // Add 2 to account for 0-based index and header row
        inspectorId: row?.INSPECTOR_ID || 'N/A',
        errors: validateInspectorRow(row),
        rawData: row
    })).filter(result => result.errors.length > 0);
}; 