import { createContext, useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { useConfirmModal } from "../../../partials/ConfirmModal";
import { AuthContext } from '../../../AuthContext'
import Axios from 'axios';

const SMALL_TEAM_PRICE = 75
const SMALL_TEAM_PRICE_YEARLY = 0
const GROWING_TEAM_PRICE = 125
const GROWING_TEAM_PRICE_YEARLY = 0
const LARGE_TEAM_PRICE = 175
const LARGE_TEAM_PRICE_YEARLY = 0

function SubscriptionTeams(props) {

    const { currentUser, getTeamsSubscription, updateTeamsSub } = useContext(AuthContext);

    const history = useHistory();

    const [isYearly, toggle] = useState(false)

    const { confirm, ConfirmModal } = useConfirmModal();
    
    const toggleClicked = () => {
        toggle(!isYearly)
    }

    async function changeSub(newTier) {
        if (!currentUser || !props.teamsProfile) {
            console.error("User not authenticated or subscription info missing. Contact info@routora.com");
            return;
        }

        // const confirmChange = window.confirm(`You are about to switch to the ${newTier} plan. Your billing will be adjusted accordingly.`);
        // if (!confirmChange) return;
        const userConfirmed = await confirm(`You are about to switch to the ${newTier} plan. Your billing will be adjusted accordingly.`);
        if (!userConfirmed) return; // Exit if they cancel
        console.log(userConfirmed)
    
        try {
            // Step 1: Get user's current subscription ID
            const response = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/get-customer-id`, {
                subscription_id: props.teamsProfile.subscription_id
            });
    
            const customerId = response.data.customerId;
            console.log('CUSTOMER ID', customerId)
            if (!customerId) throw new Error("Failed to retrieve customer ID");
    
            // Step 2: Call backend to update subscription
            const updateResponse = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/update-subscription`, {
                customerId,
                subscriptionId: props.teamsProfile.subscription_id,
                newTier
            });
    
            if (updateResponse.data.success) {
                alert("Subscription updated successfully!");
                
                // write code to update sub in db
                let tierNum = 0
                if (newTier == 'Small') {
                    tierNum = 1
                } else if (newTier == 'Growing') {
                    tierNum = 2
                } else if (newTier == 'Large') {
                    tierNum = 3
                }

                let successfulDbUpdate = await updateTeamsSub(currentUser.email, props.teamsProfile.subscription_id, tierNum)

                if (successfulDbUpdate) {
                    history.push('/teams/settings/subscription') // reload current page
                }
                
            } else {
                alert("Failed to update subscription.");
            }
        } catch (error) {
            console.error("Error updating subscription:", error);
        }
    }
    

    if (!props.teamsProfile) {
        return <div>Loading subscription information...</div>;
    }

    return (
        <div className="pt-24 px-10">
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-4 md:pb-7"></div>
                {/* Section header with slider */}
                <div className="flex flex-col md:flex-row justify-between items-center max-w-6xl mb-10">

                    {/* Left: Title */}
                    <div className="w-full text-center md:text-left mb-4 md:mb-0">
                        <p className="text-sm md:text-md font-medium mb-3 text-gray-600">TEAMS PRICING</p>
                        <h2 className="h2 text-myblue-900">Manage Plan</h2>
                    </div>
                
                </div>

                {/* Items */}
                <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-3 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

                    {/* 1st item */}
                    <div style={{ height: 550 }} className="p-7 bg-white rounded-2xl shadow-xl border-gray-200 border-2 hover:shadow-2xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                        <h4 className="text-xl text-myblue-900 font-semibold">Small</h4>
                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600">The best choice for smaller teams streamlining logistics.</p>
                        <div className="dashed-line"></div>
                        <div className="flex">
                            <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                                { isYearly ? SMALL_TEAM_PRICE_YEARLY : SMALL_TEAM_PRICE }
                            </div>
                            <div className="md:text-1xl text-gray-600 font-medium mt-6">/ month</div>
                        </div>
                        {/* { isYearly ? 
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-gray-500">${173.99} Billed Annually |</p>
                                <p className="md:text-sm text-sm text-myteal-500 ml-1 font-semibold">You Save ${42}</p>
                            </div>
                            :
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-white">-</p>
                            </div>
                        } */}
                        <div className="flex justify-between mb-2 font-medium">
                            <p className="md:text-md text-md text-gray-500">
                                Included per month:
                            </p>
                            <p className="md:text-md font-bold text-md text-gray-500">
                                7 drivers
                            </p>
                        </div>
                        <div className="flex mb-2"></div>
                        {props.teamsProfile.tier == 1 
                        ? 
                            <div id="Plus" className="btn mt-3 py-2 font-semibold rounded-lg bg-gray-400 text-white border-gray-400 border-2 w-full">
                                <p>Your Current Plan</p>
                            </div>
                        : 
                            <div onClick={() => changeSub('Small')} id="Plus" className="btn mt-3 py-2 font-semibold rounded-lg text-myteal-500 border-gray-200 border-2 hover:text-white hover:bg-myteal-500 hover:border-myteal-500 w-full cursor-pointer">
                                <p>Continue</p>
                            </div>
                        }
                        
                        <div className="mt-7">
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Create and optimize routes</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Regionalized routing</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Balanced route distribution</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Unlimited route history</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Dispatch to driver mobile apps</p>
                            </div>
                        </div>
                    </div>

                    {/* 2nd item */}
                    <div style={{ height: 550 }} className="p-7 bg-white rounded-2xl shadow-xl border-gray-200 border-2 hover:shadow-2xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                        <h4 className="text-xl text-myblue-900 font-semibold">Growing</h4>
                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600">More drivers, same powerful optimization.</p>
                        <div className="dashed-line"></div>
                        <div className="flex">
                            <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                                { isYearly ? GROWING_TEAM_PRICE_YEARLY : GROWING_TEAM_PRICE }
                            </div>
                            <div className="md:text-1xl text-gray-600 font-medium mt-6">/ month</div>
                        </div>
                        {/* { isYearly ? 
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-gray-500">${173.99} Billed Annually |</p>
                                <p className="md:text-sm text-sm text-myteal-500 ml-1 font-semibold">You Save ${42}</p>
                            </div>
                            :
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-white">-</p>
                            </div>
                        } */}
                        <div className="flex justify-between mb-2 font-medium">
                            <p className="md:text-md text-md text-gray-500">
                                Included per month:
                            </p>
                            <p className="md:text-md font-bold text-md text-gray-500">
                                14 drivers
                            </p>
                        </div>
                        <div className="flex mb-2"></div>
                        {props.teamsProfile.tier == 2 
                        ? 
                            <div id="Plus" className="btn mt-3 py-2 font-semibold rounded-lg bg-gray-400 text-white border-gray-400 border-2 w-full">
                                <p>Your Current Plan</p>
                            </div>
                        : 
                            <div onClick={() => changeSub('Growing')} id="Plus" className="btn mt-3 py-2 font-semibold rounded-lg text-myteal-500 border-gray-200 border-2 hover:text-white hover:bg-myteal-500 hover:border-myteal-500 w-full cursor-pointer">
                                <p>Continue</p>
                            </div>
                        }
                        <div className="mt-7">
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Create and optimize routes</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Regionalized routing</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Balanced route distribution</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Unlimited route history</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Dispatch to driver mobile apps</p>
                            </div>
                        </div>
                    </div>

                    {/* 3rd item */}
                    <div style={{ height: 550 }} className="p-7 bg-white rounded-2xl shadow-xl border-gray-200 border-2 hover:shadow-2xl md:mt-5" data-aos="zoom-y-out" data-aos-delay="150">
                        <h4 className="text-xl text-myblue-900 font-semibold">Large</h4>
                        <p style={{ fontSize: 15 }} className="font-medium text-gray-600">Built for high-volume teams managing complex logistics.</p>
                        <div className="dashed-line"></div>
                        <div className="flex">
                            <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                                { isYearly ? LARGE_TEAM_PRICE_YEARLY : LARGE_TEAM_PRICE }
                            </div>
                            <div className="md:text-1xl text-gray-600 font-medium mt-6">/ month</div>
                        </div>
                        {/* { isYearly ? 
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-gray-500">${863.99} Billed Annually |</p>
                                <p className="md:text-sm text-sm text-myteal-500 ml-1 font-semibold">You Save ${216}</p>
                            </div>
                            :
                            <div className="flex mb-2">
                                <p className="md:text-sm text-sm text-white">-</p>
                            </div>
                        } */}
                        <div className="flex justify-between mb-2 font-medium">
                            <p className="md:text-md text-md text-gray-500">
                                Included per month:
                            </p>
                            <p className="md:text-md font-bold text-md text-gray-500">
                                20 drivers
                            </p>
                        </div>
                        <div className="flex mb-2"></div>
                        {props.teamsProfile.tier == 3 
                        ? 
                            <div id="Plus" className="btn mt-3 py-2 font-semibold rounded-lg bg-gray-400 text-white border-gray-400 border-2 w-full">
                                <p>Your Current Plan</p>
                            </div>
                        : 
                            <div onClick={() => changeSub('Large')} id="Plus" className="btn mt-3 py-2 font-semibold rounded-lg text-myteal-500 border-gray-200 border-2 hover:text-white hover:bg-myteal-500 hover:border-myteal-500 w-full cursor-pointer">
                                <p>Continue</p>
                            </div>
                        }
                        <div className="mt-7">
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Create and optimize routes</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Regionalized routing</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Balanced route distribution</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Unlimited route history</p>
                            </div>
                            <div className="flex my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Dispatch to driver mobile apps</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <ConfirmModal />
        </div>
    );
}

export default SubscriptionTeams;