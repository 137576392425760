import React, { useContext } from 'react';
import './TeamsGrid.css';
import Grid1 from './TeamsGrid1';
import Grid3 from './TeamsGrid3';
import Grid4 from './TeamsGrid4';
import Grid5 from './TeamsGrid5';
import Grid6 from './TeamsGrid6';

import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../AuthContext'

function WebGrid(props) {

  const history = useHistory();

  const { currentUser, getTeamProfile } = useContext(AuthContext);

  async function goSignUp() {
    if (currentUser) {

        let userDbData = await getTeamProfile(currentUser.email)

        // check to see if user has access to teams
        if (userDbData && userDbData.tier && userDbData.tier >= 1) {
          history.push('/teams-intro')
        } else {
          history.push('/pricing')
        }
    } else {

        // google tracking
        try {
            // Send the trial start event to Google Analytics (and ultimately Google Ads) after the user is successfully created
            // window.gtag && window.gtag('event', 'web_button_click', {
            // 'event_category': 'Button Click',
            // 'event_label': 'Web Button Click',
            // 'value': 0.1
            // });
            // console.log('Web Button Click')
        } catch (error) {
            console.log('Error in tracking web button click start in Google Analytics', error);
        }

        history.push('/signup?origin=teams')
    }
}

function goWaitlist() {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdwD52EgyuTukBcDh4pliKp2kvMyDMjTg8yKNzhUmgz2puFJg/viewform?usp=sf_link', '_blank').focus();
}


  return (
    <section id="main-webgrid" className="bg-myblue-900 py-5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 mb-32">
        
        <Grid1 />

        <div className="block md:flex w-full mb-3 md:mb-0">
            <div className="w-full md:w-2/5 mb-3 md:mr-3">
                <Grid3 />
            </div>
            <div className="w-full md:w-3/5 md:mb-0">
                <Grid5 />
            </div>
        </div>

        <div className="block md:flex w-full mb-3">
            <div className=" w-full mb-3 md:w-3/5">
                <Grid4 />
            </div>
            <div className="w-full md:w-2/5 md:ml-3 md:mb-0">
                <Grid6 />
            </div>
        </div>

        <div className="mx-auto text-center pb-2 md:pb-3 mt-24">
            <a onClick={goSignUp} className="btn cursor-pointer text-white font-semibold bg-blue-500 hover:bg-blue-600 mb-4 w-64 md:mb-0 py-4">Get Started</a>
        </div>

      </div>
    </section>
  );
}

export default WebGrid;
