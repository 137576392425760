import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import InspectorFileUpload from '../components/InspectorFileUpload';

function InspectorsImport(props) {
    const history = useHistory();
    const [importStatus, setImportStatus] = useState({
        inspectorList: { status: 'pending', data: null, errors: [], validRowCount: 0 },
        calendar: { status: 'pending', data: null, errors: [], validRowCount: 0 },
        holidayList: { status: 'pending', data: null, errors: [], validRowCount: 0 },
        district: { status: 'pending', data: null, errors: [], validRowCount: 0 }
    });

    const handleFileLoaded = (type, data, validationErrors) => {
        setImportStatus(prev => ({
            ...prev,
            [type]: {
                status: validationErrors ? 'success' : 'success',
                data: data,
                errors: validationErrors ? validationErrors.map(error => error.errors).flat() : [],
                validRowCount: data ? data.length : 0
            }
        }));

        // Pass all valid data to the parent component
        if (data && data.length > 0) {
            props.onInspectorsLoaded(type, data);
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'success': return 'text-green-600 bg-green-50';
            case 'error': return 'text-red-600 bg-red-50';
            case 'pending': return 'text-gray-600 bg-gray-50';
            default: return 'text-gray-600 bg-gray-50';
        }
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'success': return (
                <svg className="h-5 w-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
            );
            case 'error': return (
                <svg className="h-5 w-5 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            );
            default: return (
                <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            );
        }
    };

    const allUploadsSuccessful = Object.values(importStatus).every(status => status.status === 'success');

    return (
        <section className="relative">
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="max-w-3xl mx-auto text-center pb-4 md:pb-10 pt-32">
                    <h1 className="h2 mb-4 text-myblue-900">Import Inspectors Data</h1>
                    <p className="md:text-xl text-lg text-gray-600 mb-7">
                        Upload your inspector data files. The Inspector List is required, while other files can be updated as needed.
                    </p>
                </div>

                {/* Main Inspector List Upload */}
                <div className="mb-8">
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <div className="px-4 py-5 sm:px-6 bg-blue-50">
                            <h3 className="text-lg leading-6 font-medium text-blue-900">
                                Inspector List (Required)
                            </h3>
                            <p className="mt-1 text-sm text-blue-700">
                                Upload the main list of inspectors with their details and qualifications.
                            </p>
                        </div>
                        <div className="px-4 py-5 sm:p-6">
                            <InspectorFileUpload
                                type="inspectorList"
                                onFileLoaded={handleFileLoaded}
                                status={importStatus.inspectorList}
                                useDragDrop={true}
                            />
                        </div>
                    </div>
                </div>

                {/* Optional Imports */}
                <div className="space-y-6">
                    {/* Calendar */}
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <div className="px-4 py-5 sm:px-6 bg-gray-50">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Calendar
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-600">
                                        Import inspector availability and scheduling preferences.
                                    </p>
                                </div>
                                <button
                                    onClick={() => document.getElementById('calendarFile').click()}
                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    <svg className="mr-2 -ml-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                    </svg>
                                    Upload/Update
                                </button>
                            </div>
                        </div>
                        <div className="px-4 py-5 sm:p-6">
                            <InspectorFileUpload
                                type="calendar"
                                onFileLoaded={handleFileLoaded}
                                status={importStatus.calendar}
                                inputId="calendarFile"
                                useDragDrop={false}
                            />
                        </div>
                    </div>

                    {/* Holiday List */}
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <div className="px-4 py-5 sm:px-6 bg-gray-50">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Holiday List
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-600">
                                        Import holiday schedules and special dates.
                                    </p>
                                </div>
                                <button
                                    onClick={() => document.getElementById('holidayFile').click()}
                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    <svg className="mr-2 -ml-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                    </svg>
                                    Upload/Update
                                </button>
                            </div>
                        </div>
                        <div className="px-4 py-5 sm:p-6">
                            <InspectorFileUpload
                                type="holidayList"
                                onFileLoaded={handleFileLoaded}
                                status={importStatus.holidayList}
                                inputId="holidayFile"
                                useDragDrop={false}
                            />
                        </div>
                    </div>

                    {/* District */}
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <div className="px-4 py-5 sm:px-6 bg-gray-50">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        District
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-600">
                                        Update inspector district assignments and coverage areas.
                                    </p>
                                </div>
                                <button
                                    onClick={() => document.getElementById('districtFile').click()}
                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    <svg className="mr-2 -ml-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                    </svg>
                                    Upload/Update
                                </button>
                            </div>
                        </div>
                        <div className="px-4 py-5 sm:p-6">
                            <InspectorFileUpload
                                type="district"
                                onFileLoaded={handleFileLoaded}
                                status={importStatus.district}
                                inputId="districtFile"
                                useDragDrop={false}
                            />
                        </div>
                    </div>
                </div>

                {/* Import Status Summary */}
                <div className="mt-8 bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="px-4 py-5 sm:px-6 bg-gray-50">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Import Status
                        </h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <ul className="divide-y divide-gray-200">
                            {Object.entries(importStatus).map(([type, status]) => (
                                <li key={type} className="px-4 py-4 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            {getStatusIcon(status.status)}
                                            <span className="ml-3 text-sm font-medium text-gray-900">
                                                {type.charAt(0).toUpperCase() + type.slice(1).replace(/([A-Z])/g, ' $1')}
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-4">
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(status.status)}`}>
                                                {status.status.charAt(0).toUpperCase() + status.status.slice(1)}
                                            </span>
                                        </div>
                                    </div>
                                    {status.status === 'success' && status.data && status.data.length > 0 && (
                                        <div className="mt-2 text-sm text-green-600">
                                            Successfully uploaded {status.validRowCount} rows
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {allUploadsSuccessful && (
                        <div className="px-4 py-4 sm:px-6 bg-green-50 border-t border-green-100">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <svg className="h-5 w-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <p className="ml-3 text-sm text-green-700">
                                        All required inspector data has been successfully uploaded
                                    </p>
                                </div>
                                <button
                                    onClick={() => props.setPage('selection')}
                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                >
                                    Review and Start Scheduling
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default InspectorsImport;
