import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../AuthContext";

const COLORS = [
    '#8377fd', '#55caf5', '#f55578', '#ffaf00', '#01a240', '#4426d9', '#d926d9', '#d92644', '#d99d26', '#80d926',
    '#996633', '#26bbd9', '#2626d9', '#bb26d9', '#d92662', '#d98026', '#9dd926', '#26d944', '#26d9d9', '#2644d9',
    '#808080', '#d92680', '#d96226', '#bbd926', '#26d926', '#26d9bb', '#2662d9', '#7f26d9', '#d9269d', '#d94426',
    '#664c33', '#44d926', '#26d99d', '#267fd9', '#6226d9', '#d926bb', '#d92626', '#d9bb26', '#62d926', '#26d980',
    '#269dd9', '#4426d9', '#d926d9', '#d92644', '#d99d26', '#80d926', '#26d962', '#26bbd9', '#2626d9', '#bb26d9',
    '#d92662', '#d98026', '#9dd926', '#26d944', '#26d9d9', '#2644d9', '#9d26d9', '#d92680', '#d96226', '#bbd926',
    '#26d926', '#26d9bb', '#2662d9', '#7f26d9', '#d9269d', '#d94426', '#d9d926', '#44d926', '#26d99d', '#267fd9',
    '#6226d9', '#d926bb', '#d92626', '#d9bb26', '#62d926', '#26d980', '#269dd9', '#4426d9', '#d926d9', '#d92644',
    '#d99d26', '#80d926', '#26d962', '#26bbd9', '#2626d9', '#bb26d9', '#d92662', '#d98026', '#9dd926', '#26d944',
    '#26d9d9', '#2644d9', '#9d26d9', '#d92680', '#d96226', '#bbd926', '#26d926', '#26d9bb', '#2662d9', '#7f26d9'
];

function DriverTeams(props) {
    const { currentUser, updateDriverInfo } = useContext(AuthContext);
    const history = useHistory();

    const [editingDriverId, setEditingDriverId] = useState(null);
    const [driverDetails, setDriverDetails] = useState({});
    const [addingDriver, setAddingDriver] = useState(false);
    const [newDriver, setNewDriver] = useState({ name: "", email: "", phoneNum: "" });
    const [maxDriverCount, setMaxDriverCount] = useState(20);
    
    useEffect(() => {
        if (props.teamsProfile && props.teamsProfile.tier) {
            if (props.teamsProfile.tier == 1) {
            setMaxDriverCount(7);
            } else if (props.teamsProfile.tier == 2) {
            setMaxDriverCount(14);
            } else if (props.teamsProfile.tier == 3) {
            setMaxDriverCount(20);
            }
        }
    }, [props.teamsProfile]);

    const startEditing = (driver) => {
        setEditingDriverId(driver.id);
        setDriverDetails({
            name: driver.name,
            email: driver.email,
            phoneNum: driver.phoneNum || "",
        });
    };

    const cancelEditing = () => {
        setEditingDriverId(null);
        setDriverDetails({});
    };

    const handleInputChange = (e, field) => {
        setDriverDetails({
            ...driverDetails,
            [field]: e.target.value,
        });
    };

    const handleNewDriverChange = (e, field) => {
        setNewDriver({
            ...newDriver,
            [field]: e.target.value,
        });
    };

    // Function to find the next available color
    const getNextAvailableColor = () => {
        // Get all colors currently in use
        const usedColors = props.teamsProfile.drivers.map(driver => driver.colorCode);
        
        // Find the first color in the COLORS array that is not being used
        const nextColor = COLORS.find(color => !usedColors.includes(color));
        
        // If all colors are used (unlikely with our large array), return the first color
        return nextColor || COLORS[0];
    };

    async function saveChanges(driver) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(driverDetails.email);
        if (!isValidEmail) {
            alert("Please enter a valid email address");
            return;
        }

        const isValidPhone = /^\+?[0-9\s\-\(\)]{7,15}$/.test(driverDetails.phoneNum);
        if (!isValidPhone && driverDetails.phoneNum !== "") {
            alert("Please enter a valid phone number");
            return;
        }

        const updatedDrivers = props.teamsProfile.drivers.map((d) => {
            if (d.id === driver.id) {
                return {
                    ...d,
                    name: driverDetails.name,
                    email: driverDetails.email,
                    phoneNum: driverDetails.phoneNum,
                };
            }
            return d;
        });

        if (currentUser && currentUser.email) {
            try {
                await updateDriverInfo(currentUser.email, updatedDrivers);
                props.retrieveTeamProfile();
            } catch (error) {
                console.log(error);
                alert("Error saving driver information");
            }
        } else {
            alert("Error saving driver information, please try again");
        }

        setEditingDriverId(null);
    }

    async function removeDriver(driverId) {
        if (window.confirm("Are you sure you want to remove this driver?")) {
            const updatedDrivers = props.teamsProfile.drivers.filter(driver => driver.id !== driverId);
            
            if (currentUser && currentUser.email) {
                try {
                    await updateDriverInfo(currentUser.email, updatedDrivers);
                    props.retrieveTeamProfile();
                    setEditingDriverId(null);
                } catch (error) {
                    console.log(error);
                    alert("Error removing driver");
                }
            } else {
                alert("Error removing driver, please try again");
            }
        }
    }

    async function saveNewDriver() {
        if (!newDriver.name || !newDriver.email) {
            alert("Name and Email are required.");
            return;
        }

        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newDriver.email);
        if (!isValidEmail) {
            alert("Please enter a valid email address");
            return;
        }

        const isValidPhone = /^\+?[0-9\s\-\(\)]{7,15}$/.test(newDriver.phoneNum);
        if (!isValidPhone && newDriver.phoneNum !== "") {
            alert("Please enter a valid phone number");
            return;
        }

        // Find the highest ID in the existing drivers to ensure unique IDs
        const maxId = props.teamsProfile.drivers.reduce(
            (max, driver) => (driver.id > max ? driver.id : max),
            0
        );

        // Get the next available color that's not already in use
        const nextColor = getNextAvailableColor();

        const updatedDrivers = [
            ...props.teamsProfile.drivers,
            {
                id: maxId + 1,
                name: newDriver.name,
                email: newDriver.email,
                phoneNum: newDriver.phoneNum,
                colorCode: nextColor,
            },
        ];

        if (currentUser && currentUser.email) {
            try {
                await updateDriverInfo(currentUser.email, updatedDrivers);
                props.retrieveTeamProfile();
            } catch (error) {
                console.log(error);
                alert("Error saving new driver information");
            }
        } else {
            alert("Error saving new driver information, please try again");
        }

        setAddingDriver(false);
        setNewDriver({ name: "", email: "", phoneNum: "" });
    }

    const handleAddDriverClick = () => {
        if (props.teamsProfile.drivers.length >= maxDriverCount) {
            alert(`You've reached the maximum number of drivers for your plan. Upgrade to add more.`);
        } else {
            setAddingDriver(true);
        }
    };

    return (
        <div className="px-36 pt-28">
            <div className="title text-lg font-medium text-myblue-900 px-4">
                <h1>Driver Information</h1>
                <label className="block text-gray-600 text-xs font-medium mb-1">
                    Manage your team by editing or adding driver information.
                </label>

                <div className="grid grid-cols-12 gap-4 font-semibold text-sm text-gray-700 mt-10 mb-2">
                    <div className="col-span-5">Name</div>
                    <div className="col-span-4">Email</div>
                    <div className="col-span-2">Phone</div>
                </div>

                <div className="overflow-y-auto pt-2">
                    {props.teamsProfile &&
                        props.teamsProfile.drivers &&
                        props.teamsProfile.drivers.map((driver) => (
                            <div key={driver.id} className="grid grid-cols-12 gap-4 items-center px-2 py-3 bg-white hover:bg-gray-100 cursor-pointer border rounded mb-2">
                                <div className="col-span-5 flex items-center space-x-4 ml-2">
                                    {editingDriverId === driver.id ? (
                                        <>
                                            <button 
                                                className="w-4 h-4 flex items-center justify-center text-red-500 hover:text-red-700"
                                                onClick={() => removeDriver(driver.id)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </button>
                                            <input
                                                type="text"
                                                className="font-medium text-sm border p-1"
                                                value={driverDetails.name}
                                                onChange={(e) => handleInputChange(e, "name")}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <div className="w-4 h-4 rounded-full" style={{ backgroundColor: driver.colorCode }} />
                                            <p className="font-medium truncate text-sm">{driver.name}</p>
                                        </>
                                    )}
                                </div>
                                <div className="col-span-4">
                                    {editingDriverId === driver.id ? (
                                        <input
                                            type="email"
                                            className="text-sm border p-1 w-full"
                                            value={driverDetails.email}
                                            onChange={(e) => handleInputChange(e, "email")}
                                        />
                                    ) : (
                                        <p className="text-sm truncate text-gray-500">{driver.email}</p>
                                    )}
                                </div>
                                <div className="col-span-2">
                                    {editingDriverId === driver.id ? (
                                        <input
                                            type="text"
                                            className="text-sm border p-1 w-full"
                                            value={driverDetails.phoneNum}
                                            onChange={(e) => handleInputChange(e, "phoneNum")}
                                        />
                                    ) : (
                                        <p className="text-sm truncate text-gray-500">{driver.phoneNum ? driver.phoneNum : "--"}</p>
                                    )}
                                </div>
                                <div className="col-span-1 flex justify-center border-l">
                                    {editingDriverId === driver.id ? (
                                        <div className="space-x-2">
                                            <button
                                                className="text-xs pl-2 text-blue-500 hover:text-blue-700"
                                                onClick={() => saveChanges(driver)}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="text-xs pr-1 text-gray-500 hover:text-gray-700"
                                                onClick={cancelEditing}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            className="text-sm pl-2 text-gray-500 hover:text-black"
                                            onClick={() => startEditing(driver)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" id="pen">
                                                <path
                                                    fill="currentColor"
                                                    d="M22,7.24a1,1,0,0,0-.29-.71L17.47,2.29A1,1,0,0,0,16.76,2a1,1,0,0,0-.71.29L13.22,5.12h0L2.29,16.05a1,1,0,0,0-.29.71V21a1,1,0,0,0,1,1H7.24A1,1,0,0,0,8,21.71L18.87,10.78h0L21.71,8a1.19,1.19,0,0,0,.22-.33,1,1,0,0,0,0-.24.7.7,0,0,0,0-.14ZM6.83,20H4V17.17l9.93-9.93,2.83,2.83ZM18.17,8.66,15.34,5.83l1.42-1.41,2.82,2.82Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    {addingDriver && (
                        <div className="grid grid-cols-12 gap-4 items-center px-2 py-3 bg-white hover:bg-gray-100 cursor-pointer border rounded mb-2">
                            <div className="col-span-5 flex items-center space-x-4 ml-2">
                                <button 
                                    className="w-4 h-4 flex items-center justify-center text-red-500 hover:text-red-700"
                                    onClick={() => setAddingDriver(false)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </button>
                                <input
                                    type="text"
                                    placeholder="Driver Name"
                                    className="font-medium text-sm border p-1"
                                    value={newDriver.name}
                                    onChange={(e) => handleNewDriverChange(e, "name")}
                                />
                            </div>
                            <div className="col-span-4">
                                <input
                                    type="email"
                                    placeholder="Driver Email"
                                    className="text-sm border p-1 w-full"
                                    value={newDriver.email}
                                    onChange={(e) => handleNewDriverChange(e, "email")}
                                />
                            </div>
                            <div className="col-span-2">
                                <input
                                    type="text"
                                    placeholder="Phone Number"
                                    className="text-sm border p-1 w-full"
                                    value={newDriver.phoneNum}
                                    onChange={(e) => handleNewDriverChange(e, "phoneNum")}
                                />
                            </div>
                            <div className="col-span-1 flex justify-center border-l">
                                <button
                                    className="text-xs pl-2 text-blue-500 hover:text-blue-700"
                                    onClick={saveNewDriver}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <div onClick={handleAddDriverClick} className="w-full cursor-pointer bg-white hover:bg-blue-100 py-2 rounded border border-dashed border-blue-500 flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="23" height="23" viewBox="0 0 512 512">
                        <path
                            d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                            fill="none"
                            stroke="currentColor"
                            strokeMiterlimit="10"
                            strokeWidth="32"
                        />
                        <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="32"
                            d="M256 176v160M336 256H176"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default DriverTeams;