import { createContext, useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext'

function ProfileTeams(props) {

    const { currentUser } = useContext(AuthContext);

    const history = useHistory();

    const [tier, setTier] = useState('Small')
    const [maxDriverCount, setMaxDriverCount] = useState(0)
    const [isSubscribed, setIsSubscribed] = useState(false)


    useEffect(() => {
        if (props.teamsProfile && props.teamsProfile.tier >= 1) {
            setIsSubscribed(true)

            if (props.teamsProfile.tier == 1) {
                setTier('Small')
                setMaxDriverCount(7)
            } else if (props.teamsProfile.tier == 2) {
                setTier('Growing')
                setMaxDriverCount(14)
            } else if (props.teamsProfile.tier == 3) {
                setTier('Large')
                setMaxDriverCount(20)
            } else {
                setTier('ERROR')
            }
        }
      }, [props.teamsProfile]);
    

    function goBilling() {
        props.setSection('Billing')
    }

    function goSubscription() {
        props.setSection('Subscription')
    }


    return (
        <div className="h-screen flex-grow overflow-y-auto pt-24 pl-10">

            {currentUser ? (
                <div>
                    <div className="flex justify-center items-center mt-10" data-aos="zoom-y-out" data-aos-delay="150">
                        {currentUser.photoURL ? (
                            <img className="w-44 h-44 rounded-full"
                                alt=""
                                src={currentUser.photoURL}
                            />
                        ) : (
                            <div className="w-44 h-44 text-6xl pt-6 text-white ml-2 bg-myteal-500 rounded-full flex justify-center cursor-pointer" data-aos="zoom-y-out" data-aos-delay="150">{currentUser.displayName.charAt(0)}</div>
                        )}
                    </div>

                    <div className="max-w-3xl mt-5 mx-auto text-center pb-6 md:pb-10" data-aos="zoom-y-out" data-aos-delay="300">
                        <h2 className="h2">{currentUser.displayName}</h2>
                        <p className="text-xl text-gray-600">Manage your subscriptions</p>
                        {isSubscribed ? (
                            <div>
                                <div className="md:text-1xl text-gray-500"><span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-myteal-500">SUBSCRIBED</span> TO TEAMS</div>
                                <p className="text-gray-600 text-xl mt-10">Plan: <span className="font-bold">{tier} Team</span></p>
                                <p className="text-sm text-gray-500">({maxDriverCount} drivers included)</p>
                            </div>
                        ) : (
                            <div className="md:text-1xl text-gray-500">NOT SUBSCRIBED</div>
                        )}
                    </div>

                </div>
            ) : (
                <div></div>
            )} 

            <div className="flex flex-grow justify-center flex-wrap items-center">
                <div onClick={goSubscription} className="btn-sm px-6 text-gray-100 bg-myteal-500 hover:bg-myteal-600 ml-3 cursor-pointer">
                    <span>Upgrade/Downgrade</span>
                    <svg className="w-3 h-3 fill-current text-gray-100 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                    </svg>                  
                </div>
                <div onClick={goBilling} className="btn-sm px-6 text-gray-100 bg-blue-500 hover:bg-blue-600 ml-3 cursor-pointer">
                    <span>Billing</span>
                    <svg className="w-3 h-3 fill-current text-gray-100 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                    </svg>                  
                </div>
            </div>

            <div className="text-sm text-gray-600 mx-auto text-center mt-10">Email us at <span className="text-black">info@routora.com</span></div>
            
        </div>
    );
}

export default ProfileTeams;