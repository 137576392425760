import React, { useState } from 'react';
import Axios from 'axios'

import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

function EmailModal(props) {

    const [emailValue, setEmailValue] = useState(props.email)
    const [emailSending, setEmailSending] = useState(false)

    function handleEmailValueChange(e) {
        setEmailValue(e.target.value)
    }

    // async function sendEmail() {

    //     // prevent spamming of email button
    //     if (emailSending) {
    //         return
    //     } else {
    //         setEmailSending(true)
    //     }

    //     if (props.directionsData.routeOrder.length <= 25) {
    //         let status = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/sendemail`, {'toEmail': emailValue, 'mapsUrl': props.directionsData.curatedLinks.googleUrl})

    //         if (status.data.isSent) {
    //             alert('Email Sent! Check spam if not in inbox')
    //             setEmailSending(false)
    //         } else {
    //             alert('There was an issue sending the email, check if email address is valid')
    //             setEmailSending(false)
    //         }

    //     } else {
    //         alert('Google Maps has a 25 address limit including start and destination.')
    //     }
    // }

    // code to create link
    function createLink(routeOrder, totalDistance, totalTime) {
        let baseUrl = 'http://routora.com/shared-route/'

        baseUrl += (totalDistance + '+' + totalTime + '/')

        // add addresses
        for (let i = 0; i < routeOrder.length; i++) {
            if (i == routeOrder.length-1) {
                baseUrl += encodeURIComponent(routeOrder[i].address)
            } else {
                baseUrl += encodeURIComponent(routeOrder[i].address) + '+'
            }
        }

        // add coordinates
        baseUrl += '/'
        for (let i = 0; i < routeOrder.length; i++) {
            if (i == routeOrder.length-1) {
                baseUrl += (routeOrder[i].coordinates[0] + ',' + routeOrder[i].coordinates[1])
            } else {
                baseUrl += (routeOrder[i].coordinates[0] + ',' + routeOrder[i].coordinates[1] + '+')
            }
        }

        return baseUrl
    }
    function createRouteIdLink() {
        let baseUrl = 'http://routora.com/shared-route-id/'
        baseUrl += props.routeId

        return baseUrl
    }

    async function sendEmail() {

        // prevent spamming of email button
        if (emailSending) {
            return
        } else {
            setEmailSending(true)
        }

        // set up url
        let routoraRouteLink
        
        // if route length is over 25 stops, use id strategy
        // if (props.directionsData.routeOrder.length > 25) { 
        if (props.directionsData.routeOrder) { // Switched to always using DB method
            routoraRouteLink = createRouteIdLink()
        } else { // else create route in link
            routoraRouteLink = createLink(props.directionsData.routeOrder, props.directionsData.totalDistance, props.directionsData.totalTime)
        }

        console.log('Route Link', routoraRouteLink)

        
        let status = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/sendemail`, {'toEmail': emailValue, 'mapsUrl': routoraRouteLink})

        if (status.data.isSent) {
            // amplitude tracking
            const eventProperties = {
                route_link: routoraRouteLink,
                route_length: props.directionsData.routeOrder.length
            };
            amplitude.track('Email Sent', eventProperties);

            alert('Email Sent! Check spam if not in inbox')
            setEmailSending(false)
            props.setOpenEmailModal(false);
        } else {
            alert('There was an issue sending the email, check if email address is valid')
            setEmailSending(false)
            props.setOpenEmailModal(false);
        }
    }

  return (
    <div className="modalBackground">
      <div className="modalContainer email">
            {/* <div className="title text-xl text-gray-600">
                <h1>{props.subMessage}</h1>
            </div> */}
            <div className="body text-2xl text-gray-600">
            <input type="text" className="form-input w-11/12 text-gray-800 px-3 py-2 text-sm hover:bg-gray-100" value={emailValue} onChange={handleEmailValueChange}/>
            </div>
            <div className="footer">
            <div
                onClick={() => {
                    props.setOpenEmailModal(false);
                }}
                id="cancelBtn"
                className="btn-sm text-gray-100 bg-myred-500 hover:bg-myred-600 cursor-pointer m-2 w-44"
            >
                Close
            </div>
            <div className="btn-sm text-gray-100 bg-blue-500 hover:bg-blue-600 cursor-pointer m-2 w-44" onClick={sendEmail}>Send Email</div>
        </div>
      </div>
    </div>
  );
}

export default EmailModal;