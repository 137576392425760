import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UAParser from 'ua-parser-js';

import Header from '../../partials/Header';
import SharedIndividualRoutes from './SharedIndividualRoutes';
import SharedResultsBox from './SharedResultsBox';
import SharedRouteModal from './SharedRouteModal'
import SplitUrlModal from '../SplitUrlModal/SplitUrlModal'

import Axios from 'axios'

function SharedRoute(props) {

    const { dataurl, routesurl, coordinatesurl } = useParams();
    const { routeid } = useParams();

    const [splitUrlModalOpen, setSplitUrlModalOpen] = useState(false)
    const [hasPromptedForApp, setHasPromptedForApp] = useState(false);
    const [error, setError] = useState(null);

    const parser = new UAParser();
    const result = parser.getResult();
    const deviceType = result.device.type;
    let isMobile = false
    if (deviceType != 'desktop' && deviceType != 'mobile' && deviceType != 'tablet') {
        if (window.innerWidth < 768) {
            isMobile = true
        }
    } else {
        if (deviceType == 'mobile' || deviceType == 'tablet') {
            isMobile = true
        }
    }

    //const [modalOpen, setModalOpen] = useState(isMobile);
    const [modalOpen, setModalOpen] = useState(false); // remove (and uncomment above) this when deeplinking is ready
    const [os, setOs] = useState(result.os.name)

    const [directionsData, setDirectionsData] = useState({
        curatedLinks: {googleUrl: "", appleUrl: "", wazeUrl: ""},
        routeOrder: [],
        totalDistance: 0,
        totalTime: 0
    })

    const [mapSelected, setMapSelected] = useState({
        googleMaps: true,
        appleMaps: false,
        waze: false
    })

    let navApp = 'google'
    if (mapSelected.appleMaps) {
        navApp = 'apple'
    } else if (mapSelected.waze){
        navApp = 'waze'
    }

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {

        if (routeid && routeid != null) {
            dbMethod()
        } else {
            urlMethod()
        }
        

    }, []);

    
    useEffect(() => {
        // Only show the prompt once and only on mobile
        if (isMobile && !hasPromptedForApp && routeid) {
          setHasPromptedForApp(true);
          
          // Create modal for app opening prompt
          const modal = document.createElement('div');
          modal.style.position = 'fixed';
          modal.style.top = '0';
          modal.style.left = '0';
          modal.style.width = '100%';
          modal.style.height = '100%';
          modal.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
          modal.style.display = 'flex';
          modal.style.justifyContent = 'center';
          modal.style.alignItems = 'center';
          modal.style.zIndex = '9999';
          
          const modalContent = document.createElement('div');
          modalContent.style.backgroundColor = 'white';
          modalContent.style.padding = '20px';
          modalContent.style.borderRadius = '10px';
          modalContent.style.maxWidth = '80%';
          modalContent.style.textAlign = 'center';
          
          const title = document.createElement('h3');
          title.textContent = 'Open route in the Routora Mobile App?';
          title.style.marginBottom = '20px';
          
          // Create a container for buttons
          const buttonContainer = document.createElement('div');
          buttonContainer.style.display = 'flex';
          buttonContainer.style.justifyContent = 'center';
          buttonContainer.style.gap = '10px'; // Space between buttons
          
          const openAppBtn = document.createElement('button');
          openAppBtn.textContent = 'Open in App';
          openAppBtn.style.padding = '10px 20px';
          openAppBtn.style.backgroundColor = '#4285f4';
          openAppBtn.style.color = 'white';
          openAppBtn.style.border = 'none';
          openAppBtn.style.borderRadius = '5px';
          openAppBtn.style.cursor = 'pointer';
          openAppBtn.style.flex = '1';
          openAppBtn.style.maxWidth = '140px';
          openAppBtn.onclick = () => {
            document.body.removeChild(modal);
            openAppLink();
          };
          
          const stayBtn = document.createElement('button');
          stayBtn.textContent = 'Stay on Web';
          stayBtn.style.padding = '10px 20px';
          stayBtn.style.backgroundColor = '#f1f1f1';
          stayBtn.style.color = 'black';
          stayBtn.style.border = 'none';
          stayBtn.style.borderRadius = '5px';
          stayBtn.style.cursor = 'pointer';
          stayBtn.style.flex = '1';
          stayBtn.style.maxWidth = '140px';
          stayBtn.onclick = () => {
            document.body.removeChild(modal);
          };
          
          // Add buttons to the container
          buttonContainer.appendChild(openAppBtn);
          buttonContainer.appendChild(stayBtn);
          
          // Add title and button container to the modal content
          modalContent.appendChild(title);
          modalContent.appendChild(buttonContainer);
          modal.appendChild(modalContent);
          
          // Add a slight delay before showing the prompt to ensure page is loaded
          setTimeout(() => {
            document.body.appendChild(modal);
          }, 500);
        }
    }, [isMobile, hasPromptedForApp, routeid]);

    function urlMethod() {
        // unpack url
        let dataUrlSplit = dataurl.split('+')
        let dist = dataUrlSplit[0]
        let time = dataUrlSplit[1]
        console.log('totalDistance: ' + dist)
        console.log('totalTime: ' + time)

        let directions = {
            curatedLinks: {googleUrl: "", appleUrl: "", wazeUrl: ""},
            routeOrder: [],
            totalDistance: dist,
            totalTime: time
        }

        let googleUrl = ''
        let appleUrl = ''
        let addressUrlList = routesurl.split('+')
        // START ADDRESS
        let startAddress = addressUrlList[0]
        googleUrl = 'https://www.google.com/maps/dir/' + startAddress + '/'
        appleUrl = 'http://maps.apple.com/?saddr=' + startAddress
        let googleStartLink = 'https://www.google.com/maps/dir/?api=1&destination=' + startAddress + '&travelmode=' + 'd'
        let appleMapsStartLink = 'http://maps.apple.com/?address=' + startAddress
        let wazeStartLink = 'https://www.waze.com/ul?q='+ startAddress +'&navigate=yes'
        let soloUrls = {
            googleUrl: googleStartLink,
            appleUrl: appleMapsStartLink,
            wazeUrl: wazeStartLink
        }
        directions.routeOrder.push({address: decodeURIComponent(startAddress), soloUrls: soloUrls})
        // ORDERED STOPS
        let googleLink
        let appleMapsLink
        let wazeLink
        for (let i = 1; i < addressUrlList.length-1; i++) {
            googleUrl += addressUrlList[i] + '/'
            appleUrl += '&daddr=' + addressUrlList[i]
            googleLink = 'https://www.google.com/maps/dir/?api=1&destination=' + addressUrlList[i] + '&travelmode=' + 'd'
            appleMapsLink = 'http://maps.apple.com/?address=' + addressUrlList[i]
            wazeLink = 'https://www.waze.com/ul?q='+ addressUrlList[i] +'&navigate=yes'
            soloUrls = {
                googleUrl: googleLink,
                appleUrl: appleMapsLink,
                wazeUrl: wazeLink
            }
            directions.routeOrder.push({address: decodeURIComponent(addressUrlList[i]), soloUrls: soloUrls})
        }
        // DESTINATION ADDRESS
        let destinationAddress = addressUrlList[addressUrlList.length-1]
        googleUrl += destinationAddress + '/'
        appleUrl += '&daddr=' + destinationAddress + '&dirflg=d'
        let googleEndLink = 'https://www.google.com/maps/dir/?api=1&destination=' + destinationAddress + '&travelmode=' + 'd'
        let appleMapsEndLink = 'http://maps.apple.com/?address=' + destinationAddress
        let wazeEndLink = 'https://www.waze.com/ul?q='+ destinationAddress +'&navigate=yes'
        soloUrls = {
            googleUrl: googleEndLink,
            appleUrl: appleMapsEndLink,
            wazeUrl: wazeEndLink
        }
        directions.routeOrder.push({address: decodeURIComponent(destinationAddress), soloUrls: soloUrls})

        directions.curatedLinks = {
            googleUrl: googleUrl, 
            appleUrl: appleUrl, 
            wazeUrl: googleUrl
        }

        console.log(directions)
        setDirectionsData(directions)
        setLoaded(true)
    }

    async function dbMethod() {
        console.log('Route ID -> ', routeid)
        try {
            const response = await Axios.post(`https://api.routora.com/get-route/${routeid}`);
            // route should be saved and id used to save it should be passed back
            let routeData = response.data.routeData;
            console.log(routeData);

            if (!routeData) {
                setError("This route has expired or doesn't exist. Please request a new route link.");
                setLoaded(true);
                return;
            }

            setDirectionsData(routeData);
            setLoaded(true);
        } catch (error) {
            setError("Could not retrieve route data. Please contact info@routora.com if this is a recurring problem");
            setLoaded(true);
        }
    }


    // Utility to detect browser
    const getBrowser = () => {
        const userAgent = navigator.userAgent;
        
        // Safari detection
        if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
            console.log('SAFARI')
            return 'Safari';
        }
        
        // Chrome detection (includes other Chromium browsers)
        if (/Chrome/.test(userAgent)) {
            console.log('CHROME')
            return 'Chrome';
        }
        
        // Firefox detection
        if (/Firefox/.test(userAgent)) {
            console.log('FIREFOX')
            return 'Firefox';
        }
        
        return 'unknown';
    };

    const openAppLink = () => {
        const browser = getBrowser();
        const APP_STORE_URL = 'https://apps.apple.com/us/app/routora/id6450558064';
        const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.routora.app';
        
        // Create a modal dialog element
        const createDownloadPrompt = (storeUrl) => {
            const modal = document.createElement('div');
            modal.style.position = 'fixed';
            modal.style.top = '0';
            modal.style.left = '0';
            modal.style.width = '100%';
            modal.style.height = '100%';
            modal.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
            modal.style.display = 'flex';
            modal.style.justifyContent = 'center';
            modal.style.alignItems = 'center';
            modal.style.zIndex = '9999';
            
            const modalContent = document.createElement('div');
            modalContent.style.backgroundColor = 'white';
            modalContent.style.padding = '20px';
            modalContent.style.borderRadius = '10px';
            modalContent.style.maxWidth = '80%';
            modalContent.style.textAlign = 'center';
            
            const message = document.createElement('p');
            message.textContent = 'App not opening? You may need to download it first.';
            message.style.marginBottom = '20px';
            
            const downloadBtn = document.createElement('button');
            downloadBtn.textContent = 'Download App';
            downloadBtn.style.padding = '10px 20px';
            downloadBtn.style.backgroundColor = '#4285f4';
            downloadBtn.style.color = 'white';
            downloadBtn.style.border = 'none';
            downloadBtn.style.borderRadius = '5px';
            downloadBtn.style.marginRight = '10px';
            downloadBtn.style.cursor = 'pointer';
            downloadBtn.onclick = () => {
                window.location.href = storeUrl;
                document.body.removeChild(modal);
            };
            
            const cancelBtn = document.createElement('button');
            cancelBtn.textContent = 'Cancel';
            cancelBtn.style.padding = '10px 20px';
            cancelBtn.style.backgroundColor = '#f1f1f1';
            cancelBtn.style.color = 'black';
            cancelBtn.style.border = 'none';
            cancelBtn.style.borderRadius = '5px';
            cancelBtn.style.cursor = 'pointer';
            cancelBtn.onclick = () => {
                document.body.removeChild(modal);
            };
            
            modalContent.appendChild(message);
            modalContent.appendChild(downloadBtn);
            modalContent.appendChild(cancelBtn);
            modal.appendChild(modalContent);
            
            return modal;
        };
    
        // Show download prompt after timeout
        const showDownloadPromptAfterDelay = (storeUrl) => {
            setTimeout(() => {
                if (!document.hidden) {
                    const modal = createDownloadPrompt(storeUrl);
                    document.body.appendChild(modal);
                }
            }, 1500); // Increased timeout to give app more time to open
        };
        
        if (browser === 'Safari') {
            // Try to open the app
            window.location.href = `com.routora.app://shared-route/${routeid}`;
            showDownloadPromptAfterDelay(APP_STORE_URL);
        } 
        else if (browser === 'Chrome' || browser === 'Firefox') {
            // For Chrome/Firefox on Android, we can use Intent URLs but still show our custom prompt
            // if the app doesn't open after a delay
            const intentUrl = 
                `intent://shared-route/${routeid}#Intent;` +
                'scheme=com.routora.app;' +
                'package=com.routora.app;' +
                'end'; // Removed fallback URL to prevent automatic store opening
            
            window.location.href = intentUrl;
            showDownloadPromptAfterDelay(PLAY_STORE_URL);
        } 
        else {
            // For other browsers
            window.location.href = `com.routora.app://shared-route/${routeid}`;
            showDownloadPromptAfterDelay(PLAY_STORE_URL);
        }
    };
    

    let content = (
        <div>
            Loading...
        </div>
    )

    if (loaded) {
        if (error) {
            content = (
                <div className="flex flex-col items-center justify-center p-8">
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4 w-full max-w-2xl">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-red-700">
                                    {error}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            content = (
                <div>
                    <SharedResultsBox
                        directionsData={directionsData}
                        mapSelected={mapSelected}
                        setSplitUrlModalOpen={setSplitUrlModalOpen}
                        openAppLink={openAppLink}
                    ></SharedResultsBox>

                    <SharedIndividualRoutes
                        directionsData={directionsData}
                        setMapSelected={setMapSelected}
                        mapSelected={mapSelected}
                    ></SharedIndividualRoutes>
                </div>
            )
        }
    }

    return (
        <div>
            {splitUrlModalOpen &&
                <SplitUrlModal
                    setOpenModal={setSplitUrlModalOpen}
                    directionsData={directionsData}
                    navApp={navApp}
                ></SplitUrlModal>
            }
            {modalOpen && 
            <SharedRouteModal 
                setOpenModal={setModalOpen}
                os={os}
                routeid={routeid}
            ></SharedRouteModal>}
            <Header></Header>
            <div className="h-20"></div>

            { content }
        </div>
    );
}

export default SharedRoute;

