import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../AuthContext'

function RoutoraForTeams(props) {

    const history = useHistory();

    const { currentUser, getTeamProfile } = useContext(AuthContext);
    const [hoveredImage, setHoveredImage] = useState(null);

    async function goSignUp() {
        if (currentUser) {

            let userDbData = await getTeamProfile(currentUser.email)
  
            // check to see if user has access to teams
            if (userDbData && userDbData.tier && userDbData.tier >= 1) {
              history.push('/teams')
            } else {
              history.push('/teams/onboarding')
            }
        } else {

            // google tracking
            try {
                // Send the trial start event to Google Analytics (and ultimately Google Ads) after the user is successfully created
                // window.gtag && window.gtag('event', 'web_button_click', {
                // 'event_category': 'Button Click',
                // 'event_label': 'Web Button Click',
                // 'value': 0.1
                // });
                // console.log('Web Button Click')
            } catch (error) {
                console.log('Error in tracking web button click start in Google Analytics', error);
            }

            history.push('/signup?origin=teams')
        }
    }

    function goWaitlist() {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdwD52EgyuTukBcDh4pliKp2kvMyDMjTg8yKNzhUmgz2puFJg/viewform?usp=sf_link', '_blank').focus();
    }

    return (
        <section className="relative">
            {!props.isTeamsIntro && 
                <div>
                    <div className="absolute inset-0 bg-gradient-to-t from-white to-gray-100 pointer-events-none" aria-hidden="true"></div>
                    <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
                </div>
            }
            <div id="web-intro" className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="max-w-3xl mx-auto text-center pb-4 md:pb-10 pt-20">
                    <div style={{ padding: "3px 5px 3px 5px", width: "55px", fontSize: "15px" }} className="text-myteal-500 mx-auto font-semibold bg-myteal-200 mb-3 rounded">NEW</div>
                    <h1 className="h2 mb-4 text-myblue-900">Routora for Teams</h1>
                    <p className="md:text-xl text-lg text-gray-600 mb-7">
                        Plan, assign, and optimize routes for your team in seconds. Smarter fleet management starts here.
                    </p>
                    <div>
                        <a
                            onClick={goSignUp}
                            className="btn cursor-pointer text-white font-semibold bg-blue-500 hover:bg-blue-600 w-1/2 mb-4 md:w-64 md:mb-0 py-4"
                        >
                            Get Started
                        </a>
                    </div>
                </div>
                <div className="relative pb-32">
                    {/* Web Dashboard Image */}
                    <div 
                        className={`rounded-2xl shadow-lg bg-white relative z-10 cursor-pointer transform transition-all duration-300 ease-in-out hover:-translate-y-4 hover:shadow-xl ${hoveredImage === 'mobile' ? 'opacity-50' : ''}`}
                        onMouseEnter={() => setHoveredImage('web')}
                        onMouseLeave={() => setHoveredImage(null)}
                    >
                        <img
                            src={require('../images/FleetScreenShot.png').default}
                            alt="Routora for Teams Visual"
                            className="rounded-lg shadow-2xl w-full border"
                        />
                    </div>
                    
                    {/* Mobile App Image (Overlapping) */}
                    <div 
                        className={`absolute flex bottom-28 -right-4 md:bottom-24 md:-right-10 w-1/5 z-20 transform rotate-[5deg] cursor-pointer transition-all duration-300 ease-in-out hover:-translate-y-4 hover:shadow-xl`}
                        onMouseEnter={() => setHoveredImage('mobile')}
                        onMouseLeave={() => setHoveredImage(null)}
                    >
                        
                        <img
                            src={require('../images/MobileScreenShot.png').default}
                            alt="Mobile App Preview"
                            className="rounded-xl shadow-xl border"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RoutoraForTeams;
