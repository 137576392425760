import { createContext, useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext'
import Axios from 'axios';

function BillingTeams(props) {

    const { currentUser } = useContext(AuthContext);

    const history = useHistory();

    async function goCustomerPortal() {
        try {

            // Step 1: Retrieve the customer ID by calling the /get-customer-id endpoint
            const customerRes = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/get-customer-id`, {
            subscription_id: props.teamsProfile.subscription_id, // Send the subscription ID to get the customer ID
          });
      
          const { customerId } = customerRes.data;
      
          if (!customerId) {
            console.error('Error: No customer ID returned from Stripe');
            alert('Failed to retrieve user. Please try again or email info@routora.com');
            return;
          }
      
          // Step 2: Once the customer ID is retrieved, create a session to the customer portal
          const res = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/create-customer-portal-session`, {
            customerId, // Pass the retrieved customer ID
            returnUrl: 'https://www.routora.com/teams/settings/billing', // The return URL after the user updates payment details
          });
      
          const { url } = res.data; // Extract the session URL from the response
      
          if (url) {
            window.location.href = url; // Redirect the user to the Stripe portal URL
          } else {
            console.error('Error: No URL returned from Stripe');
            alert('Failed to open user subscription portal. Please try again or email info@routora.com');
          }

        } catch (error) {
          console.error('Error opening customer portal:', error);
          alert('Failed to open user subscription portal. Please try again or email info@routora.com');
        }
    }

    function goCancel() {
        history.push('/teams/cancel')
    }

    if (!props.teamsProfile) {
        return <div>Loading billing information...</div>;
    }

    return (
        <div className="pt-28 px-44">

            <p className="mb-5 text-sm text-gray-500">ROUTORA FOR TEAMS</p>
            <div className="p-10 rounded border shadow-md border-gray-200">

                <p className="title text-lg font-medium text-myblue-900">Subscriptions</p>
                <p className="mb-10 text-gray-500 text-xs">From this page, you can review and modify your subscription, manage payment methods, update billing details, and cancel your plan at any time. Additionally, you can view your entire invoice history and download receipts and invoices for your records.</p>

                <ul className="flex flex-grow justify-center flex-wrap items-center">
                    <li>
                        <div onClick={goCancel} className="font-medium text-myred-500 hover:text-myred-600 px-5 py-3 flex items-center transition duration-150 ease-in-out underline underline-offset-1 cursor-pointer">Delete Subscription</div>
                    </li>
                    <li className="mr-5">
                        <div onClick={goCustomerPortal} className="btn-sm text-gray-100 bg-myred-500 hover:bg-myred-600 ml-3 cursor-pointer">
                            <span>Manage Billing</span>
                            <svg className="w-3 h-3 fill-current text-gray-100 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                            </svg>                  
                        </div>
                    </li>
                </ul>

            </div>
            
        </div>
    );
}

export default BillingTeams;