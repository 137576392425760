import axios from "axios";

export class OnboardingApi {
  static async createCustomer(userEmail, name) {
    const response = await axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/create-customer`, {
      userEmail: userEmail,
      name: name,
    });

    if (response.status === 200) {
      return response.data;
    }

    throw Error("Something went wrong while creating a subscription.");
  }

  static async startTrial(userEmail, customerId, tier, trialDays = 7) {
    const response = await axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/create-checkout-session`, {
      userEmail: userEmail,
      customerId: customerId,
      tier: tier,
      trialDays: trialDays
    });

    if (response.status === 200) {
      const redirectUrl = response.data;
      window.location.href = redirectUrl;
    }

    throw Error("Something went wrong when starting the trial.");
  }
}
