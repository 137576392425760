import { useState, useRef } from "react";

export function useConfirmModal() {
    const [message, setMessage] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const resolveRef = useRef(null); // Use useRef instead of let resolvePromise

    const confirm = (msg) => {
        setMessage(msg);
        setIsOpen(true);

        return new Promise((resolve) => {
            resolveRef.current = resolve; // Store the resolve function in ref
        });
    };

    const handleConfirm = () => {
        setIsOpen(false);
        if (resolveRef.current) resolveRef.current(true); // Resolve promise with true
    };

    const handleCancel = () => {
        setIsOpen(false);
        if (resolveRef.current) resolveRef.current(false); // Resolve promise with false
    };

    const ConfirmModal = () => {
        if (!isOpen) return null;
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                    <h3 className="text-lg font-semibold text-gray-900">Confirm Action</h3>
                    <p className="text-gray-600 mt-2">{message}</p>
                    <div className="flex justify-end mt-4">
                        <button onClick={handleCancel} className="px-4 py-2 bg-gray-300 rounded-lg mr-2 hover:bg-gray-400">
                            Cancel
                        </button>
                        <button onClick={handleConfirm} className="px-4 py-2 bg-myteal-500 text-white rounded-lg hover:bg-myteal-600">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return { confirm, ConfirmModal };
}
