import React, { useEffect, useState } from "react";
import Input from "../components/input";

const AddDrivers = (props) => {
  const [currDriverCount, setCurrDriverCount] = useState(1);
  const [maxDriverCount, setMaxDriverCount] = useState(20);

  useEffect(() => {
    if (props.tier === "Small") {
      setMaxDriverCount(7);
    } else if (props.tier === "Growing") {
      setMaxDriverCount(14);
    } else if (props.tier === "Large") {
      setMaxDriverCount(20);
    }
  }, [props.tier]);

  const getValue = (index, field) => {
    if (props.form.drivers && props.form.drivers[index]) {
      return props.form.drivers[index][field];
    }
    return null;
  };

  function nextDriver() {
    if (currDriverCount >= maxDriverCount) {
      alert(`You've reached the maximum number of drivers for your plan. Upgrade to add more.`);
      return;
    }

    if (props.form && props.form.drivers) {
      let driverFilledInfo = props.form.drivers;
      let isFilled = true;

      // Check that all existing driver fields are filled before adding a new one
      for (let i = 0; i < driverFilledInfo.length; i++) {
        if (driverFilledInfo[i].name === "" || driverFilledInfo[i].email === "") {
          isFilled = false;
        }
      }

      // Ensure last driver input is filled
      if (!driverFilledInfo[currDriverCount - 1]) {
        isFilled = false;
      }

      if (isFilled) {
        setCurrDriverCount(currDriverCount + 1);
      } else {
        alert("Be sure to fill in both the name and the email of each driver.");
      }
    } else {
      alert("Be sure to fill in both the name and the email of each driver.");
    }
  }

  const createDriverInputs = () => {
    const elementArray = [];

    for (let i = 0; i < currDriverCount; i++) {
      elementArray.push(
        <div className="" key={i}>
          <div className="flex flex-row space-x-1 w-full">
            <div className="w-4 h-4 rounded-full" style={{ backgroundColor: props.colors[i], marginTop: 46 }}></div>
            <div className="flex-1">
              <Input
                label={`Driver ${i + 1}`}
                placeholder={"Enter driver name"}
                defaultValue={getValue(i, "name")}
                onBlur={(e) => {
                  const name = e.target.value;
                  props.saveDriverData(i, "name", name);
                }}
              />
            </div>
            <div className="flex-1" style={{ marginTop: 18 }}>
              <Input
                type="email"
                placeholder={"Enter driver email"}
                defaultValue={getValue(i, "email")}
                onBlur={(e) => {
                  const email = e.target.value;
                  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

                  if (isValidEmail) {
                    props.saveDriverData(i, "email", email);
                  } else {
                    alert("Please enter a valid email address");
                    e.target.value = "";
                  }
                }}
              />
            </div>
            <div className="flex-1" style={{ marginTop: 0 }}>
              <Input
                type="tel"
                label={`*Optional*`}
                placeholder={"Enter driver phone #"}
                defaultValue={getValue(i, "phoneNum")}
                pattern="^\+?[0-9\s\-\(\)]{7,15}$"
                onBlur={(e) => {
                  const phoneNum = e.target.value;
                  const isValidPhone = /^\+?[0-9\s\-\(\)]{7,15}$/.test(phoneNum);

                  if (isValidPhone) {
                    props.saveDriverData(i, "phoneNum", phoneNum);
                  } else {
                    alert("Please enter a valid phone number");
                    e.target.value = "";
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return elementArray;
  };

  return (
    <div className="px-4">
      <div className="title text-lg font-medium text-black-500 px-4">
        <h1>Add your drivers</h1>
        <label className="block text-gray-600 text-xs font-medium mb-1">
          Add the information of your drivers.
        </label>
        <div className="overflow-y-auto pt-2">
          {createDriverInputs()}
        </div>
        {currDriverCount < 20 && (
          <div className="pl-9 pr-4">
            <div
              onClick={nextDriver}
              className="w-full cursor-pointer bg-white hover:bg-blue-100 py-2 rounded border border-dashed border-blue-500 flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-blue-500"
                width="23"
                height="23"
                viewBox="0 0 512 512"
              >
                <path
                  d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                  fill="none"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeWidth="32"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M256 176v160M336 256H176"
                />
              </svg>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddDrivers;
