// Validation constants
const NYC_BOROUGHS = ['Manhattan', 'Bronx', 'Brooklyn', 'Queens', 'Staten Island'];
const NYC_LAT_RANGE = { min: 40.4774, max: 40.9176 };
const NYC_LONG_RANGE = { min: -74.2591, max: -73.7004 };
const VALID_UNITS = ['Construction Enforcement', 'Quality of Life'];

// Required columns for inspection data
export const REQUIRED_COLUMNS = [
    'INSPECTION_ID',
    'Record_ID',
    'Record_Open_Date',
    'Priority',
    'TaskType',
    'UNIT',
    'DURATION',
    'Status',
    'District',
    'Street',
    'City',
    'State',
    'Postcode',
    'Latitude',
    'Longitude',
    'AppointmentStart',
    'AppointmentFinish',
    'RequiredEngineers',
    'ComplaintCategory'
];

// Helper functions
const isValidDate = (dateStr) => {
    if (!dateStr) return false;
    const date = new Date(dateStr);
    return date instanceof Date && !isNaN(date);
};

const isValidDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return false;
    const dateTime = new Date(dateTimeStr);
    return dateTime instanceof Date && !isNaN(dateTime);
};

const isValidNYCZipCode = (zipCode) => {
    if (!zipCode) return false;
    const zipStr = String(zipCode).trim();
    return /^\d{5}$/.test(zipStr) && 
           parseInt(zipStr) >= 10001 && 
           parseInt(zipStr) <= 11697; // NYC ZIP code range
};

const isValidLatitude = (lat) => {
    if (!lat) return false;
    const numLat = parseFloat(String(lat));
    return !isNaN(numLat) && 
           numLat >= NYC_LAT_RANGE.min && 
           numLat <= NYC_LAT_RANGE.max;
};

const isValidLongitude = (lng) => {
    if (!lng) return false;
    const numLng = parseFloat(String(lng));
    return !isNaN(numLng) && 
           numLng >= NYC_LONG_RANGE.min && 
           numLng <= NYC_LONG_RANGE.max;
};

const isValidInteger = (value) => {
    if (!value) return false;
    const num = parseInt(String(value));
    return !isNaN(num) && Number.isInteger(num);
};

const isValidPositiveInteger = (value) => {
    if (!value) return false;
    const num = parseInt(String(value));
    return isValidInteger(num) && num > 0;
};

const isValidUnit = (unit) => {
    if (!unit) return false;
    return VALID_UNITS.includes(String(unit).trim());
};

// Column validation function
export const validateColumns = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        return {
            isValid: false,
            missingColumns: REQUIRED_COLUMNS,
            message: 'No data found in file'
        };
    }

    const fileColumns = Object.keys(data[0]);
    const missingColumns = REQUIRED_COLUMNS.filter(col => !fileColumns.includes(col));

    return {
        isValid: missingColumns.length === 0,
        missingColumns,
        message: missingColumns.length > 0
            ? `Missing required columns: ${missingColumns.join(', ')}`
            : 'All required columns present'
    };
};

// Main validation function
export const validateInspectionRow = (row) => {
    const errors = [];
    if (!row) {
        errors.push('Invalid row: empty or undefined');
        return errors;
    }

    // Required fields check (excluding fields that can have empty values)
    const strictlyRequiredFields = [
        'INSPECTION_ID', 'Record_ID', 'Record_Open_Date', 'Priority',
        'TaskType', 'UNIT', 'DURATION', 'Status', 'District', 'Street',
        'City', 'State', 'Postcode', 'Latitude', 'Longitude',
        'ComplaintCategory'
    ];

    // These fields must exist in the row but can have empty values
    const requiredButCanBeEmptyFields = [
        'AppointmentStart', 'AppointmentFinish', 'RequiredEngineers'
    ];

    // Check if all required columns exist in the data structure
    const missingFields = [...strictlyRequiredFields, ...requiredButCanBeEmptyFields]
        .filter(field => !(field in row));

    if (missingFields.length > 0) {
        errors.push(`Missing required fields: ${missingFields.join(', ')}`);
        return errors;
    }

    // Check strictly required fields have non-empty values
    const missingValues = strictlyRequiredFields.filter(field => {
        const value = row[field];
        return value === null || value === undefined || String(value).trim() === '';
    });
    if (missingValues.length > 0) {
        errors.push(`Required fields cannot be empty: ${missingValues.join(', ')}`);
        return errors;
    }

    // INSPECTION_ID and Record_ID validation
    if (!isValidInteger(row.INSPECTION_ID) || parseInt(String(row.INSPECTION_ID)) < 0) {
        errors.push('Invalid INSPECTION_ID: Must be a non-negative integer');
    }
    if (!isValidInteger(row.Record_ID) || parseInt(String(row.Record_ID)) < 0) {
        errors.push('Invalid Record_ID: Must be a non-negative integer');
    }

    // UNIT validation
    if (!isValidUnit(row.UNIT)) {
        errors.push(`Invalid UNIT: Must be either "${VALID_UNITS[0]}" or "${VALID_UNITS[1]}"`);
    }

    // Date validations
    if (!isValidDate(row.Record_Open_Date)) {
        errors.push('Invalid Record_Open_Date format: Must be YYYY-MM-DD');
    }

    // Optional value validations - validate format only if value is not empty
    const hasAppointmentStart = row.AppointmentStart && String(row.AppointmentStart).trim() !== '';
    const hasAppointmentFinish = row.AppointmentFinish && String(row.AppointmentFinish).trim() !== '';

    if (hasAppointmentStart && !isValidDateTime(row.AppointmentStart)) {
        errors.push('Invalid AppointmentStart format: Must be YYYY-MM-DD HH:MM:SS');
    }
    if (hasAppointmentFinish && !isValidDateTime(row.AppointmentFinish)) {
        errors.push('Invalid AppointmentFinish format: Must be YYYY-MM-DD HH:MM:SS');
    }
    if (hasAppointmentStart && hasAppointmentFinish &&
        isValidDateTime(row.AppointmentStart) && isValidDateTime(row.AppointmentFinish) &&
        new Date(row.AppointmentFinish) <= new Date(row.AppointmentStart)) {
        errors.push('AppointmentFinish must be after AppointmentStart');
    }

    // Priority validation
    if (!row.Priority || !/^[ABCD][+]?$/.test(String(row.Priority).trim())) {
        errors.push('Invalid Priority: Must be A, B, B+, C, or D');
    }

    // Duration validation
    if (!isValidPositiveInteger(row.DURATION)) {
        errors.push('Invalid DURATION: Must be a positive integer');
    }

    // District validation
    if (!isValidPositiveInteger(row.District)) {
        errors.push('Invalid District: Must be a positive integer');
    }

    // Location validations
    if (!row.City || !NYC_BOROUGHS.includes(String(row.City).trim())) {
        errors.push('Invalid City: Must be one of the five NYC boroughs');
    }
    if (!row.State || String(row.State).trim() !== 'NY') {
        errors.push('Invalid State: Must be NY');
    }
    if (!isValidNYCZipCode(row.Postcode)) {
        errors.push('Invalid Postcode: Must be a valid NYC ZIP code');
    }
    if (!isValidLatitude(row.Latitude)) {
        errors.push('Invalid Latitude: Must be within NYC bounds');
    }
    if (!isValidLongitude(row.Longitude)) {
        errors.push('Invalid Longitude: Must be within NYC bounds');
    }

    // Complaint Category validation
    if (!row.ComplaintCategory || String(row.ComplaintCategory).trim().length === 0) {
        errors.push('Invalid ComplaintCategory: Cannot be empty');
    }

    return errors;
};

export const validateInspectionData = (data) => {
    if (!Array.isArray(data)) {
        throw new Error('Invalid data format: Expected an array of inspection records');
    }

    // First validate columns
    const columnValidation = validateColumns(data);
    if (!columnValidation.isValid) {
        throw new Error(columnValidation.message);
    }

    return data.map((row, index) => ({
        rowIndex: index + 2, // Add 2 to account for 0-based index and header row
        inspectionId: row?.INSPECTION_ID || 'N/A',
        errors: validateInspectionRow(row),
        rawData: row
    })).filter(result => result.errors.length > 0);
}; 