import React, { useEffect } from "react";
import TeamPricing from "../../components/team_pricing";
import { Range, getTrackBackground } from 'react-range';
import { OnboardingApi } from "../api";
import { useState, useContext } from "react";
import { AuthContext } from "../../../AuthContext";
import { useHistory } from 'react-router-dom';

const SMALL_TEAM_PRICE = 75
const SMALL_TEAM_PRICE_YEARLY = 0
const GROWING_TEAM_PRICE = 125
const GROWING_TEAM_PRICE_YEARLY = 0
const LARGE_TEAM_PRICE = 175
const LARGE_TEAM_PRICE_YEARLY = 0

const tierDriverCount = {
  Small: 7,
  Growing: 14,
  Large: 20
};


const TrialDetails = (props) => {
  const FLEET_FLAT_MONTHLY_RATE = 79;
  const ADDITIONAL_DRIVER_FEE = 10;
  const history = useHistory();

  const [numberOfDrivers, setNumberOfDrivers] = useState(0);
  const [originalNumberOfDrivers, setOriginalNumberOfDrivers] = useState(0);
  const [fleetMonthlyPrice, setFleetMonthlyPrice] = useState(79);
  const [promoCode, setPromoCode] = useState("");
  const [isYearly, toggle] = useState(false)
  const [tier, setTier] = useState('')
  const [trialDays, setTrialDays] = useState(7);
  const [promoCodeStatus, setPromoCodeStatus] = useState(null); // null: not applied, true: valid, false: invalid

  const { updateDriverCount, updateTeamsSub, currentUser } = useContext(AuthContext);

  // Add Range constants
  const MIN_DRIVERS = 0;
  const MAX_DRIVERS = 20;

  if (numberOfDrivers <=7) {
    if (tier != 'Small') {
        setTier('Small')
    }
  } else if (numberOfDrivers <= 14) {
      if (tier != 'Growing') {
          setTier('Growing')
      }
  } else if (numberOfDrivers <= 20) {
      if (tier != 'Large') {
          setTier('Large')
      }
  }

  useEffect(() => {
    if (props.form.numDrivers){
      let answeredNumDrivers = props.form.numDrivers
      setNumberOfDrivers(answeredNumDrivers)
      setOriginalNumberOfDrivers(answeredNumDrivers)
    }
  }, []);

  useEffect(() => {
    if (numberOfDrivers <=7) {
      if (tier != 'Small') {
          setTier('Small')
      }
    } else if (numberOfDrivers <= 14) {
        if (tier != 'Growing') {
            setTier('Growing')
        }
    } else if (numberOfDrivers <= 20) {
        if (tier != 'Large') {
            setTier('Large')
        }
    }
  }, [numberOfDrivers]);

  const handlePromoCodeChange = (e) => {
    const code = e.target.value;
    setPromoCode(code);
    setPromoCodeStatus(null); // Reset status when code changes
  };

  const handleApplyPromoCode = async () => {
    if (promoCode.toLowerCase() === 'earlybird') {
      setTrialDays(30);
      setPromoCodeStatus(true);
    }  else if (promoCode.toLowerCase() === 'devsquad') {
      setTrialDays(7);
      if (currentUser) {
        let success = await updateTeamsSub(currentUser.email, null, 1)
        console.log(success)
        if (success) {
          history.push(`/teams/onboarding?page=Add_Drivers&numberOfDrivers=${numberOfDrivers}`)
          history.go()
        } else {
          alert('Error processing code, please try again or contact info@routora.com')
        }
      }
      setPromoCodeStatus(true);
    } else {
      setTrialDays(7);
      setPromoCodeStatus(false);
    }
  };

  const calulateFleetMonthlyPrice = () => {
    if (numberOfDrivers <= 5) {
      return FLEET_FLAT_MONTHLY_RATE;
    }

    return (
      FLEET_FLAT_MONTHLY_RATE + (numberOfDrivers - 5) * ADDITIONAL_DRIVER_FEE
    );
  };

  const handleAccessSubmit = async () => {
    console.log("Promo code entered:", promoCode);
    if (promoCode === 'QB29538490') {  // Access Code: QB29538490
      if (currentUser) {
        let success = await updateTeamsSub(currentUser.email, null, 1)
        console.log(success)
        if (success) {
          history.push(`/teams/onboarding?page=Add_Drivers&numberOfDrivers=${numberOfDrivers}`)
          history.go()
        } else {
          alert('Error processing code, please try again or contact info@routora.com')
        }
      }
    } else {
      alert('Invalid Code')
    }
  };

  async function initiateTrial() {
    // first update the driver count if its changed
    if (numberOfDrivers != originalNumberOfDrivers) {
      props.saveData("numDrivers", numberOfDrivers);
      updateDriverCount(props.email, numberOfDrivers)
    }

    console.log(props.name)
    const customerId = await OnboardingApi.createCustomer(
      props.email,
      props.name
    );
    if (customerId) {
      await OnboardingApi.startTrial(
        props.email,
        customerId,
        tier,
        trialDays
      );
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Plans that scale with your team
        </h1>
        <p className="text-xl text-gray-600">
          Choose the perfect plan for your team size and start optimizing your routes today
        </p>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        {/* Left Column - Pricing Card */}
        <div className="lg:w-1/2 flex justify-center">
          <div style={{ height: 600, width: 400 }} className="p-7 bg-white rounded-2xl shadow-lg border-gray-200 border-2">
            {tier == 'Small' &&
              <div>
                <h4 className="text-xl text-myblue-900 font-semibold">Small</h4>
                <p style={{ fontSize: 15 }} className="font-medium text-gray-600">The best choice for smaller teams streamlining logistics.</p>
              </div>
            }
            {tier == 'Growing' &&
              <div>
                <h4 className="text-xl text-myblue-900 font-semibold">Growing</h4>
                <p style={{ fontSize: 15 }} className="font-medium text-gray-600">More drivers, same powerful </p>
                <p style={{ fontSize: 15 }} className="font-medium text-gray-600">optimization.</p>
              </div>
            }
            {tier == 'Large' &&
              <div>
                <h4 className="text-xl text-myblue-900 font-semibold">Large</h4>
                <p style={{ fontSize: 15 }} className="font-medium text-gray-600">Built for high-volume teams managing complex logistics.</p>
              </div>
            }
            <div className="dashed-line"></div>

            <div className="flex">
                {tier == 'Small' &&
                  <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                      { isYearly ? SMALL_TEAM_PRICE_YEARLY : SMALL_TEAM_PRICE }
                  </div>
                }
                {tier == 'Growing' &&
                  <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                      { isYearly ? GROWING_TEAM_PRICE_YEARLY : GROWING_TEAM_PRICE }
                  </div>
                }
                {tier == 'Large' &&
                  <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
                      { isYearly ? LARGE_TEAM_PRICE_YEARLY : LARGE_TEAM_PRICE }
                  </div>
                }
                <div className="md:text-1xl text-gray-600 font-medium mt-6">/ month</div>
              
            </div>
            <div className="flex justify-between mb-2 font-medium">
                <p className="md:text-md text-md text-gray-500">
                    Included per month:
                </p>
                <p className="md:text-md font-bold text-gray-500">
                  {tierDriverCount[tier] + ' Drivers' || ""}
                </p>

            </div>
            <div className="flex mb-2"></div>
            <div onClick={initiateTrial} id="Plus" className="btn mt-3 py-2 font-semibold rounded-lg bg-myteal-500 text-white border-myteal-500 border-2 hover:bg-myteal-600 hover:border-myteal-600 w-full cursor-pointer">
                <p>Start free {trialDays} day trial</p>
            </div>
            
            <div className="mt-7">
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Create and optimize routes</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">File upload</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Balance your routes</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Route history</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Accurate ETA's</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Download itineraries</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Dispatch to driver mobile apps</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-2">Synced with Google, Apple, Waze</p>
                </div>
            </div>
          </div>
        </div>

        {/* Right Column - Driver Selection and Promo Code */}
        <div className="lg:w-1/2">
          <div className="bg-white p-7 rounded-2xl shadow-lg border-gray-200 border-2">
            <h2 className="text-xl text-myblue-900 font-semibold mb-4">
              Configure your team
            </h2>
            
            {/* Driver Count Section */}
            <div className="mb-14">
              <p style={{ fontSize: 15 }} className="font-medium text-gray-600 mb-4">
                Number of Drivers
              </p>
              <div className="relative">
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <Range
                      step={1}
                      min={MIN_DRIVERS}
                      max={MAX_DRIVERS}
                      values={[numberOfDrivers]}
                      onChange={(values) => {
                        const clamped = Math.max(values[0], 1);
                        setNumberOfDrivers(clamped);
                      }}
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '8px',
                            width: '100%',
                            borderRadius: '4px',
                            background: getTrackBackground({
                              values: [numberOfDrivers],
                              colors: ['#548BF4', '#ccc'],
                              min: MIN_DRIVERS,
                              max: MAX_DRIVERS,
                            }),
                            margin: '1rem 0',
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '24px',
                            width: '24px',
                            borderRadius: '50%',
                            backgroundColor: '#FFF',
                            border: '2px solid #548BF4',
                            boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: '30px',
                              left: '-6px',
                              background: '#548BF4',
                              color: '#FFF',
                              padding: '4px 8px',
                              borderRadius: '4px',
                              fontSize: '0.85rem',
                              fontWeight: 'bold',
                              pointerEvents: 'none',
                              whiteSpace: 'nowrap',
                              width: '32px',
                              textAlign: 'center',
                              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)'
                            }}
                          >
                            {numberOfDrivers}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="number"
                      min="1"
                      max="20"
                      value={numberOfDrivers}
                      onChange={(e) => {
                        const value = Math.min(Math.max(parseInt(e.target.value) || 1, 1), 20);
                        setNumberOfDrivers(value);
                      }}
                      className="w-20 px-3 py-2 rounded-lg border-gray-300"
                    />
                    <span className="text-gray-600">drivers</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Promo Code Section */}
            <div className="border-t pt-8">
              <p style={{ fontSize: 15 }} className="font-medium text-gray-600 mb-4">
                Have a promo code?
              </p>
              <div className="space-y-3">
                <div className="flex gap-3">
                  <input
                    type="text"
                    className="flex-1 rounded-lg border-gray-300"
                    placeholder="Enter promo code"
                    value={promoCode}
                    onChange={handlePromoCodeChange}
                  />
                  {promoCode.length > 0 && (
                    <button
                      onClick={handleApplyPromoCode}
                      className="text-blue-500 font-medium hover:text-blue-600 transition-colors"
                    >
                      Apply
                    </button>
                  )}
                </div>
                {promoCodeStatus === true && (
                  <div className="text-sm text-myteal-500 font-medium flex items-center">
                    <svg className="w-5 h-5 mr-1" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    30-day trial activated
                  </div>
                )}
                {promoCodeStatus === false && (
                  <div className="text-sm text-red-500 font-medium flex items-center">
                    <svg className="w-5 h-5 mr-1" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                    </svg>
                    Invalid promo code
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialDetails;
