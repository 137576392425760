import React, { useState } from 'react';
import TeamsHeader from '../teams/TeamsHeader/TeamsHeader';
import InspectionsImport from './pages/InspectionsImport';
import InspectorsImport from './pages/InspectorsImport';
import SelectionDashboard from './selection/SelectionDashboard'
import { rankInspectionRequests } from './calculations/sortInspections';

function ImportDashboard() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [page, setPage] = useState('inspections');

    // State for all imported data
    const [inspectionsData, setInspectionsData] = useState([]);
    const [inspectionDurationData, setInspectionDurationData] = useState([]);
    const [inspectorsData, setInspectorsData] = useState([]);
    const [calendarData, setCalendarData] = useState([]);
    const [holidayListData, setHolidayListData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    console.log({
        inspectionsData,
        inspectionDurationData, 
        inspectorsData,
        calendarData,
        holidayListData,
        districtData
    });

    // Handler for when inspection data is loaded
    const handleInspectionsLoaded = (data) => {
        setInspectionsData(data);
        console.log('Inspections loaded:', data);
    };

    // Handler for when inspection duration data is loaded
    const handleInspectionDurationLoaded = (data) => {
        setInspectionDurationData(data);
        console.log('Inspection duration data loaded:', data);
    };

    // Handler for when inspector data is loaded
    const handleInspectorsLoaded = (type, data) => {
        switch (type) {
            case 'inspectorList':
                setInspectorsData(data);
                console.log('Inspectors loaded:', data);
                break;
            case 'calendar':
                const standardizedCalendarData = data.map(entry => ({
                    ...entry,
                    START_TIME: standardizeTime(entry.START_TIME), 
                    END_TIME: standardizeTime(entry.END_TIME)
                }));
                setCalendarData(standardizedCalendarData);
                console.log('Calendar data loaded:', standardizedCalendarData);
                break;
            case 'holidayList':
                setHolidayListData(data);
                console.log('Holiday list loaded:', data);
                break;
            case 'district':
                setDistrictData(data);
                console.log('District data loaded:', data);
                break;
            default:
                console.warn('Unknown data type:', type);
        }
    };

    // Standardize the date format before setting the state
    const standardizeTime = (timeStr) => {
        // Handle the "DD-MMM-YY HH:MM AM/PM" format
        const formatTwo = /^(\d{1,2})-([A-Z]{3})-(\d{2})\s*(\d{1,2}):(\d{2})\s*(AM|PM)$/i;
        if (formatTwo.test(timeStr)) {
            const monthMap = {
                'JAN': 1, 'FEB': 2, 'MAR': 3, 'APR': 4, 'MAY': 5, 'JUN': 6,
                'JUL': 7, 'AUG': 8, 'SEP': 9, 'OCT': 10, 'NOV': 11, 'DEC': 12
            };
            const [, d, m, y, h, min, ampm] = timeStr.match(formatTwo);
            let hours = parseInt(h);
            
            // Convert to 24-hour format
            if (ampm.toUpperCase() === 'PM' && hours < 12) hours += 12;
            if (ampm.toUpperCase() === 'AM' && hours === 12) hours = 0;
            
            // Format as "M/D/YY HH:mm"
            return `${monthMap[m.toUpperCase()]}/${d}/${y} ${hours.toString().padStart(2, '0')}:${min}`;
        }
        return timeStr; // Return as is if it's already in the correct format
    };

    return (
        <div className="flex flex-col min-h-screen overflow-hidden pb-20">
            <TeamsHeader 
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
            />
            
            {/* Page content */}
            <main className="grow">
                {page === 'inspections' && (
                    <InspectionsImport 
                        onInspectionsLoaded={handleInspectionsLoaded}
                        onInspectionDurationLoaded={handleInspectionDurationLoaded}
                        inspectionsData={inspectionsData}
                        inspectionDurationData={inspectionDurationData}
                        setPage={setPage}
                    />
                )}
                {page === 'inspectors' && (
                    <InspectorsImport 
                        onInspectorsLoaded={handleInspectorsLoaded}
                        inspectorsData={inspectorsData}
                        calendarData={calendarData}
                        holidayListData={holidayListData}
                        districtData={districtData}
                        setPage={setPage}
                    />
                )}
                {page === 'selection' && (
                    <SelectionDashboard 
                        inspections={{
                            inspectionsData,
                            inspectionDurationData
                        }}
                        inspectors={{
                            inspectorsData,
                            calendarData,
                            holidayListData,
                            districtData
                        }}
                    />
                )}
            </main>
        </div>
    );
}

export default ImportDashboard;