import React, { useContext } from 'react';
import './WebGrid.css';
import Grid1 from './Grid1';
import Grid2 from './Grid2';
import Grid3 from './Grid3';
import Grid4 from './Grid4';
import Grid5 from './Grid5';
import Grid6 from './Grid6';

import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../AuthContext'

function WebGrid(props) {

  const history = useHistory();

  const { currentUser, currentUserTier } = useContext(AuthContext);

  function goSignUp() {
    if (currentUser) {
        if (currentUserTier == 2) {
            history.push('/playground')
        } else {
            history.push('/pricing')
        }
    } else {

      // google tracking
      try {
        // Send the trial start event to Google Analytics (and ultimately Google Ads) after the user is successfully created
        window.gtag && window.gtag('event', 'web_button_click', {
          'event_category': 'Button Click',
          'event_label': 'Web Button Click',
          'value': 0.1
        });
        console.log('Web Button Click')
      } catch (error) {
        console.log('Error in tracking web button click start in Google Analytics', error);
      }

      history.push('/signup?origin=web')
    }
  }


  return (
    <section id="main-webgrid" className="bg-myblue-900 py-5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 mb-32">
        
        {/* <div className="text-center max-w-5xl mx-auto">
            <h1 className="text-4xl md:text-6xl text-myblue-900 font-extrabold text-myblue-900">Routora on Web</h1>
        </div> */}
        
        <Grid1 />

        <div className="block md:flex w-full mb-3">
            <div className="w-full md:w-2/5 mr-3 mb-3 md:mb-0">
                <Grid3 />
            </div>
            <div className=" w-full md:w-3/5">
                <Grid4 />
            </div>
        </div>

        <div className="block md:flex w-full mb-3">
            <div className=" w-full md:w-3/5 mb-3 md:mb-0">
                <Grid5 />
            </div>
            <div className="w-full md:w-2/5 md:ml-3">
                <Grid6 />
            </div>
        </div>

        <Grid2 />

        {props.isHomeWeb &&
          <div className="mx-auto text-center pb-2 md:pb-3 mt-24">
              <a onClick={goSignUp} className="btn cursor-pointer text-white font-semibold bg-blue-500 hover:bg-blue-600 mb-4 w-64 md:mb-0 py-4">Try Today for Free</a>
          </div>
        }

      </div>
    </section>
  );
}

export default WebGrid;
